export default function InputFocusDot({
  previousDot,
  nextDot,
  dotIndex,
  previousDirection = 'vertical',
  direction = 'vertical',
  nextDirection = 'vertical',
  hasError = false,
}) {
  const previousClasses = {
    vertical: ['top-full -left-5', 'top-0 -left-5'],
    horizontal: [
      'top-3 left-5 peer-focus:-left-5',
      'top-3 -left-10 peer-focus:-left-5',
    ],
  };
  const classes = {
    vertical: ['top-full -left-5', 'top-0 -left-5'],
    horizontal: [
      'top-3 left-5 peer-focus:-left-5',
      'top-3 -left-10 peer-focus:-left-5',
    ],
  };
  const nextClasses = {
    vertical: ['top-full -left-5', 'top-0 -left-5'],
    horizontal: [
      'top-3 left-5 peer-focus:-left-5',
      'top-3 -left-10 peer-focus:-left-5',
    ],
  };
  let changeClass = '';
  if (previousDot === dotIndex) {
    changeClass =
      nextDot > dotIndex
        ? nextClasses[nextDirection][0]
        : nextDot === dotIndex
        ? classes[direction][0]
        : nextClasses[previousDirection][1];
  } else {
    changeClass =
      previousDot > dotIndex
        ? previousClasses[nextDirection][0]
        : previousClasses[previousDirection][1];
  }
  return (
    <div
      className={`${changeClass} ${direction === 'vertical' ? '-left-5' : ''} ${
        hasError ? 'peer-focus:bg-red-500' : 'peer-focus:bg-primary-500'
      } absolute w-3 h-3 transition-all duration-500 bg-transparent rounded-full peer-focus:top-4 sm:peer-focus:top-3`}
    ></div>
  );
}
