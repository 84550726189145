import InputErrorMessage from '../InputErrorMessage';
import InputFocusDot from '../InputFocusDot';

export default function JoinedInput({
  // Input
  idPrefix = '',
  isVertical = false,
  hideSecond = false,
  label,
  labelClass = '',
  parentClass = '',
  inputs = [{}, {}],

  register,
  errors,
  errorName,
  error,

  setCurrentDot,
  previousDot,
  currentDot,

  //placeholder = label,
  //type = 'text',
  //// Form
  //name,
  //rules = {},
  //// Dots
  //showDot = true,
  //dotIndex,
  //// Classes
  //labelClass = '',
  //inputParentClass = '',
  //inputClass = '',
}) {
  const {
    label: label1,
    placeholder: placeholder1,
    type: type1,
    name: name1,
    rules: rules1,
    showDot: showDot1,
    dotIndex: dotIndex1,
    dotProps: dotProps1,
    inputParentClass: inputParentClass1,
    inputClass: inputClass1,
    ref: ref1,
    options: options1,
    ...props1
  } = inputs[0];
  const {
    label: label2,
    placeholder: placeholder2,
    type: type2,
    name: name2,
    rules: rules2,
    showDot: showDot2,
    dotIndex: dotIndex2,
    dotProps: dotProps2,
    inputParentClass: inputParentClass2,
    inputClass: inputClass2,
    ref: ref2,
    options: options2,
    ...props2
  } = inputs[1];

  const inputId1 = `inp_${idPrefix}${idPrefix !== '' && '_'}${name1}`;
  const inputId2 = `inp_${idPrefix}${idPrefix !== '' && '_'}${name2}`;

  return (
    <div className={parentClass}>
      <label
        htmlFor={inputId1}
        className={`${labelClass} block text-sm font-medium text-gray-900`}
      >
        {label}
      </label>
      <div
        className={
          isVertical
            ? 'mt-1 -space-y-px rounded-md shadow-sm'
            : 'flex mt-1 -space-x-px'
        }
      >
        <div
          className={`${inputParentClass1} ${
            !isVertical && 'flex-1 w-1/2 min-w-0'
          } relative`}
        >
          <label htmlFor="inp_edit_document_kind" className="sr-only">
            {label1}
          </label>
          {type1 === 'select' ? (
            <select
              type={type1}
              id={inputId1}
              name={name1}
              ref={ref1 ? ref1 : register(rules1)}
              onFocus={() => setCurrentDot(dotIndex1)}
              className={`${
                errors[name1] ? 'inp-border-red' : 'inp-border-primary'
              } ${inputClass1} ${
                isVertical && !hideSecond
                  ? 'rounded-t-md rounded-none'
                  : isVertical
                  ? 'rounded-md'
                  : 'rounded-l-md rounded-none'
              } relative block w-full bg-transparent focus:z-10 sm:text-sm peer`}
              {...props1}
            >
              {options1}
            </select>
          ) : (
            <input
              type={type1}
              id={inputId1}
              name={name1}
              placeholder={placeholder1 || label1}
              ref={ref1 ? ref1 : register(rules1)}
              onFocus={() => setCurrentDot(dotIndex1)}
              className={`${
                errors[name1] ? 'inp-border-red' : 'inp-border-primary'
              } ${inputClass1} ${
                isVertical && !hideSecond
                  ? 'rounded-none rounded-t-md'
                  : isVertical
                  ? 'rounded-md'
                  : 'rounded-none rounded-l-md'
              } relative block w-full rounded-none bg-transparent focus:z-10 sm:text-sm peer`}
              {...props1}
            ></input>
          )}
          {showDot1 && (
            <InputFocusDot
              previousDot={previousDot}
              nextDot={currentDot}
              dotIndex={dotIndex1}
              hasError={errors[name1]}
              {...dotProps1}
            />
          )}
        </div>
        {!hideSecond && (
          <div
            className={`${inputParentClass2} ${
              !isVertical && 'flex-1 w-1/2 min-w-0'
            } relative`}
          >
            <label htmlFor="inp_edit_document_kind" className="sr-only">
              {label2}
            </label>
            {type2 === 'select' ? (
              <select
                type={type2}
                id={inputId2}
                name={name2}
                ref={ref2 ? ref2 : register(rules2)}
                onFocus={() => setCurrentDot(dotIndex2)}
                className={`${
                  errors[name2] ? 'inp-border-red' : 'inp-border-primary'
                } ${inputClass2} ${
                  isVertical ? 'rounded-b-md' : 'rounded-r-md'
                } relative block w-full rounded-none bg-transparent focus:z-20 sm:text-sm peer`}
                {...props2}
              >
                {options2}
              </select>
            ) : (
              <input
                type={type2}
                id={inputId2}
                name={name2}
                placeholder={placeholder2 || label2}
                ref={ref2 ? ref2 : register(rules2)}
                onFocus={() => setCurrentDot(dotIndex2)}
                className={`${
                  errors[name2] ? 'inp-border-red' : 'inp-border-primary'
                } ${inputClass2} ${
                  isVertical ? 'rounded-b-md' : 'rounded-r-md'
                } relative block w-full rounded-none bg-transparent focus:z-20 sm:text-sm peer`}
                {...props2}
              ></input>
            )}
            {showDot2 && (
              <InputFocusDot
                previousDot={previousDot}
                nextDot={currentDot}
                dotIndex={dotIndex2}
                hasError={errors[name2]}
                {...dotProps2}
              />
            )}
          </div>
        )}
      </div>
      <InputErrorMessage
        error={error ? error : errors[errorName]}
      ></InputErrorMessage>
    </div>
  );
}
