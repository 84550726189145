import './App.css';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import PublicOnlyRoute from './components/Utils/PublicOnlyRoute';
import PrivateRoute from './components/Utils/ProtectedRoute';
import { ProvideAuth } from './contexts/Auth';

import SubcategoryList from './pages/subcategory/List';
import OperationList from './pages/operation/List';
import CategoryList from './pages/category/List';
import CustomerList from './pages/customer/List';
import ProductList from './pages/product/List';
import ProjectList from './pages/project/List';
import NotFound from './pages/misc/NotFound';
import Settings from './pages/user/Settings';
import Dashboard from './pages/Dashboard';
import UserList from './pages/user/List';
import Login from './pages/auth/Login';
import FolderList from './pages/folder/List';

import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';

function App() {
  return (
    <ProvideAuth>
      <Router>
        <Switch>
          <PublicOnlyRoute exact path="/">
            <Login />
          </PublicOnlyRoute>
          <PrivateRoute path="/subcategories/:categoryId">
            <SubcategoryList />
          </PrivateRoute>
          <PrivateRoute path="/subcategories">
            <SubcategoryList />
          </PrivateRoute>
          <PrivateRoute path="/operations" needsProject>
            <OperationList />
          </PrivateRoute>
          <PrivateRoute path="/customers" needsProject>
            <CustomerList />
          </PrivateRoute>
          <PrivateRoute path="/categories">
            <CategoryList />
          </PrivateRoute>
          <PrivateRoute path="/documents">
            <FolderList />
          </PrivateRoute>
          <PrivateRoute path="/dashboard">
            <Dashboard />
          </PrivateRoute>
          <PrivateRoute path="/settings">
            <Settings />
          </PrivateRoute>
          <PrivateRoute path="/projects">
            <ProjectList />
          </PrivateRoute>
          <PrivateRoute path="/products">
            <ProductList />
          </PrivateRoute>
          <PrivateRoute path="/users">
            <UserList />
          </PrivateRoute>
          <PublicOnlyRoute path="/login">
            <Login />
          </PublicOnlyRoute>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
        <div
          id="toast-container"
          className="fixed bottom-0 left-0 right-0 z-50 sm:bottom-auto sm:left-auto sm:top-16 sm:right-4"
        ></div>
        <meta name="enviroment" content={process.env.REACT_APP_ENVIROMENT} />
      </Router>
    </ProvideAuth>
  );
}

export default App;
