import axiosInstance from './axiosConfig';
import { constants } from './constants';

export default class GeneralApi {
  constructor(auth, history) {
    this.auth = auth;
    this.history = history;
  }

  async get(apiPath) {
    try {
      const result = await axiosInstance.get(`${constants.API_URL}${apiPath}`);
      return {
        success: true,
        message: 'Pedido correcto',
        data: result.data,
      };
    } catch (error) {
      console.log(error);
      if (error.response.status === 440) {
        this.auth.signout(() => this.history.push('/'));
        return {
          success: false,
          message: 'Sesión finalizada',
        };
      }
      return {
        success: false,
        message:
          error.response != null
            ? error.response.data.message
            : 'Ocurrió un error',
      };
    }
  }

  async post(apiPath, data) {
    try {
      const result = await axiosInstance.post(
        `${constants.API_URL}${apiPath}`,
        data
      );
      return {
        success: true,
        message: 'Pedido correcto',
        data: result.data,
      };
    } catch (error) {
      console.log(error);
      if (error.response.status === 440) {
        this.auth.signout(() => this.history.push('/'));
        return {
          success: false,
          message: 'Sesión finalizada',
        };
      }
      return {
        success: false,
        message:
          error.response != null
            ? error.response.data.message
            : 'Ocurrió un error',
      };
    }
  }

  async put(apiPath, data) {
    try {
      const result = await axiosInstance.put(
        `${constants.API_URL}${apiPath}`,
        data
      );
      return {
        success: true,
        message: 'Pedido correcto',
        data: result.data,
      };
    } catch (error) {
      console.log(error);
      if (error.response.status === 440) {
        this.auth.signout(() => this.history.push('/'));
        return {
          success: false,
          message: 'Sesión finalizada',
        };
      }
      return {
        success: false,
        message:
          error.response != null
            ? error.response.data.message
            : 'Ocurrió un error',
      };
    }
  }
}
