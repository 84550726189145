import { Transition } from '@headlessui/react';

export default function Pagination({
  page,
  amountPerPage,
  total,
  maxPage,
  onPreviousPage,
  onNextPage,
}) {
  return (
    <Transition
      show={total > 0}
      as="nav"
      className="bg-white mt-3 px-4 py-3 flex items-center shadow sm:rounded-lg justify-between border-t border-gray-200 sm:px-6"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          Mostrando desde
          <span className="font-medium">
            {' '}
            {(page - 1) * amountPerPage + 1}
          </span>{' '}
          hasta
          <span className="font-medium">
            {' '}
            {page * amountPerPage <= total ? page * amountPerPage : total}
          </span>{' '}
          de
          <span className="font-medium"> {total}</span> resultados
        </p>
      </div>
      <div className="flex-1 flex justify-between sm:justify-end">
        <button
          onClick={() => {
            if (page === 1) return;
            onPreviousPage();
          }}
          disabled={page <= 1}
          className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Anterior
        </button>
        <button
          onClick={() => {
            if (page === maxPage) return;
            onNextPage();
          }}
          disabled={page >= maxPage}
          className="ml-3 inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Siguiente
        </button>
      </div>
    </Transition>
  );
}
