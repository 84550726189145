import { Transition } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import highlightText from '@brianmcallister/highlight-text';
import {
  ArchiveIcon,
  ChevronRightIcon,
  SearchIcon,
} from '@heroicons/react/outline';
import { QrcodeIcon } from '@heroicons/react/solid';

import { useAuth } from '../../contexts/Auth';
import GeneralApi from '../../utils/generalApi';
import PaginationApi from '../../utils/paginationApi';

import CreateForm from '../../components/Product/CreateForm';
import EditForm from '../../components/Product/EditForm';
import Details from '../../components/Product/Details';

import { showErrorToast, showSuccessToast } from '../../components/Toast';
import ConfirmModalContent from '../../components/ConfirmModalContent';
import LoadingSpinner from '../../components/LoadingSpinner';
import EmptyState from '../../components/EmptyState';
import PageHeader from '../../components/PageHeader';
import Pagination from '../../components/Pagination';
import SlideOver from '../../components/SlideOver';
import TabItem from '../../components/TabItem';
import Modal from '../../components/Modal';

export default function ProductList() {
  let { categoryId } = useParams();
  let history = useHistory();
  let auth = useAuth();

  // Product List
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);

  // Filters
  const [categories, setCategories] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState(categoryId || '');
  const [searchFilter, setSearchFilter] = useState('');

  // Pagination
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [total, setTotal] = useState(0);

  const amountPerPage = 9;

  const generalApi = new GeneralApi(auth, history);
  const productsApi = new PaginationApi(
    '/products/list',
    amountPerPage,
    auth,
    history
  );

  // Slide-overs
  // Create Product
  const [isProductCreateOpen, setIsProductCreateOpen] = useState(false);

  // Edit Product
  const [isProductEditOpen, setIsProductEditOpen] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState('');

  // Product Details
  const [isProductDetailsOpen, setIsProductDetailsOpen] = useState(false);
  const [detailsProduct, setDetailsProduct] = useState({});

  // Confirm Modals
  // Delete Product
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  function setEditProductData(product) {
    setSelectedProductId(product._id);
    setDetailsProduct(product);
  }

  function setDetailsProductData(product) {
    setSelectedProductId(product._id);
    setDetailsProduct(product);
    setIsProductDetailsOpen(true);
  }

  async function createProduct(data) {
    const result = await generalApi.post(`/products`, data);
    if (!result.success) {
      showErrorToast(result.message);
      return result;
    }
    showSuccessToast('Producto creado');
    setIsProductCreateOpen(false);
    getProducts();
    return result;
  }

  async function updateProduct(data) {
    const result = await generalApi.put(`/products`, {
      ...data,
      product: selectedProductId,
    });
    if (!result.success) {
      showErrorToast(result.message);
      return result;
    }
    showSuccessToast('Producto modificado');
    setIsProductEditOpen(false);
    setSelectedProductId('');
    getProducts();
    return result;
  }

  async function deleteProduct(product_id) {
    setLoadingDelete(true);
    const result = await generalApi.post(`/products/delete`, {
      product: product_id,
    });
    if (!result.success) {
      showErrorToast(result.message);
      setLoadingDelete(false);
      return result;
    }
    showSuccessToast('Producto eliminado');
    setIsConfirmDeleteOpen(false);
    setIsProductDetailsOpen(false);
    setLoadingDelete(false);
    setSelectedProductId('');
    getProducts();
    return result;
  }

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryFilter, searchFilter, page]);

  async function getCategories() {
    setLoading(true);
    const result = await generalApi.post(`/categories/list/active`);
    if (!result.success) {
      showErrorToast(result.message);
      setLoading(false);
      return result;
    }
    setCategories(result.data.categories);
    return result;
  }

  async function getProducts() {
    setLoading(true);
    let result = await productsApi.getContent(page, {
      search: searchFilter,
      category: categoryFilter,
    });
    if (!result.success) {
      setLoading(false);
      return showErrorToast(result.message);
    }
    setProducts(result.data.products);
    setMaxPage(result.data.pagination.maxPage);
    setTotal(result.data.pagination.total);
    setLoading(false);
  }

  return (
    <div>
      {/* Slide-over Create */}
      <SlideOver
        isOpen={isProductCreateOpen}
        onClose={() => {
          setIsProductCreateOpen(false);
        }}
      >
        <CreateForm
          categories={categories}
          toggleFocus={isProductCreateOpen}
          onSubmit={createProduct}
          onCancelClick={() => {
            setIsProductCreateOpen(false);
          }}
        />
      </SlideOver>

      {/* Slide-over Edit */}
      <SlideOver
        isOpen={isProductEditOpen}
        onClose={() => {
          setIsProductEditOpen(false);
        }}
      >
        <EditForm
          product={detailsProduct}
          categories={categories}
          toggleFocus={isProductEditOpen}
          onSubmit={updateProduct}
          onCancelClick={() => {
            setIsProductEditOpen(false);
          }}
        />
      </SlideOver>

      {/* Slide-over Details */}
      <SlideOver
        isOpen={isProductDetailsOpen}
        onClose={() => {
          setIsProductDetailsOpen(false);
        }}
      >
        <Details
          product={detailsProduct}
          toggleFocus={isProductDetailsOpen}
          onCloseClick={() => {
            setIsProductDetailsOpen(false);
            setSelectedProductId('');
          }}
          onEditClick={(product) => {
            setIsProductDetailsOpen(false);
            setEditProductData(product);
            setIsProductEditOpen(true);
          }}
          onDeleteClick={() => {
            setIsConfirmDeleteOpen(true);
          }}
        />
      </SlideOver>

      <Modal
        isOpen={isConfirmDeleteOpen}
        onClose={() => {
          setIsConfirmDeleteOpen(false);
        }}
      >
        <ConfirmModalContent
          onClose={() => {
            setIsConfirmDeleteOpen(false);
          }}
          onConfirm={() => {
            deleteProduct(selectedProductId);
          }}
          title="Eliminar producto"
          content="¿Está seguro que desea eliminar este producto?"
          buttonLoading={loadingDelete}
        />
      </Modal>

      {/* Header */}
      <div className="px-4 py-4 bg-white shadow sm:pb-0 sm:px-6 lg:px-8">
        <PageHeader
          title="Productos"
          buttonText="Crear producto"
          buttonAction={() => {
            setSelectedProductId('');
            setIsProductCreateOpen(true);
          }}
        />
        {/* Product Position filter */}
        <div className="mt-4">
          {/* Dropdown menu on small screens */}
          <div className="sm:hidden">
            <label htmlFor="selected-tab" className="sr-only">
              Escoger tipo de producto
            </label>
            <select
              id="selected-tab"
              name="selected-tab"
              className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
              value={categoryFilter}
              onChange={(event) => {
                setCategoryFilter(event.target.value);
              }}
            >
              <option value="">Todos</option>
              {categories.map((obj, index) => (
                <option key={obj._id} value={obj._id}>
                  {obj.name}
                </option>
              ))}
            </select>
          </div>
          {/* Tabs at small breakpoint and up */}
          <div className="hidden sm:block">
            <nav className="flex -mb-px space-x-8">
              <TabItem
                content="Todos"
                active={categoryFilter === ''}
                onClick={() => {
                  setCategoryFilter('');
                }}
              ></TabItem>
              {categories.map((obj, index) => (
                <TabItem
                  key={obj._id}
                  content={obj.name}
                  active={categoryFilter === obj._id}
                  onClick={() => {
                    setCategoryFilter(obj._id);
                  }}
                ></TabItem>
              ))}
            </nav>
          </div>
        </div>
      </div>

      {/* Products grid */}
      <div className="px-0 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex flex-col mt-4">
          <div className="min-w-full align-middle">
            <div className="flex items-center justify-between px-4 py-3 mb-3 bg-white border-t border-gray-200 shadow sm:rounded-lg sm:px-6">
              <div className="w-full">
                <label htmlFor="inp_search" className="sr-only">
                  Busca por código, nombre o descripción
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    {/* Heroicon name: search */}
                    <SearchIcon className="w-5 h-5 text-gray-400" />
                  </div>
                  <input
                    id="inp_search"
                    name="search"
                    className="block w-full py-2 pl-10 pr-3 leading-5 placeholder-gray-500 bg-white border border-gray-300 rounded-md focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                    placeholder="Busca por código, nombre o descripción"
                    type="search"
                    value={searchFilter}
                    onChange={(e) => {
                      setSearchFilter(e.target.value);
                    }}
                  ></input>
                </div>
              </div>
            </div>
            <Transition
              show={loading}
              className="flex items-center p-4 bg-white shadow sm:rounded-lg"
            >
              <LoadingSpinner color="primary"></LoadingSpinner>
            </Transition>
            <Transition show={!loading} className="shadow sm:hidden">
              <ul className="mt-2 overflow-hidden divide-y divide-gray-200 shadow sm:hidden">
                {products.map((obj) => (
                  <li key={obj._id}>
                    <div
                      onClick={() => {
                        setDetailsProductData(obj);
                        setIsProductDetailsOpen(true);
                      }}
                      className="block px-4 py-4 bg-white hover:bg-gray-50"
                    >
                      <span className="flex items-center space-x-4">
                        <span className="flex flex-1 space-x-2 truncate">
                          <QrcodeIcon className="flex-shrink-0 w-5 h-5 text-gray-400" />
                          <span className="flex flex-col text-sm text-gray-500 truncate">
                            <span
                              className="truncate"
                              dangerouslySetInnerHTML={{
                                __html: highlightText(obj.name || '', [
                                  searchFilter,
                                ]),
                              }}
                            ></span>
                            <span>
                              <span className="font-medium text-gray-900">
                                {obj.quantity.toFixed(2)}
                              </span>
                            </span>
                          </span>
                        </span>
                        {/* Heroicon name: chevron-right */}
                        <ChevronRightIcon className="flex-shrink-0 w-5 h-5 text-gray-400" />
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </Transition>
            <Transition
              show={!loading}
              className="hidden min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg sm:block"
            >
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                      Código
                    </th>
                    <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                      Nombre
                    </th>
                    <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                      Descripción
                    </th>
                    <th className="px-6 py-3 text-xs font-medium tracking-wider text-right text-gray-500 uppercase bg-gray-50">
                      Categoría
                    </th>
                    <th className="px-6 py-3 text-xs font-medium tracking-wider text-right text-gray-500 uppercase bg-gray-50">
                      Subcategoría
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {products.map((obj) => (
                    <tr key={obj._id} className="bg-white">
                      <td className="px-4 py-2 text-sm text-gray-900 whitespace-nowrap">
                        <div className="flex">
                          <button
                            onClick={() => {
                              setDetailsProductData(obj);
                              setIsProductDetailsOpen(true);
                            }}
                            className="inline-flex w-full p-2 space-x-2 text-sm truncate rounded-md cursor-pointer group focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary-500"
                          >
                            {/* Heroicon name: cash */}
                            <QrcodeIcon className="flex-shrink-0 hidden w-5 h-5 text-gray-400 group-hover:text-gray-500 md:block" />
                            <p
                              className="text-gray-500 underline truncate group-hover:text-gray-900"
                              dangerouslySetInnerHTML={{
                                __html: highlightText(obj.code || '', [
                                  searchFilter,
                                ]),
                              }}
                            ></p>
                          </button>
                        </div>
                      </td>
                      <td className="px-6 py-4 text-sm text-left text-gray-500 whitespace-nowrap">
                        <span
                          className="font-medium text-gray-900"
                          dangerouslySetInnerHTML={{
                            __html: highlightText(obj.name || '', [
                              searchFilter,
                            ]),
                          }}
                        ></span>
                      </td>
                      <td className="w-full px-6 py-4 text-sm text-left text-gray-500 max-w-0 whitespace-nowrap">
                        <span
                          className="font-medium text-gray-900"
                          dangerouslySetInnerHTML={{
                            __html: highlightText(obj.description || '', [
                              searchFilter,
                            ]),
                          }}
                        ></span>
                      </td>
                      <td className="px-6 py-4 text-sm text-right text-gray-500 whitespace-nowrap">
                        <span className="font-medium text-gray-900">
                          {obj.category?.name}
                        </span>
                      </td>
                      <td className="px-6 py-4 text-sm text-right text-gray-500 whitespace-nowrap">
                        <span className="font-medium text-gray-900">
                          {obj.subcategory?.name ?? '-'}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Transition>
            <Transition
              show={!loading && products.length === 0}
              className="px-4 py-8 mt-3 bg-white shadow sm:rounded-lg"
            >
              <EmptyState
                title="No se encontraron resultados"
                subtitle={
                  searchFilter === ''
                    ? 'Empieza creando un nuevo producto'
                    : 'No encuentras lo que buscas? Verifica que no sea un error o crea un nuevo producto'
                }
                buttonText="Crear producto"
                buttonAction={() => {
                  setSelectedProductId('');
                  setIsProductCreateOpen(true);
                }}
                icon={
                  <ArchiveIcon className="w-12 h-12 mx-auto text-gray-400" />
                }
              />
            </Transition>
            {/* Pagination */}
            <Pagination
              page={page}
              amountPerPage={amountPerPage}
              total={total}
              maxPage={maxPage}
              onPreviousPage={() => {
                setPage(page - 1);
              }}
              onNextPage={() => {
                setPage(page + 1);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
