import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Document, Page } from 'react-pdf';
import {
  CheckCircleIcon,
  DocumentDownloadIcon,
  XIcon,
  CashIcon as CashOutlineIcon,
} from '@heroicons/react/outline';
import {
  CalendarIcon,
  CashIcon,
  InformationCircleIcon,
  ReceiptTaxIcon,
} from '@heroicons/react/solid';

import { formatDate } from '../../utils/dateUtils';
import GeneralApi from '../../utils/generalApi';
import { useAuth } from '../../contexts/Auth';

import LoadingSpinner from '../LoadingSpinner';
import { showErrorToast } from '../Toast';
import EmptyState from '../EmptyState';
import Modal from '../Modal';

export default function PaymentsDetails({
  customerId,
  toggleFocus,
  refreshPayments,
  setRefreshPayments,
  onCloseClick,
  onCreatePaymentClick,
}) {
  let history = useHistory();
  let auth = useAuth();

  const generalApi = new GeneralApi(auth, history);

  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState({});
  const [payments, setPayments] = useState([]);
  const initialFocusRef = useRef();

  const [voucherUrl, setVoucherUrl] = useState('');
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    if (toggleFocus) {
      setTimeout(() => {
        if (initialFocusRef.current) initialFocusRef.current.focus();
      }, 200);
    }
  }, [toggleFocus]);

  async function getPayments() {
    setLoading(true);
    let result = await generalApi.post('/customers/payments/list', {
      customer: customerId,
    });
    if (!result.success) {
      setLoading(false);
      return showErrorToast(result.message);
    }
    setCustomer(result.data.customer);
    setPayments(result.data.payments);
    setLoading(false);
    setRefreshPayments(false);
  }

  useEffect(() => {
    if (refreshPayments) getPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, refreshPayments]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <>
      <Modal
        size="large"
        isOpen={voucherUrl !== ''}
        onClose={() => {
          setVoucherUrl('');
          setNumPages(null);
          setPageNumber(1);
        }}
      >
        <Document
          file={voucherUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={
            <div className="py-8">
              <LoadingSpinner color="primary" content="Cargando voucher" />
            </div>
          }
          noData=" "
          className="flex justify-center max-w-full p-1 mt-12 overflow-y-scroll max-h-96"
        >
          <Page pageNumber={pageNumber} />
        </Document>
        <div className="flex items-center justify-center flex-1 py-4 space-x-8 bg-gray-100">
          <button
            onClick={() => {
              if (pageNumber === 1) return;
              setPageNumber(pageNumber - 1);
            }}
            disabled={pageNumber <= 1}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          >
            Anterior
          </button>
          <p>
            Página {pageNumber} de {numPages || '0'}
          </p>
          <div className="flex space-x-2">
            <button
              onClick={() => {
                if (pageNumber === numPages) return;
                setPageNumber(pageNumber + 1);
              }}
              disabled={pageNumber >= numPages}
              className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              Siguiente
            </button>
            <a
              className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              href={voucherUrl}
              target="_blank"
              rel="noreferrer"
            >
              <DocumentDownloadIcon className="w-5 h-5" />
            </a>
          </div>
        </div>
      </Modal>
      <div className="flex flex-col h-full overflow-y-scroll bg-white shadow-xl">
        <div className="px-4 py-6 shadow sm:px-6">
          <div className="flex items-start justify-between">
            <h2
              id="slide-over-heading"
              className="text-lg font-medium text-gray-900"
            >
              Pagos del Cliente
            </h2>
            <div className="flex items-center ml-3 h-7">
              <button
                onClick={() => {
                  onCloseClick();
                }}
                className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:ring-2 focus:ring-primary-500"
              >
                <span className="sr-only">Close panel</span>
                {/* Heroicon name: x */}
                <XIcon className="w-6 h-6" />
              </button>
            </div>
          </div>
        </div>
        {/* Main */}
        <div className="h-full">
          <div className="relative h-full">
            <div className="absolute inset-x-0 top-0 w-full py-4 sm:px-6">
              {customer?.status === 'Pagado' ? (
                <div className="flex items-center justify-center px-4 py-2 text-white sm:rounded-full bg-primary-500">
                  Pagado
                  <CheckCircleIcon className="w-6 h-6 ml-2" />
                </div>
              ) : (
                <button
                  onClick={() => {
                    onCreatePaymentClick();
                  }}
                  ref={initialFocusRef}
                  type="button"
                  className="inline-flex items-center justify-center flex-1 flex-shrink-0 w-full px-4 py-2 text-sm font-medium text-white border border-transparent shadow-sm sm:rounded-md bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  Nuevo pago
                </button>
              )}
            </div>
            {loading ? (
              <div className="flex items-center justify-center w-full h-full">
                <LoadingSpinner color="primary" content="Buscando pagos" />
              </div>
            ) : (
              <div className="pt-12">
                <div className="mt-6 sm:mt-8">
                  <div className="block sm:px-6">
                    <div className="flex justify-between text-sm">
                      <span>S/ {customer.paid}</span>
                      <span>S/ {customer.initial_fee}</span>
                    </div>
                    <div className="relative">
                      <div className="absolute inset-0 w-full h-5 shadow-sm sm:rounded-md bg-primary-200"></div>
                      <div
                        className="absolute inset-y-0 flex items-center justify-center h-5 text-sm text-white shadow-sm sm:rounded-md bg-primary-500"
                        style={{
                          width: `${
                            customer.paid > 0 && customer.initial_fee > 0
                              ? (customer.paid / customer.initial_fee) * 100
                              : 0
                          }%`,
                        }}
                      >
                        {customer.paid > 0 &&
                          customer.initial_fee > 0 &&
                          `${(
                            (customer.paid / customer.initial_fee) *
                            100
                          ).toFixed(1)}%`}
                      </div>
                    </div>
                  </div>
                  {payments.length === 0 ? (
                    <div className="mt-12 text-center">
                      <EmptyState
                        title="No se encontraron resultados"
                        subtitle={'Empieza creando un nuevo pago'}
                        icon={
                          <CashOutlineIcon className="w-12 h-12 mx-auto text-gray-400" />
                        }
                      />
                    </div>
                  ) : (
                    <ul className="mt-10 border-t border-gray-200 divide-y divide-gray-200">
                      {payments.map((payment) => (
                        <li key={payment._id}>
                          <div className="block shadow-md">
                            <div className="px-4 py-4">
                              <div className="flex items-center justify-between">
                                <p className="text-sm font-medium truncate text-primary-600">
                                  {payment.description}
                                </p>
                                <div className="flex flex-shrink-0 ml-2">
                                  <p className="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">
                                    {payment.voucher_kind}
                                  </p>
                                </div>
                              </div>
                              <div className="mt-2">
                                <div>
                                  <p className="flex items-center text-sm text-gray-500">
                                    <CashIcon
                                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                    S/ {payment.amount}
                                  </p>
                                  {payment.voucher_file && (
                                    <p className="flex items-center mt-2 text-sm text-gray-500">
                                      <ReceiptTaxIcon
                                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      />
                                      <button
                                        className="underline transition rounded-md outline-none text-primary-600 hover:text-primary-400 focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                        onClick={() =>
                                          setVoucherUrl(payment.voucher_file)
                                        }
                                      >
                                        Ver voucher
                                      </button>
                                    </p>
                                  )}
                                  {payment.observation && (
                                    <p className="flex items-start mt-2 text-sm text-gray-500">
                                      <InformationCircleIcon
                                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      />
                                      {payment.observation}
                                    </p>
                                  )}
                                </div>
                                <div className="flex items-center mt-2 text-sm text-gray-500">
                                  <CalendarIcon
                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  <p>
                                    <time dateTime={payment.date}>
                                      {formatDate(payment.date)}
                                    </time>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
