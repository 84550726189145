export default function PageHeader({ title, buttonText, buttonAction }) {
  return (
    <div className="flex items-center justify-between">
      <h1 className="text-2xl font-bold leading-6 text-gray-900 sm:truncate">
        {title}
      </h1>
      {buttonText && (
        <div className="flex">
          <button
            onClick={buttonAction}
            type="button"
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm order-0 bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:order-1 sm:ml-3"
          >
            {buttonText}
          </button>
        </div>
      )}
    </div>
  );
}
