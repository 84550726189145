import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { XIcon } from '@heroicons/react/outline';

import { useAuth } from '../../contexts/Auth';
import { constants } from '../../utils/constants';
import GeneralApi from '../../utils/generalApi';

import InputErrorMessage from '../InputErrorMessage';
import LoadingSpinner from '../LoadingSpinner';
import { showErrorToast } from '../Toast';

export default function CreateForm({
  categories,
  toggleFocus,
  onSubmit,
  onCancelClick,
}) {
  let history = useHistory();
  let auth = useAuth();

  const {
    register,
    handleSubmit,
    errors,
    reset,
    setError,
    clearErrors,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useForm({
    defaultValues: { quantity: 0 },
  });
  const [chosenCategory, setChosenCategory] = useState(
    categories.length > 0 ? categories[0]._id : ''
  );
  const [subcategories, setSubcategories] = useState([]);

  const initialFocusRef = useRef();
  const generalApi = new GeneralApi(auth, history);

  useEffect(() => {
    getSubcategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenCategory]);

  async function getSubcategories() {
    if (chosenCategory === '') return;
    let result = await generalApi.post('/subcategories/list/category', {
      category: chosenCategory,
    });
    if (!result.success) {
      return showErrorToast(result.message);
    }
    setSubcategories(result.data.subcategories);
  }

  async function submit(data) {
    let response = await onSubmit(data);
    if (!response.success) {
      setError('server', response.message);
      setTimeout(() => {
        clearErrors('server');
      }, 200);
    }
  }

  useEffect(() => {
    if (isSubmitSuccessful) reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  useEffect(() => {
    if (toggleFocus) {
      setTimeout(() => {
        initialFocusRef.current.focus();
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleFocus]);

  return (
    <form
      onSubmit={handleSubmit(submit)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') return e.preventDefault();
      }}
      className="flex flex-col h-full bg-white divide-y divide-gray-200 shadow-xl"
    >
      <div className="flex-1 h-0 overflow-y-auto">
        <div className="px-4 py-6 bg-primary-700 sm:px-6">
          <div className="flex items-center justify-between">
            <h2
              id="slide-over-heading"
              className="text-lg font-medium text-white"
            >
              Nuevo Producto
            </h2>
            <div className="flex items-center ml-3 h-7">
              <button
                type="button"
                onClick={onCancelClick}
                className="rounded-md bg-primary-700 text-primary-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
              >
                <span className="sr-only">Close panel</span>
                {/* Heroicon name: x */}
                <XIcon className="w-6 h-6" />
              </button>
            </div>
          </div>
          <div className="mt-1">
            <p className="text-sm text-primary-300">
              Llene los datos del formulario para crear un nuevo producto.
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-between flex-1">
          <div className="px-4 divide-y divide-gray-200 sm:px-6">
            <div className="pt-6 pb-5 space-y-3">
              <div>
                <label
                  htmlFor="inp_code"
                  className="block text-sm font-medium text-gray-900"
                >
                  Código
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    id="inp_code"
                    name="code"
                    placeholder="Código"
                    ref={(e) => {
                      if (e != null) {
                        register(e, {
                          required: constants.ERROR_MESSAGES.REQUIRED,
                        });
                        initialFocusRef.current = e;
                      }
                    }}
                    className={`${
                      errors.code ? 'inp-border-red' : 'inp-border-primary'
                    } block w-full shadow-sm sm:text-sm rounded-md`}
                  />
                </div>
                <InputErrorMessage error={errors.code}></InputErrorMessage>
              </div>
              <div>
                <label
                  htmlFor="inp_name"
                  className="block text-sm font-medium text-gray-900"
                >
                  Nombre
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    id="inp_name"
                    name="name"
                    placeholder="Nombre"
                    ref={register({
                      required: constants.ERROR_MESSAGES.REQUIRED,
                    })}
                    className={`${
                      errors.name ? 'inp-border-red' : 'inp-border-primary'
                    } block w-full shadow-sm sm:text-sm rounded-md`}
                  ></input>
                </div>
                <InputErrorMessage error={errors.name}></InputErrorMessage>
              </div>
              <div>
                <label
                  htmlFor="inp_description"
                  className="block text-sm font-medium text-gray-900"
                >
                  Descripción
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    id="inp_description"
                    name="description"
                    placeholder="Descripción"
                    ref={register()}
                    className={`${
                      errors.description
                        ? 'inp-border-red'
                        : 'inp-border-primary'
                    } block w-full shadow-sm sm:text-sm rounded-md`}
                  ></input>
                </div>
                <InputErrorMessage
                  error={errors.description}
                ></InputErrorMessage>
              </div>
              <div>
                <label
                  htmlFor="inp_category"
                  className="block text-sm font-medium text-gray-900"
                >
                  Categoría
                </label>
                <div className="mt-1 -space-y-px rounded-md shadow-sm">
                  <div>
                    <label htmlFor="inp_category" className="sr-only">
                      Categoría
                    </label>
                    <select
                      id="inp_category"
                      name="category"
                      defaultValue="Factura"
                      onChange={(e) => {
                        setChosenCategory(e.target.value);
                      }}
                      ref={register({
                        required: constants.ERROR_MESSAGES.REQUIRED,
                      })}
                      className={`${
                        errors.category
                          ? 'inp-border-red'
                          : 'inp-border-primary'
                      } rounded-none rounded-t-md relative block w-full bg-transparent focus:z-10 sm:text-sm`}
                    >
                      <option value="">Elegir categoría</option>
                      {categories.map((obj) => (
                        <option key={obj._id} value={obj._id}>
                          {obj.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label htmlFor="inp_subcategory" className="sr-only">
                      Subcategoría
                    </label>
                    <select
                      id="inp_subcategory"
                      name="subcategory"
                      ref={register()}
                      className={`${
                        errors.subcategory
                          ? 'inp-border-red'
                          : 'inp-border-primary'
                      } rounded-none rounded-b-md relative block w-full bg-transparent focus:z-10 sm:text-sm`}
                    >
                      <option value="">Elegir subcategoría (opcional)</option>
                      {subcategories.map((obj) => (
                        <option key={obj._id} value={obj._id}>
                          {obj.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <InputErrorMessage error={errors.category}></InputErrorMessage>
              </div>
              <div>
                <label
                  htmlFor="inp_quantity"
                  className="block text-sm font-medium text-gray-900"
                >
                  Cantidad
                </label>
                <div className="mt-1">
                  <input
                    type="number"
                    id="inp_quantity"
                    name="quantity"
                    ref={register({
                      required: constants.ERROR_MESSAGES.REQUIRED,
                    })}
                    className={`${
                      errors.quantity ? 'inp-border-red' : 'inp-border-primary'
                    } block w-full shadow-sm sm:text-sm rounded-md`}
                    placeholder="Cantidad"
                  ></input>
                </div>
                <InputErrorMessage error={errors.quantity}></InputErrorMessage>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end flex-shrink-0 px-4 py-4">
        <button
          type="button"
          onClick={onCancelClick}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          Cancelar
        </button>
        <button
          type="submit"
          className="inline-flex justify-center px-4 py-2 ml-4 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          {isSubmitting ? (
            <LoadingSpinner></LoadingSpinner>
          ) : (
            <span>Crear producto</span>
          )}
        </button>
      </div>
    </form>
  );
}
