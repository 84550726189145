import { Route, Redirect } from 'react-router-dom';
import StorageService from '../../utils/storage';

import { useAuth } from '../../contexts/Auth';

export default function PublicOnlyRoute({ children, ...rest }) {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !auth.isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: StorageService.get('userData').redirect || '/dashboard',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
