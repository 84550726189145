import { XIcon } from '@heroicons/react/outline';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { constants } from '../../utils/constants';
import InputErrorMessage from '../InputErrorMessage';
import LoadingSpinner from '../LoadingSpinner';

export default function OperationCreateForm({
  toggleFocus,
  onSubmit,
  onCancelClick,
}) {
  const {
    register,
    handleSubmit,
    errors,
    reset,
    setError,
    clearErrors,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useForm();
  const [voucherKind, setVoucherKind] = useState('Factura');
  const initialFocusRef = useRef();

  async function submit(data) {
    let response = await onSubmit(data);
    if (!response.success) {
      setError('server', response.message);
      setTimeout(() => {
        clearErrors('server');
      }, 200);
    }
  }

  useEffect(() => {
    if (isSubmitSuccessful) reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  useEffect(() => {
    if (toggleFocus) {
      setTimeout(() => {
        initialFocusRef.current.focus();
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleFocus]);

  return (
    <form
      onSubmit={handleSubmit(submit)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') return e.preventDefault();
      }}
      className="flex flex-col h-full bg-white divide-y divide-gray-200 shadow-xl"
    >
      <div className="flex-1 h-0 overflow-y-auto">
        <div className="px-4 py-6 bg-primary-700 sm:px-6">
          <div className="flex items-center justify-between">
            <h2
              id="slide-over-heading"
              className="text-lg font-medium text-white"
            >
              Nueva Operación
            </h2>
            <div className="flex items-center ml-3 h-7">
              <button
                type="button"
                onClick={onCancelClick}
                className="rounded-md bg-primary-700 text-primary-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
              >
                <span className="sr-only">Close panel</span>
                {/* Heroicon name: x */}
                <XIcon className="w-6 h-6" />
              </button>
            </div>
          </div>
          <div className="mt-1">
            <p className="text-sm text-primary-300">
              Llene los datos del formulario para crear una nueva operación.
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-between flex-1">
          <div className="px-4 divide-y divide-gray-200 sm:px-6">
            <div className="pt-6 pb-5 space-y-3">
              <div>
                <label
                  htmlFor="inp_kind"
                  className="block text-sm font-medium text-gray-900"
                >
                  Tipo
                </label>
                <div className="mt-1">
                  <select
                    id="inp_kind"
                    name="kind"
                    ref={(e) => {
                      if (e != null) {
                        register(e);
                        initialFocusRef.current = e;
                      }
                    }}
                    className="relative block w-full bg-transparent border-gray-300 rounded-md focus:ring-primary-500 focus:border-primary-500 focus:z-10 sm:text-sm"
                  >
                    <option value="Ingreso">Ingreso</option>
                    <option value="Egreso">Egreso</option>
                  </select>
                </div>
              </div>
              <div>
                <label
                  htmlFor="inp_motive"
                  className="block text-sm font-medium text-gray-900"
                >
                  Motivo
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    id="inp_motive"
                    name="motive"
                    ref={register({
                      required: constants.ERROR_MESSAGES.REQUIRED,
                    })}
                    className={`${
                      errors.motive ? 'inp-border-red' : 'inp-border-primary'
                    } block w-full shadow-sm sm:text-sm rounded-md`}
                    placeholder="Motivo"
                  ></input>
                </div>
                <InputErrorMessage error={errors.motive}></InputErrorMessage>
              </div>
              <div>
                <label
                  htmlFor="inp_voucher_kind"
                  className="block text-sm font-medium text-gray-900"
                >
                  Comprobante
                </label>
                <div className="mt-1 -space-y-px rounded-md shadow-sm">
                  <div>
                    <label htmlFor="inp_voucher_kind" className="sr-only">
                      Tipo de comprobante
                    </label>
                    <select
                      id="inp_voucher_kind"
                      name="voucher_kind"
                      defaultValue="Factura"
                      onChange={(e) => {
                        setVoucherKind(e.target.value);
                      }}
                      ref={register()}
                      className={`${
                        errors.voucher_number
                          ? 'inp-border-red'
                          : 'inp-border-primary'
                      } ${
                        voucherKind !== 'Sin comprobante'
                          ? 'rounded-none rounded-t-md'
                          : 'rounded-md'
                      } relative block w-full bg-transparent focus:z-10 sm:text-sm`}
                    >
                      <option value="Factura">Factura</option>
                      <option value="Boleta">Boleta</option>
                      <option value="Sin comprobante">Sin comprobante</option>
                    </select>
                  </div>
                  {voucherKind !== 'Sin comprobante' ? (
                    <div>
                      <label htmlFor="inp_voucher_number" className="sr-only">
                        Número de comprobante
                      </label>
                      <input
                        type="text"
                        id="inp_voucher_number"
                        name="voucher_number"
                        ref={register()}
                        className={`${
                          errors.voucher_number
                            ? 'inp-border-red'
                            : 'inp-border-primary'
                        } relative block w-full rounded-none rounded-b-md bg-transparent focus:z-10 sm:text-sm`}
                        placeholder="Número de comprobante"
                      ></input>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <InputErrorMessage
                  error={errors.voucher_number}
                ></InputErrorMessage>
              </div>
              <div>
                <label
                  htmlFor="inp_amount"
                  className="block text-sm font-medium text-gray-900"
                >
                  Monto
                </label>
                <div className="mt-1">
                  <input
                    type="number"
                    id="inp_amount"
                    name="amount"
                    ref={register({
                      required: constants.ERROR_MESSAGES.REQUIRED,
                    })}
                    className={`${
                      errors.amount ? 'inp-border-red' : 'inp-border-primary'
                    } block w-full shadow-sm sm:text-sm rounded-md`}
                    placeholder="Monto"
                  ></input>
                </div>
                <InputErrorMessage error={errors.amount}></InputErrorMessage>
              </div>
              <div>
                <label
                  htmlFor="inp_observation"
                  className="block text-sm font-medium text-gray-900"
                >
                  Observación
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    id="inp_observation"
                    name="observation"
                    ref={register({
                      required: constants.ERROR_MESSAGES.REQUIRED,
                    })}
                    className={`${
                      errors.observation
                        ? 'inp-border-red'
                        : 'inp-border-primary'
                    } block w-full shadow-sm sm:text-sm rounded-md`}
                    placeholder="Observación"
                  ></input>
                </div>
                <InputErrorMessage
                  error={errors.observation}
                ></InputErrorMessage>
              </div>
              <div>
                <label
                  htmlFor="inp_date"
                  className="block text-sm font-medium text-gray-900"
                >
                  Fecha
                </label>
                <div className="mt-1">
                  <input
                    type="date"
                    id="inp_date"
                    name="date"
                    defaultValue={new Date().toISOString().substr(0, 10)}
                    ref={register({
                      required: constants.ERROR_MESSAGES.REQUIRED,
                    })}
                    className={`${
                      errors.date ? 'inp-border-red' : 'inp-border-primary'
                    } block w-full shadow-sm sm:text-sm rounded-md`}
                    placeholder="Fecha"
                  ></input>
                </div>
                <InputErrorMessage error={errors.date}></InputErrorMessage>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end flex-shrink-0 px-4 py-4">
        <button
          type="button"
          onClick={onCancelClick}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          Cancelar
        </button>
        <button
          type="submit"
          className="inline-flex justify-center px-4 py-2 ml-4 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          {isSubmitting ? (
            <LoadingSpinner></LoadingSpinner>
          ) : (
            <span>Crear operación</span>
          )}
        </button>
      </div>
    </form>
  );
}
