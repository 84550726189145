import { Link, useLocation } from 'react-router-dom';

export default function SidebarItem({ path, isChild, ...rest }) {
  const location = useLocation();

  return (
    <Link
      to={path}
      className={`${
        location.pathname === path
          ? 'bg-gray-100 text-gray-900 border-l-8'
          : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900 hover:border-l-8 focus:bg-gray-50 focus:text-gray-900 focus:border-l-8'
      } ${
        isChild ? 'ml-6' : ''
      } group flex items-center py-2 px-2 text-sm font-medium rounded-md border-primary-600 transition-all duration-150 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary-500`}
      {...rest}
    ></Link>
  );
}
