import { Transition } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth';
import { SearchIcon, TagIcon } from '@heroicons/react/outline';

import GeneralApi from '../../utils/generalApi';
import PaginationApi from '../../utils/paginationApi';

import CategoryCreateForm from '../../components/Category/CategoryCreateForm';
import CategoryEditForm from '../../components/Category/CategoryEditForm';
import CategoryCard from '../../components/Category/CategoryCard';

import { showErrorToast, showSuccessToast } from '../../components/Toast';
import ConfirmModalContent from '../../components/ConfirmModalContent';
import LoadingSpinner from '../../components/LoadingSpinner';
import Pagination from '../../components/Pagination';
import EmptyState from '../../components/EmptyState';
import Modal from '../../components/Modal';

export default function CategoryList() {
  let history = useHistory();
  let auth = useAuth();

  // Category List
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);

  // Filters
  const [searchFilter, setSearchFilter] = useState('');

  // Pagination
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [total, setTotal] = useState(0);

  const amountPerPage = 9;

  const generalApi = new GeneralApi(auth, history);
  const categoriesApi = new PaginationApi(
    '/categories/list',
    amountPerPage,
    auth,
    history
  );

  // Slide-overs
  // Create Category
  const [isCategoryCreateOpen, setIsCategoryCreateOpen] = useState(false);
  const [createColor, setCreateColor] = useState({ hex: '#3B82F6' });

  // Edit Category
  const [isCategoryEditOpen, setIsCategoryEditOpen] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [detailsCategory, setDetailsCategory] = useState({});
  const [editColor, setEditColor] = useState({ hex: '#3B82F6' });

  // Confirm Modals
  // Disable Category
  const [isConfirmDisableOpen, setIsConfirmDisableOpen] = useState(false);
  const [loadingToggle, setLoadingToggle] = useState(false);

  // Delete Category
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  function setEditCategoryData(category) {
    setSelectedCategoryId(category._id);
    setEditColor({ hex: category.color });
    setDetailsCategory(category);
  }

  async function createCategory(data) {
    const result = await generalApi.post(`/categories`, data);
    if (!result.success) {
      showErrorToast(result.message);
      return result;
    }
    showSuccessToast('Categoría creada');
    setIsCategoryCreateOpen(false);
    getCategories();
    return result;
  }

  async function updateCategory(data) {
    const result = await generalApi.put(`/categories`, {
      ...data,
      category: selectedCategoryId,
    });
    if (!result.success) {
      showErrorToast(result.message);
      return result;
    }
    showSuccessToast('Categoría modificada');
    setIsCategoryEditOpen(false);
    setSelectedCategoryId('');
    getCategories();
    return result;
  }

  async function toggleCategory(category_id, is_active) {
    setLoadingToggle(true);
    const result = await generalApi.post(`/categories/status`, {
      category: category_id,
      is_active: is_active,
    });
    if (!result.success) {
      showErrorToast(result.message);
      setLoadingToggle(false);
      return result;
    }
    showSuccessToast(
      is_active ? 'Categoría habilitada' : 'Categoría deshabilitada'
    );
    setIsConfirmDisableOpen(false);
    setLoadingToggle(false);
    setSelectedCategoryId('');
    getCategories();
    return result;
  }

  async function deleteCategory(category_id) {
    setLoadingDelete(true);
    const result = await generalApi.post(`/categories/delete`, {
      category: category_id,
    });
    if (!result.success) {
      showErrorToast(result.message);
      setLoadingDelete(false);
      return result;
    }
    showSuccessToast('Categoría eliminada');
    setIsConfirmDeleteOpen(false);
    setLoadingDelete(false);
    setSelectedCategoryId('');
    getCategories();
    return result;
  }

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilter, page]);

  async function getCategories() {
    setLoading(true);
    let result = await categoriesApi.getContent(page, {
      search: searchFilter,
    });
    if (!result.success) {
      setLoading(false);
      return showErrorToast(result.message);
    }
    setCategories(result.data.categories);
    setMaxPage(result.data.pagination.maxPage);
    setTotal(result.data.pagination.total);
    setLoading(false);
  }

  return (
    <div>
      <Modal
        isOpen={isConfirmDisableOpen}
        onClose={() => {
          setIsConfirmDisableOpen(false);
        }}
      >
        <ConfirmModalContent
          onClose={() => {
            setIsConfirmDisableOpen(false);
          }}
          onConfirm={() => {
            toggleCategory(selectedCategoryId, false);
          }}
          title="Deshabilitar categoría"
          content="¿Está seguro que desea deshabilitar esta categoría? La categoría ya no podrá ser usada."
          buttonLoading={loadingToggle}
        />
      </Modal>

      <Modal
        isOpen={isConfirmDeleteOpen}
        onClose={() => {
          setIsConfirmDeleteOpen(false);
        }}
      >
        <ConfirmModalContent
          onClose={() => {
            setIsConfirmDeleteOpen(false);
          }}
          onConfirm={() => {
            deleteCategory(selectedCategoryId);
          }}
          title="Eliminar categoría"
          content="¿Está seguro que desea eliminar esta categoría?"
          buttonLoading={loadingDelete}
        />
      </Modal>

      {/* Header */}
      <div className="px-4 py-4 bg-white shadow sm:px-6 lg:px-8">
        <div className="md:flex md:items-center md:justify-between">
          <h1 className="text-2xl font-bold leading-6 text-gray-900 py-1.5">
            Categorías
          </h1>
        </div>
      </div>

      {/* Categories grid */}
      <div className="relative z-0 flex flex-col-reverse flex-1 overflow-hidden sm:flex-row">
        <main className="relative z-0 flex-1 px-0 overflow-y-auto focus:outline-none sm:px-6 lg:px-8">
          <div className="flex flex-col mb-4 sm:my-4">
            <div className="min-w-full align-middle">
              <div className="flex items-center justify-between px-4 py-3 mb-3 bg-white border-t border-gray-200 shadow sm:rounded-lg sm:px-6">
                <div className="w-full">
                  <label htmlFor="inp_search" className="sr-only">
                    Busca por nombre de la categoría
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      {/* Heroicon name: search */}
                      <SearchIcon className="w-5 h-5 text-gray-400" />
                    </div>
                    <input
                      id="inp_search"
                      name="search"
                      className="block w-full py-2 pl-10 pr-3 leading-5 placeholder-gray-500 bg-white border border-gray-300 rounded-md focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                      placeholder="Busca por nombre de la categoría"
                      type="search"
                      value={searchFilter}
                      onChange={(e) => {
                        setSearchFilter(e.target.value);
                      }}
                    ></input>
                  </div>
                </div>
              </div>
              <Transition
                show={loading}
                className="flex items-center p-4 bg-white shadow sm:rounded-lg"
              >
                <LoadingSpinner color="primary"></LoadingSpinner>
              </Transition>
              <Transition
                show={!loading}
                as="ul"
                className="grid grid-cols-1 gap-x-6 gap-y-3 md:grid-cols-2"
              >
                {categories.map((obj) => (
                  <CategoryCard
                    key={obj._id}
                    category={obj}
                    searchWords={[searchFilter]}
                    onEditClick={(category) => {
                      setEditCategoryData(category);
                      setIsCategoryEditOpen(true);
                    }}
                    onToggleClick={(category) => {
                      setSelectedCategoryId(category._id);
                      if (category.is_active)
                        return setIsConfirmDisableOpen(true);
                      toggleCategory(category._id, true);
                    }}
                    onDeleteClick={(category) => {
                      setSelectedCategoryId(category._id);
                      setIsConfirmDeleteOpen(true);
                    }}
                  ></CategoryCard>
                ))}
              </Transition>
              <Transition
                show={!loading && categories.length === 0}
                className="px-4 py-8 bg-white shadow sm:rounded-lg"
              >
                <EmptyState
                  title="No se encontraron resultados"
                  subtitle={
                    searchFilter === ''
                      ? 'Empieza creando una nueva categoría en el panel derecho'
                      : 'No encuentras lo que buscas? Verifica que no sea un error o crea una nuevo categoría en el panel derecho'
                  }
                  icon={<TagIcon className="w-12 h-12 mx-auto text-gray-400" />}
                />
              </Transition>
              {/* Pagination */}
              <Pagination
                page={page}
                amountPerPage={amountPerPage}
                total={total}
                maxPage={maxPage}
                onPreviousPage={() => {
                  setPage(page - 1);
                }}
                onNextPage={() => {
                  setPage(page + 1);
                }}
              />
            </div>
          </div>
        </main>
        <aside className="relative flex flex-col w-full px-0 mx-auto xl:flex-shrink-0 max-w-96 sm:max-w-none sm:w-64 xl:w-96 sm:pr-6 lg:pr-8">
          <div className="flex flex-col mt-4 sm:my-4">
            <div className="min-w-full align-middle">
              <div className="flex flex-col items-center justify-between mb-3 bg-white border-t border-gray-200 shadow sm:rounded-lg">
                <div className="w-full p-4 bg-white border-b border-gray-200 sm:rounded-t-lg sm:px-6">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    {isCategoryEditOpen
                      ? 'Editar Categoría'
                      : 'Crear Categoría'}
                  </h3>
                  <p className="hidden mt-1 text-sm text-gray-500 sm:block">
                    {isCategoryEditOpen
                      ? 'Llene los datos del formulario para editar la categoría.'
                      : 'Llene los datos del formulario para crear una nueva categoría.'}
                  </p>
                </div>
                <div className="w-full bg-white border-b border-gray-200 sm:rounded-lg">
                  {isCategoryEditOpen ? (
                    <CategoryEditForm
                      category={detailsCategory}
                      color={editColor}
                      toggleFocus={selectedCategoryId}
                      onColorChange={(color) => {
                        setEditColor(color);
                      }}
                      onSubmit={updateCategory}
                      onCancelClick={() => {
                        setIsCategoryEditOpen(false);
                      }}
                    ></CategoryEditForm>
                  ) : (
                    <CategoryCreateForm
                      color={createColor}
                      toggleFocus={isCategoryCreateOpen}
                      onColorChange={(color) => {
                        setCreateColor(color);
                      }}
                      onSubmit={createCategory}
                    ></CategoryCreateForm>
                  )}
                </div>
              </div>
            </div>
          </div>
        </aside>
      </div>
    </div>
  );
}
