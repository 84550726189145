import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { DotsVerticalIcon, XIcon } from '@heroicons/react/outline';

import { useAuth } from '../../contexts/Auth';
import { constants } from '../../utils/constants';
import GeneralApi from '../../utils/generalApi';

import { showErrorToast } from '../Toast';
import LoadingSpinner from '../LoadingSpinner';
import { Menu, Transition } from '@headlessui/react';

export default function CustomerDetails({
  customerId,
  toggleFocus,
  refreshDetails,
  setRefreshDetails,
  onCloseClick,
  onPaymentsClick,
  onDocumentsClick,
  onEditClick,
  onDeleteClick,
}) {
  let history = useHistory();
  let auth = useAuth();

  const generalApi = new GeneralApi(auth, history);

  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState({});
  const initialFocusRef = useRef();

  useEffect(() => {
    if (toggleFocus) {
      setTimeout(() => {
        if (initialFocusRef.current) initialFocusRef.current.focus();
      }, 200);
    }
  }, [toggleFocus, customer]);

  async function getCustomerDetails() {
    setLoading(true);
    let result = await generalApi.post('/customers/details', {
      customer: customerId,
    });
    if (!result.success) {
      setLoading(false);
      return showErrorToast(result.message);
    }
    setCustomer(result.data.customer);
    setLoading(false);
    setRefreshDetails(false);
  }

  useEffect(() => {
    if (refreshDetails) getCustomerDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, refreshDetails]);

  return (
    <div className="flex flex-col h-full overflow-y-scroll bg-white shadow-xl">
      <div className="px-4 py-6 sm:px-6">
        <div className="flex items-start justify-between">
          <h2
            id="slide-over-heading"
            className="text-lg font-medium text-gray-900"
          >
            Datos del Cliente
          </h2>
          <div className="flex items-center ml-3 h-7">
            <button
              onClick={() => {
                onCloseClick();
              }}
              className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:ring-2 focus:ring-primary-500"
            >
              <span className="sr-only">Close panel</span>
              {/* Heroicon name: x */}
              <XIcon className="w-6 h-6" />
            </button>
          </div>
        </div>
      </div>
      {/* Main */}
      {loading ? (
        <div className="flex items-center justify-center w-full h-full px-4 text-center">
          <LoadingSpinner
            color="primary"
            content="Cargando detalles del cliente"
          />
        </div>
      ) : (
        <div>
          <div className="pb-1 sm:pb-6">
            <div>
              <div className="relative h-20 lg:h-28 bg-primary-700"></div>
              <div className="px-4 mt-6 sm:mt-8 sm:flex sm:items-end sm:px-6">
                <div className="sm:flex-1">
                  <div>
                    <div className="items-center sm:flex">
                      <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
                        {customer.first_name} {customer.last_name_p}{' '}
                        {customer.last_name_m} - #{customer.department}
                      </h3>
                      <span
                        className={`${
                          customer.status === 'Pagado'
                            ? 'text-green-800 bg-green-100'
                            : 'text-yellow-800 bg-yellow-100'
                        } mt-2.5 sm:mt-0 sm:ml-2.5 inline-block px-3 py-0.5 text-sm font-medium rounded-full`}
                      >
                        {customer.status}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-wrap mt-5 space-y-3 sm:space-y-0 sm:space-x-3">
                    <button
                      onClick={onPaymentsClick}
                      ref={initialFocusRef}
                      type="button"
                      className="inline-flex items-center justify-center flex-shrink-0 w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:flex-1"
                    >
                      Pagos
                    </button>
                    <button
                      type="button"
                      onClick={onDocumentsClick}
                      className="inline-flex items-center justify-center flex-1 w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    >
                      Documentos
                    </button>
                    <span className="inline-flex ml-3 sm:ml-0">
                      <div className="relative inline-block text-left">
                        <Menu>
                          {({ open }) => (
                            <>
                              <Menu.Button className="inline-flex items-center p-2 text-sm font-medium text-gray-400 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                                <span className="sr-only">
                                  Open options menu
                                </span>
                                {/* Heroicon name: dots-vertical */}
                                <DotsVerticalIcon className="w-5 h-5" />
                              </Menu.Button>

                              <Transition
                                show={open}
                                enter="transition ease-out duration-100"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                              >
                                <Menu.Items
                                  static
                                  className="absolute right-0 w-48 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5"
                                >
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button
                                        className={`${
                                          active
                                            ? 'bg-gray-100 text-gray-900'
                                            : 'text-gray-700'
                                        } block px-4 py-2 text-sm cursor-pointer w-full text-left`}
                                        onClick={() => onEditClick(customer)}
                                      >
                                        Editar
                                      </button>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button
                                        className={`${
                                          active
                                            ? 'text-red-700 bg-gray-100'
                                            : 'text-red-500'
                                        } block px-4 py-2  rounded-b-md text-white text-sm cursor-pointer transition-colors w-full text-left`}
                                        onClick={onDeleteClick}
                                      >
                                        Eliminar
                                      </button>
                                    )}
                                  </Menu.Item>
                                </Menu.Items>
                              </Transition>
                            </>
                          )}
                        </Menu>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="px-4 pt-5 pb-5 sm:px-0 sm:pt-0">
            <dl className="space-y-8 sm:px-6 sm:space-y-6">
              <div>
                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                  Documento
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                  {constants.DOCUMENT_KIND_DISPLAY[customer.document_kind]}:{' '}
                  {customer.document_number}
                </dd>
              </div>
              <div>
                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                  Contacto
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                  {customer.email} - {customer.phone_number}
                </dd>
              </div>
              <div>
                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                  Departmento
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                  #{customer.department} - S/ {customer.total_cost}
                </dd>
              </div>
              <div>
                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                  Cuota inicial
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                  S/ {customer.initial_fee}
                  {customer.initial_fee > customer.paid && (
                    <span>
                      {' '}
                      (Pendiente: S/{' '}
                      {customer.initial_fee - (customer.paid || 0)})
                    </span>
                  )}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      )}
    </div>
  );
}
