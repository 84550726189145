import StorageService from '../../utils/storage';

import LinkButton from '../../components/LinkButton';

import image from '../../resources/img/illustrations/404.svg';

export default function NotFound() {
  return (
    <section className="text-gray-700 body-font">
      <div className="container flex flex-col items-center px-5 py-24 mx-auto md:flex-row">
        <div className="w-5/6 mb-10 lg:max-w-lg lg:w-full md:w-1/2 md:mb-0">
          <img
            className="object-cover object-center rounded"
            alt="hero"
            src={image}
          />
        </div>
        <div className="flex flex-col items-center text-center lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 md:items-start md:text-left">
          <h1 className="mb-4 text-3xl font-medium text-gray-900 title-font sm:text-4xl">
            Error
            <br className="hidden lg:inline-block" />
            <span className="ml-2 sm:ml-0">404</span>
          </h1>
          <p className="mb-8 leading-relaxed">
            Ups! La página que busca no existe
          </p>
          <div className="flex justify-center">
            <LinkButton
              to={StorageService.get('userData').redirect || '/dashboard'}
              type="button"
              className="inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              Regresar
            </LinkButton>
          </div>
        </div>
      </div>
    </section>
  );
}
