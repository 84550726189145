import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import {
  ChatIcon,
  DocumentDownloadIcon,
  FolderOpenIcon,
  PrinterIcon,
  XIcon,
} from '@heroicons/react/outline';
import { ArrowsExpandIcon } from '@heroicons/react/solid';

import { formatDate } from '../../utils/dateUtils';
import { constants } from '../../utils/constants';
import GeneralApi from '../../utils/generalApi';
import PdfClient from '../../utils/pdfClient';
import { useAuth } from '../../contexts/Auth';

import LoadingSpinner from '../LoadingSpinner';
import { showErrorToast } from '../Toast';
import EmptyState from '../EmptyState';
import Modal from '../Modal';

export default function DocumentsDetails({
  folderId,
  customerId,
  toggleFocus,
  refreshDocuments,
  setRefreshDocuments,
  onCloseClick,
  onCreateDocumentClick,
}) {
  let history = useHistory();
  let auth = useAuth();

  const generalApi = new GeneralApi(auth, history);

  const [loading, setLoading] = useState(true);
  const [documents, setDocuments] = useState([]);
  const initialFocusRef = useRef();

  const [documentName, setDocumentName] = useState('');
  const [imageUrl, setImageUrl] = useState('');

  const [currentDocument, setCurrentDocument] = useState(null);

  useEffect(() => {
    if (toggleFocus) {
      setTimeout(() => {
        if (initialFocusRef.current) initialFocusRef.current.focus();
      }, 200);
    }
  }, [toggleFocus]);

  async function getDocuments() {
    setLoading(true);
    let result = await generalApi.post('/folders/documents/list', {
      customer: customerId,
      folder: folderId,
    });
    if (!result.success) {
      setLoading(false);
      return showErrorToast(result.message);
    }
    setDocuments(result.data.documents);
    setLoading(false);
    setRefreshDocuments(false);
  }

  useEffect(() => {
    if (refreshDocuments) getDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, folderId, refreshDocuments]);

  const handleOpenFile = (fileName, fileUrl, fileType) => {
    if (fileType === 'pdf') {
      const pdfClient = new PdfClient();
      pdfClient.ready().then(() => {
        pdfClient.previewFile(fileName, fileUrl, {
          embedMode: 'LIGHT_BOX',
        });
      });
    } else if (fileType === 'image') {
      setDocumentName(fileName);
      setImageUrl(fileUrl);
    }
  };

  return (
    <>
      <Modal
        size="large"
        isOpen={imageUrl !== ''}
        onClose={() => {
          setImageUrl('');
          setDocumentName('');
        }}
      >
        <p className="p-2 mx-auto text-lg bg-gray-100 sm:pl-4 sm:pr-12 sm:py-4">
          {documentName}
        </p>
        {imageUrl !== '' ? (
          <>
            <img
              src={imageUrl}
              className="flex justify-center max-w-full p-1 mx-auto mb-2 overflow-y-scroll max-h-96"
              alt="Imagen del documento"
            ></img>
            <div className="flex items-center justify-center flex-1 py-4 space-x-8 bg-gray-100">
              <a
                className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                href={imageUrl}
                target="_blank"
                rel="noreferrer"
                download
              >
                Descargar
                <DocumentDownloadIcon className="w-5 h-5 ml-2" />
              </a>
              <button
                className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                onClick={() => {
                  let popup = window.open('about:blank', '_new');
                  popup.document.open();
                  popup.document.write(
                    `<html><head>
                      <script>
                        function step1(){setTimeout(step2(), 10);}
                        function step2(){window.print();window.close()}
                      </script>
                    </head>
                    <body onload=step1()>
                      <img src='${imageUrl}'/>
                    </body></html>`
                  );
                  popup.document.close();
                }}
              >
                Imprimir
                <PrinterIcon className="w-5 h-5 ml-2" />
              </button>
            </div>
          </>
        ) : (
          <div className="h-20"></div>
        )}
      </Modal>
      <Modal
        isOpen={currentDocument !== null}
        onClose={() => setCurrentDocument(null)}
      >
        <p className="p-2 mx-auto text-lg bg-gray-100 sm:pl-4 sm:pr-12 sm:py-4">
          Comentarios
        </p>
        <ul>
          {currentDocument?.comments &&
            currentDocument?.comments.map((comment, index) => (
              <li key={comment._id}>
                <div className="relative p-4">
                  {index !== currentDocument?.comments.length - 1 ? (
                    <span
                      className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                      aria-hidden="true"
                    />
                  ) : null}
                  <div className="relative flex items-center space-x-3">
                    <div>
                      <span className="flex items-center justify-center w-8 h-8 rounded-full ring-8 ring-white bg-primary-500">
                        <ChatIcon
                          className="w-5 h-5 text-white"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                      <div>
                        <p className="text-sm text-gray-500">
                          {comment.content}
                        </p>
                      </div>
                      <div className="text-sm text-right text-gray-500 whitespace-nowrap">
                        <time dateTime={comment.created_at}>
                          {formatDate(comment.created_at)}
                        </time>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </Modal>
      <div className="flex flex-col h-full overflow-y-scroll bg-white shadow-xl">
        <div className="px-4 py-6 shadow sm:px-6">
          <div className="flex items-start justify-between">
            <h2
              id="slide-over-heading"
              className="text-lg font-medium text-gray-900"
            >
              {folderId ? 'Documentos en la carpeta' : 'Documentos del Cliente'}
            </h2>
            <div className="flex items-center ml-3 h-7">
              <button
                onClick={() => {
                  onCloseClick();
                }}
                className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:ring-2 focus:ring-primary-500"
              >
                <span className="sr-only">Close panel</span>
                {/* Heroicon name: x */}
                <XIcon className="w-6 h-6" />
              </button>
            </div>
          </div>
        </div>
        {/* Main */}
        <div className="h-full">
          <div className="relative h-full">
            <div className="absolute inset-x-0 top-0 w-full py-4 sm:px-6">
              <button
                onClick={() => {
                  onCreateDocumentClick();
                }}
                ref={initialFocusRef}
                type="button"
                className="inline-flex items-center justify-center flex-1 flex-shrink-0 w-full px-4 py-2 text-sm font-medium text-white border border-transparent shadow-sm sm:rounded-md bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              >
                Nuevo documento
              </button>
            </div>
            {loading ? (
              <div className="flex items-center justify-center w-full h-full">
                <LoadingSpinner color="primary" content="Buscando documentos" />
              </div>
            ) : (
              <div className="pt-8">
                <div className="mt-6 sm:mt-8">
                  {documents.length === 0 ? (
                    <div className="mt-12 text-center">
                      <EmptyState
                        title="No se encontraron resultados"
                        subtitle={'Empieza creando un nuevo documento'}
                        icon={
                          <FolderOpenIcon className="w-12 h-12 mx-auto text-gray-400" />
                        }
                      />
                    </div>
                  ) : (
                    <ul className="mt-10 border-t border-gray-200">
                      {documents.map((document) => (
                        <li
                          key={document._id}
                          className="p-3 border-b border-gray-200 sm:p-4"
                        >
                          <div className="items-center space-y-2 sm:space-x-1 sm:space-y-0 sm:flex">
                            <div className="flex-1 min-w-0 space-y-1">
                              <div className="flex space-x-1">
                                <p className="flex justify-center px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full flex-shrink-">
                                  {
                                    constants.FILE_TYPE_DISPLAY[
                                      document.file_type
                                    ]
                                  }
                                </p>
                                <p className="text-sm text-gray-500 truncate">
                                  {formatDate(document.created_at)}
                                </p>
                              </div>
                              <p
                                className="text-sm font-medium text-gray-900 truncate"
                                title={document.name}
                              >
                                {document.name}
                              </p>
                            </div>
                            <div className="flex space-x-2 sm:items-center sm:space-y-1 sm:flex-col">
                              {['image', 'pdf'].includes(document.file_type) ? (
                                <button
                                  className="flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                                  onClick={() =>
                                    handleOpenFile(
                                      document.name,
                                      document.file_url,
                                      document.file_type
                                    )
                                  }
                                >
                                  Ver
                                  <ArrowsExpandIcon className="w-4 h-4 ml-1" />
                                </button>
                              ) : (
                                <a
                                  download
                                  title="Descargar"
                                  className="text-center cursor-pointer shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                                  href={document.file_url}
                                >
                                  Descargar
                                </a>
                              )}
                              <button
                                className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                                onClick={() => setCurrentDocument(document)}
                              >
                                Comentarios
                              </button>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
