import axios from 'axios';
import React, { useContext, createContext, useState } from 'react';
import { constants } from '../utils/constants';
import StorageService from '../utils/storage';

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export function useAuth() {
  return useContext(authContext);
}

function useProvideAuth() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    StorageService.get('userData') != null
  );

  const signin = async (accessKind, accessValue, password) => {
    try {
      const result = await axios.post(`${constants.API_URL}/users/login`, {
        access_kind: accessKind,
        access_value: accessValue,
        password,
      });
      StorageService.set('userData', result.data);
      StorageService.set('isAuthenticated', true);
      StorageService.set('accessToken', result.data.accessToken);
      StorageService.set('chosenProject', result.data?.chosenProject);
      setIsAuthenticated(true);
      return {
        success: true,
        message: 'Inicio de sesión completado, bienvenido',
        data: result.data,
      };
    } catch (error) {
      console.log(error);
      StorageService.set('userData', null);
      setIsAuthenticated(false);
      return {
        success: false,
        message:
          error.response != null
            ? error.response.data.message
            : 'Ocurrió un error',
      };
    }
  };

  const signout = () => {
    StorageService.set('userData', null);
    StorageService.set('isAuthenticated', false);
    StorageService.set('accessToken', null);
    StorageService.set('refreshToken', null);
    StorageService.set('chosenProject', null);
    setIsAuthenticated(false);
  };

  return {
    isAuthenticated,
    signin,
    signout,
  };
}
