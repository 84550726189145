import { Transition } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { SearchIcon, TagIcon } from '@heroicons/react/outline';

import { useAuth } from '../../contexts/Auth';
import GeneralApi from '../../utils/generalApi';
import PaginationApi from '../../utils/paginationApi';

import SubcategoryCreateForm from '../../components/Subcategory/SubcategoryCreateForm';
import SubcategoryEditForm from '../../components/Subcategory/SubcategoryEditForm';
import SubcategoryCard from '../../components/Subcategory/SubcategoryCard';

import { showErrorToast, showSuccessToast } from '../../components/Toast';
import ConfirmModalContent from '../../components/ConfirmModalContent';
import LoadingSpinner from '../../components/LoadingSpinner';
import Pagination from '../../components/Pagination';
import EmptyState from '../../components/EmptyState';
import TabItem from '../../components/TabItem';
import Modal from '../../components/Modal';

export default function SubcategoryList() {
  let { categoryId } = useParams();
  let history = useHistory();
  let auth = useAuth();

  // Subcategory List
  const [loading, setLoading] = useState(true);
  const [subcategories, setSubcategories] = useState([]);

  // Filters
  const [categories, setCategories] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState(categoryId || '');
  const [searchFilter, setSearchFilter] = useState('');

  // Pagination
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [total, setTotal] = useState(0);

  const amountPerPage = 9;

  const generalApi = new GeneralApi(auth, history);
  const subcategoriesApi = new PaginationApi(
    '/subcategories/list',
    amountPerPage
  );

  // Slide-overs
  // Create Subcategory
  const [isSubcategoryCreateOpen, setIsSubcategoryCreateOpen] = useState(false);

  // Edit Subcategory
  const [isSubcategoryEditOpen, setIsSubcategoryEditOpen] = useState(false);
  const [selectedSubcategoryId, setSelectedSubcategoryId] = useState('');
  const [detailsSubcategory, setDetailsSubcategory] = useState({});

  // Confirm Modals
  // Disable Subcategory
  const [isConfirmDisableOpen, setIsConfirmDisableOpen] = useState(false);
  const [loadingToggle, setLoadingToggle] = useState(false);

  // Delete Subcategory
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  function setEditSubcategoryData(subcategory) {
    setSelectedSubcategoryId(subcategory._id);
    setDetailsSubcategory(subcategory);
  }

  async function createSubcategory(data) {
    const result = await generalApi.post(`/subcategories`, {
      ...data,
      category: categoryFilter,
    });
    if (!result.success) {
      showErrorToast(result.message);
      return result;
    }
    showSuccessToast('Subcategoría creada');
    setIsSubcategoryCreateOpen(false);
    getSubcategories();
    return result;
  }

  async function updateSubcategory(data) {
    const result = await generalApi.put(`/subcategories`, {
      ...data,
      subcategory: selectedSubcategoryId,
    });
    if (!result.success) {
      showErrorToast(result.message);
      return result;
    }
    showSuccessToast('Subcategoría modificada');
    setIsSubcategoryEditOpen(false);
    setSelectedSubcategoryId('');
    getSubcategories();
    return result;
  }

  async function toggleSubcategory(subcategory_id, is_active) {
    setLoadingToggle(true);
    const result = await generalApi.post(`/subcategories/status`, {
      subcategory: subcategory_id,
      is_active: is_active,
    });
    if (!result.success) {
      showErrorToast(result.message);
      setLoadingToggle(false);
      return result;
    }
    showSuccessToast(
      is_active ? 'Subcategoría habilitada' : 'Subcategoría deshabilitada'
    );
    setIsConfirmDisableOpen(false);
    setLoadingToggle(false);
    setSelectedSubcategoryId('');
    getSubcategories();
    return result;
  }

  async function deleteSubcategory(subcategory_id) {
    setLoadingDelete(true);
    const result = await generalApi.post(`/subcategories/delete`, {
      subcategory: subcategory_id,
    });
    if (!result.success) {
      showErrorToast(result.message);
      setLoadingDelete(false);
      return result;
    }
    showSuccessToast('Subcategoría eliminada');
    setIsConfirmDeleteOpen(false);
    setLoadingDelete(false);
    setSelectedSubcategoryId('');
    getSubcategories();
    return result;
  }

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getSubcategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryFilter, searchFilter, page]);

  async function getCategories() {
    setLoading(true);
    const result = await generalApi.post(`/categories/list/active`);
    if (!result.success) {
      showErrorToast(result.message);
      setLoading(false);
      return result;
    }
    setCategories(result.data.categories);
    if (result.data.categories.length > 0 && categoryId === undefined)
      setCategoryFilter(result.data.categories[0]._id);
    return result;
  }

  async function getSubcategories() {
    if (categoryFilter === '') return setLoading(false);
    setLoading(true);
    let result = await subcategoriesApi.getContent(page, {
      search: searchFilter,
      category: categoryFilter,
    });
    if (!result.success) {
      setLoading(false);
      return showErrorToast(result.message);
    }
    setSubcategories(result.data.subcategories);
    setMaxPage(result.data.pagination.maxPage);
    setTotal(result.data.pagination.total);
    setLoading(false);
  }

  return (
    <div>
      <Modal
        isOpen={isConfirmDisableOpen}
        onClose={() => {
          setIsConfirmDisableOpen(false);
        }}
      >
        <ConfirmModalContent
          onClose={() => {
            setIsConfirmDisableOpen(false);
          }}
          onConfirm={() => {
            toggleSubcategory(selectedSubcategoryId, false);
          }}
          title="Deshabilitar subcategoría"
          content="¿Está seguro que desea deshabilitar esta subcategoría? La subcategoría ya no podrá ser usada."
          buttonLoading={loadingToggle}
        />
      </Modal>

      <Modal
        isOpen={isConfirmDeleteOpen}
        onClose={() => {
          setIsConfirmDeleteOpen(false);
        }}
      >
        <ConfirmModalContent
          onClose={() => {
            setIsConfirmDeleteOpen(false);
          }}
          onConfirm={() => {
            deleteSubcategory(selectedSubcategoryId);
          }}
          title="Eliminar subcategoría"
          content="¿Está seguro que desea eliminar esta subcategoría?"
          buttonLoading={loadingDelete}
        />
      </Modal>

      {/* Header */}
      <div className="px-4 py-4 bg-white shadow sm:pb-0 sm:px-6 lg:px-8">
        <div className="md:flex md:items-center md:justify-between">
          <h1 className="text-2xl font-bold leading-6 text-gray-900 py-1.5">
            Subcategorías
          </h1>
        </div>
        {/* Category filter */}
        <div className="mt-4">
          {/* Dropdown menu on small screens */}
          <div className="sm:hidden">
            <label htmlFor="selected-tab" className="sr-only">
              Escoger subcategoría
            </label>
            <select
              id="selected-tab"
              name="selected-tab"
              className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
              value={categoryFilter}
              onChange={(event) => {
                setCategoryFilter(event.target.value);
              }}
            >
              {categories.map((obj, index) => (
                <option key={index} value={obj._id}>
                  {obj.name}
                </option>
              ))}
            </select>
          </div>
          {/* Tabs at small breakpoint and up */}
          <div className="hidden sm:block">
            <nav className="flex -mb-px space-x-8">
              {categories.map((obj, index) => (
                <TabItem
                  key={index}
                  content={obj.name}
                  active={categoryFilter === obj._id}
                  onClick={() => {
                    setCategoryFilter(obj._id);
                  }}
                ></TabItem>
              ))}
            </nav>
          </div>
        </div>
      </div>

      {/* Subcategories grid */}
      <div className="relative z-0 flex flex-col-reverse flex-1 overflow-hidden sm:flex-row">
        <main className="relative z-0 flex-1 px-0 overflow-y-auto focus:outline-none sm:px-6 lg:px-8">
          <div className="flex flex-col mb-4 sm:my-4">
            <div className="min-w-full align-middle">
              <div className="flex items-center justify-between px-4 py-3 mb-3 bg-white border-t border-gray-200 shadow sm:rounded-lg sm:px-6">
                <div className="w-full">
                  <label htmlFor="inp_search" className="sr-only">
                    Busca por nombre de la subcategoría
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      {/* Heroicon name: search */}
                      <SearchIcon className="w-5 h-5 text-gray-400" />
                    </div>
                    <input
                      id="inp_search"
                      name="search"
                      className="block w-full py-2 pl-10 pr-3 leading-5 placeholder-gray-500 bg-white border border-gray-300 rounded-md focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                      placeholder="Busca por nombre de la subcategoría"
                      type="search"
                      value={searchFilter}
                      onChange={(e) => {
                        setSearchFilter(e.target.value);
                      }}
                    ></input>
                  </div>
                </div>
              </div>
              <Transition
                show={loading}
                className="flex items-center p-4 bg-white shadow sm:rounded-lg"
              >
                <LoadingSpinner color="primary"></LoadingSpinner>
              </Transition>
              <Transition
                show={!loading}
                as="ul"
                className="grid grid-cols-1 gap-x-6 gap-y-3 md:grid-cols-2"
              >
                {subcategories.map((obj) => (
                  <SubcategoryCard
                    key={obj._id}
                    subcategory={obj}
                    searchWords={[searchFilter]}
                    onEditClick={(subcategory) => {
                      setEditSubcategoryData(subcategory);
                      setIsSubcategoryEditOpen(true);
                    }}
                    onToggleClick={(subcategory) => {
                      setSelectedSubcategoryId(subcategory._id);
                      if (subcategory.is_active)
                        return setIsConfirmDisableOpen(true);
                      toggleSubcategory(subcategory._id, true);
                    }}
                    onDeleteClick={(subcategory) => {
                      setSelectedSubcategoryId(subcategory._id);
                      setIsConfirmDeleteOpen(true);
                    }}
                  ></SubcategoryCard>
                ))}
              </Transition>
              <Transition
                show={!loading && subcategories.length === 0}
                className="px-4 py-8 bg-white shadow sm:rounded-lg"
              >
                <EmptyState
                  title={
                    categories.length === 0
                      ? 'No se encontraron categorías'
                      : 'No se encontraron resultados'
                  }
                  subtitle={
                    categories.length === 0
                      ? 'Empieza creando una nueva categoría'
                      : searchFilter === ''
                      ? 'Empieza creando una nueva subcategoría en el panel derecho'
                      : 'No encuentras lo que buscas? Verifica que no sea un error o crea una nueva subcategoría en el panel derecho'
                  }
                  buttonText={categories.length === 0 && 'Crear categoría'}
                  buttonAction={() => {
                    history.replace('/categories');
                  }}
                  icon={<TagIcon className="w-12 h-12 mx-auto text-gray-400" />}
                />
              </Transition>
              {/* Pagination */}
              <Pagination
                page={page}
                amountPerPage={amountPerPage}
                total={total}
                maxPage={maxPage}
                onPreviousPage={() => {
                  setPage(page - 1);
                }}
                onNextPage={() => {
                  setPage(page + 1);
                }}
              />
            </div>
          </div>
        </main>
        <aside className="relative flex flex-col w-full px-0 mx-auto xl:flex-shrink-0 max-w-96 sm:max-w-none sm:w-64 xl:w-96 sm:pr-6 lg:pr-8">
          <div className="flex flex-col mt-4 sm:my-4">
            <div className="min-w-full align-middle">
              <div className="flex flex-col items-center justify-between mb-3 bg-white border-t border-gray-200 shadow sm:rounded-lg">
                <div className="w-full p-4 bg-white border-b border-gray-200 sm:rounded-t-lg sm:px-6">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    {isSubcategoryEditOpen
                      ? 'Editar Subcategoría'
                      : 'Crear Subcategoría'}
                  </h3>
                  <p className="hidden mt-1 text-sm text-gray-500 sm:block">
                    {isSubcategoryEditOpen
                      ? 'Llene los datos del formulario para editar la subcategoría.'
                      : 'Llene los datos del formulario para crear una nueva subcategoría.'}
                  </p>
                </div>
                <div className="w-full bg-white border-b border-gray-200 sm:rounded-lg">
                  {isSubcategoryEditOpen ? (
                    <SubcategoryEditForm
                      subcategory={detailsSubcategory}
                      toggleFocus={selectedSubcategoryId}
                      onSubmit={updateSubcategory}
                      onCancelClick={() => {
                        setIsSubcategoryEditOpen(false);
                      }}
                    ></SubcategoryEditForm>
                  ) : (
                    <SubcategoryCreateForm
                      toggleFocus={isSubcategoryCreateOpen}
                      onSubmit={createSubcategory}
                    ></SubcategoryCreateForm>
                  )}
                </div>
              </div>
            </div>
          </div>
        </aside>
      </div>
    </div>
  );
}
