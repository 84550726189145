import { Transition } from '@headlessui/react';
import { useEffect, useRef, useState } from 'react';
import { BlockPicker } from 'react-color';
import { useForm } from 'react-hook-form';
import { constants } from '../../utils/constants';
import InputErrorMessage from '../InputErrorMessage';
import LoadingSpinner from '../LoadingSpinner';
import OutsideAlerter from '../OutsideAlerter';

export default function CreateForm({
  color,
  toggleFocus,
  onColorChange,
  onSubmit,
}) {
  const {
    register,
    handleSubmit,
    errors,
    reset,
    setError,
    clearErrors,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useForm();
  const initialFocusRef = useRef();

  const [isColorOpen, setIsColorOpen] = useState(false);

  async function submit(data) {
    let response = await onSubmit({ ...data, color: color.hex });
    if (!response.success) {
      setError('server', response.message);
      setTimeout(() => {
        clearErrors('server');
      }, 200);
    } else reset();
  }

  useEffect(() => {
    if (isSubmitSuccessful) reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  useEffect(() => {
    if (toggleFocus) {
      setTimeout(() => {
        initialFocusRef.current.focus();
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleFocus]);

  return (
    <form
      onSubmit={handleSubmit(submit)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') return e.preventDefault();
      }}
      className="flex flex-col h-full bg-white divide-y divide-gray-200 sm:rounded-lg"
    >
      <div className="flex-1 h-0 overflow-y-auto">
        <div className="flex flex-col justify-between flex-1">
          <div className="px-4 divide-y divide-gray-200 sm:px-6">
            <div className="py-5 space-y-3">
              <div>
                <label
                  htmlFor="inp_name"
                  className="block text-sm font-medium text-gray-900"
                >
                  Nombre
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    id="inp_name"
                    name="name"
                    ref={(e) => {
                      if (e != null) {
                        register(e, {
                          required: constants.ERROR_MESSAGES.REQUIRED,
                        });
                        initialFocusRef.current = e;
                      }
                    }}
                    className={`${
                      errors.name ? 'inp-border-red' : 'inp-border-primary'
                    } block w-full shadow-sm sm:text-sm rounded-md`}
                    placeholder="Nombre"
                  ></input>
                </div>
                <InputErrorMessage error={errors.name}></InputErrorMessage>
              </div>
              <div>
                <label
                  htmlFor="inp_color"
                  className="block text-sm font-medium text-gray-900"
                >
                  Color
                </label>
                <div className="relative mt-1">
                  <input
                    type="text"
                    id="inp_color"
                    name="color"
                    defaultValue={color.hex}
                    readOnly={true}
                    className={`${
                      errors.color ? 'inp-border-red' : 'inp-border-primary'
                    } block w-full shadow-sm sm:text-sm rounded-md`}
                    placeholder="Color"
                    onClick={() => {
                      setIsColorOpen(!isColorOpen);
                    }}
                  ></input>
                  <div
                    className="absolute inset-y-0 w-4 h-4 rounded-full pointer-events-none right-4 top-3"
                    style={{ backgroundColor: color.hex }}
                  ></div>
                </div>
                <Transition show={isColorOpen}>
                  <OutsideAlerter
                    onOutsideClick={() => {
                      setIsColorOpen(false);
                    }}
                  >
                    <BlockPicker
                      className="top-0 float-left mt-1 mb-5 border inp-border-primary"
                      triangle="hide"
                      width="100%"
                      color={color}
                      onChangeComplete={onColorChange}
                      colors={[
                        '#6B7280',
                        '#EF4444',
                        '#F97316',
                        '#F59E0B',
                        '#10B981',
                        '#14B8A6',
                        '#0EA5E9',
                        '#3B82F6',
                        '#6366F1',
                        '#8B5CF6',
                        '#EC4899',
                        '#F43F5E',
                      ]}
                    ></BlockPicker>
                  </OutsideAlerter>
                </Transition>
                <InputErrorMessage error={errors.color}></InputErrorMessage>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end flex-shrink-0 px-4 py-4">
        <button
          type="submit"
          className="inline-flex justify-center px-4 py-2 ml-4 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          {isSubmitting ? (
            <LoadingSpinner></LoadingSpinner>
          ) : (
            <span>Crear carpeta</span>
          )}
        </button>
      </div>
    </form>
  );
}
