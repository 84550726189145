import highlightText from '@brianmcallister/highlight-text';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/outline';

export default function ProjectCard({
  project,
  searchWords,
  onEditClick,
  onToggleClick,
  onDeleteClick,
}) {
  return (
    <li className="relative flex col-span-1 shadow-sm sm:rounded-md">
      <div className="flex items-center justify-between flex-1 truncate bg-white border border-gray-200 sm:rounded-md">
        <div className="flex-1 px-4 py-4 text-sm truncate">
          <span
            className={`${
              project.is_active ? 'text-gray-900' : 'text-red-600'
            } font-medium`}
            dangerouslySetInnerHTML={{
              __html: highlightText(project.name, searchWords),
            }}
          ></span>
        </div>
        <div className="flex-shrink-0 pr-2">
          <Menu>
            {({ open }) => (
              <>
                <Menu.Button className="inline-flex items-center justify-center w-8 h-8 text-gray-400 bg-transparent bg-white sm:rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                  <span className="sr-only">Abrir menú</span>
                  <DotsVerticalIcon className="w-5 h-5" />
                </Menu.Button>

                <Transition
                  show={open}
                  enter="transition ease-out duration-100"
                  enterFrom="scale-95"
                  enterTo="scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="scale-100"
                  leaveTo="scale-95"
                >
                  <Menu.Items
                    static
                    className="absolute z-10 w-48 mx-3 mt-1 origin-top-right bg-white divide-y divide-gray-200 rounded-md shadow-lg right-10 top-3 ring-1 ring-black ring-opacity-5"
                  >
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700'
                          } block px-4 py-2 text-sm cursor-pointer w-full text-left`}
                          onClick={() => {
                            onEditClick(project);
                          }}
                        >
                          Editar
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700'
                          } block px-4 py-2 text-sm cursor-pointer w-full text-left`}
                          onClick={() => {
                            onToggleClick(project);
                          }}
                        >
                          {project.is_active ? 'Deshabilitar' : 'Habilitar'}
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700'
                          } block px-4 py-2 text-sm cursor-pointer w-full text-left`}
                          onClick={() => {
                            onDeleteClick(project);
                          }}
                        >
                          Eliminar
                        </button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>
      </div>
    </li>
  );
}
