import { addMinutes, format } from 'date-fns';
import { es } from 'date-fns/esm/locale';

export function formatDate(dateString, formatString = `do 'de' MMMM yyyy`) {
  if (!dateString) return '-';
  const date = new Date(dateString || null);
  return format(addMinutes(date, date.getTimezoneOffset()), formatString, {
    locale: es,
  });
}
