export default function TabItem({ content, active, onClick }) {
  return (
    <button
      onClick={onClick}
      className={`${
        active
          ? 'border-primary-400 text-primary-600'
          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
      } whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm transition-colors focus:border-primary-700 focus:text-primary-800 focus:outline-none`}
    >
      {content}
    </button>
  );
}
