import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../contexts/Auth';

import { showErrorToast, showSuccessToast } from '../../components/Toast';
import LoadingSpinner from '../../components/LoadingSpinner';

import logo from '../../resources/img/logo_icon.svg';

export default function Login() {
  let history = useHistory();
  let auth = useAuth();

  const [loading, setLoading] = useState(false);
  const [accessKind, setAccessKind] = useState('dni');
  const [accessValue, setAccessValue] = useState('');
  const [password, setPassword] = useState('');

  let login = async () => {
    setLoading(true);
    let result = await auth.signin(accessKind, accessValue, password);
    if (!result.success) {
      setLoading(false);
      return showErrorToast(result.message);
    }
    showSuccessToast(result.message);
    history.replace(
      result?.data?.projects?.length === 0
        ? 'projects'
        : result?.data?.redirect || '/dashboard'
    );
  };

  return (
    <div className="flex min-h-screen bg-white">
      <div className="flex flex-col justify-center flex-1 px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="w-full max-w-sm mx-auto lg:w-96">
          <div>
            <img className="w-auto h-12" src={logo} alt="Workflow"></img>
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
              Ingresa a tu cuenta
            </h2>
            {/*
            <p className="mt-2 text-sm text-gray-600 max-w">
              O
              <a
                href="#"
                className="ml-1 font-medium text-primary-600 hover:text-primary-500"
              >
                empieza tu periodo de prueba de 14 días
              </a>
            </p>
            */}
          </div>

          <div className="mt-8">
            <div className="mt-6">
              <div className="space-y-6">
                <div>
                  <label
                    htmlFor="inp_document_number"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Usuario
                  </label>
                  <div className="mt-1 -space-y-px rounded-md shadow-sm">
                    <div>
                      <label htmlFor="inp_access_kind" className="sr-only">
                        Tipo de documento
                      </label>
                      <select
                        id="inp_access_kind"
                        name="access_kind"
                        value={accessKind}
                        onChange={(e) => setAccessKind(e.target.value)}
                        className="relative block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-none shadow-sm appearance-none rounded-t-md focus:outline-none focus:z-10 focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                      >
                        <option value="dni">DNI</option>
                        <option value="passport">Pasaporte</option>
                        <option value="other">Otro</option>
                        <option value="email">Correo</option>
                      </select>
                    </div>
                    <div>
                      <label htmlFor="inp_access_value" className="sr-only">
                        Número de documento
                      </label>
                      <input
                        id="inp_access_value"
                        name="access_value"
                        type="text"
                        value={accessValue}
                        autoFocus
                        onChange={(e) => setAccessValue(e.target.value)}
                        onKeyUp={(e) => {
                          if (e.key === 'Enter') login();
                        }}
                        className="relative block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-none shadow-sm appearance-none rounded-b-md focus:outline-none focus:z-10 focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                      ></input>
                    </div>
                  </div>
                </div>

                <div className="space-y-1">
                  <label
                    htmlFor="inp_password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Contraseña
                  </label>
                  <div className="mt-1">
                    <input
                      id="inp_password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      onKeyUp={(e) => {
                        if (e.key === 'Enter') login();
                      }}
                      className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                    ></input>
                  </div>
                </div>

                {/*
                <div className="flex items-center justify-end">
                  <div className="text-sm">
                    <a
                      href="#"
                      className="font-medium text-primary-600 hover:text-primary-500"
                    >
                      ¿Olvidaste tu contraseña?
                    </a>
                  </div>
                </div>
                */}

                <div>
                  <button
                    onClick={login}
                    className="inline-flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                  >
                    {loading ? (
                      <LoadingSpinner></LoadingSpinner>
                    ) : (
                      <span>Ingresar</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative flex-1 hidden w-0 lg:block">
        <img
          className="absolute inset-0 object-cover w-full h-full"
          src="https://www.kabbage.com/resource-center/static/697f67813cf64f75e2adb824ff914ce3/26852/how-to-write-a-business-plan_207251500-1024x688.jpg"
          alt=""
        ></img>
      </div>
    </div>
  );
}
