import highlightText from '@brianmcallister/highlight-text';
import { Menu, Transition } from '@headlessui/react';

import {
  DotsVerticalIcon,
  FolderIcon,
  UserCircleIcon,
} from '@heroicons/react/outline';
import { constants } from '../../utils/constants';

export default function ItemCard({
  kind = 'folder',
  folder,
  searchWords,
  onEditClick,
  onDeleteClick,
  onDocumentsClick,
}) {
  return (
    <li className="relative flex w-full col-span-1 shadow sm:rounded-md group focus-within:outline-none focus-within:ring-1 focus-within:ring-primary-500 focus-within:border-primary-500">
      <div
        className="flex items-center justify-center flex-shrink-0 w-16 h-24 text-sm font-medium text-white sm:w-12 lg:w-16 sm:rounded-l-md"
        style={{ backgroundColor: folder.color }}
      >
        {kind === 'folder' ? (
          <FolderIcon className="w-8 h-8" />
        ) : (
          <UserCircleIcon className="w-8 h-8" />
        )}
      </div>
      <div className="flex items-center justify-between flex-1 truncate transition-all bg-white cursor-pointer group hover:bg-gray-50 sm:rounded-r-md">
        <button
          className="flex flex-col items-start justify-center flex-1 h-24 px-4 text-sm truncate focus:outline-none"
          onClick={
            folder.is_active
              ? () => {
                  onDocumentsClick(folder);
                }
              : null
          }
        >
          <span
            className={`${
              folder.is_active ? 'text-gray-900' : 'text-red-600'
            } font-medium text-base`}
            dangerouslySetInnerHTML={{
              __html: highlightText(folder.name, searchWords),
            }}
          ></span>
          {kind === 'customer' && (
            <p>
              <span>
                {constants.DOCUMENT_KIND_DISPLAY[folder.document_kind]}:{' '}
              </span>
              <span
                dangerouslySetInnerHTML={{
                  __html: highlightText(folder.document_number, searchWords),
                }}
              ></span>
            </p>
          )}
          <p className="text-gray-500">{`Documentos: ${
            folder.documents ? folder.documents.length : '0'
          }`}</p>
        </button>
        {kind === 'folder' && (
          <div className="flex-shrink-0 pr-2">
            <Menu>
              {({ open }) => (
                <>
                  <Menu.Button className="inline-flex items-center justify-center w-8 h-8 text-gray-400 bg-transparent bg-white sm:rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                    <span className="sr-only">Abrir menú</span>
                    <DotsVerticalIcon className="w-5 h-5" />
                  </Menu.Button>

                  <Transition show={open}>
                    <Menu.Items
                      static
                      className="absolute z-10 w-48 mx-3 mt-1 origin-top-right bg-white divide-y divide-gray-200 rounded-md shadow-lg right-10 top-3 ring-1 ring-black ring-opacity-5"
                    >
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700'
                            } block px-4 py-2 text-sm cursor-pointer w-full text-left`}
                            onClick={() => {
                              onEditClick(folder);
                            }}
                          >
                            Editar
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700'
                            } block px-4 py-2 text-sm cursor-pointer w-full text-left`}
                            onClick={() => {
                              onDeleteClick(folder);
                            }}
                          >
                            Eliminar
                          </button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>
        )}
      </div>
    </li>
  );
}
