import {
  ArchiveIcon,
  CashIcon,
  ClipboardListIcon,
  CogIcon,
  HomeIcon,
  InboxIcon,
  LockOpenIcon,
  TagIcon,
  UserGroupIcon,
  UsersIcon,
  DocumentTextIcon,
} from '@heroicons/react/outline';

import { constants } from '../../utils/constants';
import StorageService from '../../utils/storage';
import SidebarItem from './SidebarItem';
import SidebarItemParent from './SidebarItemParent';

const ICON_CLASSES =
  'w-6 h-6 mr-3 text-gray-500 transition-all duration-150 group-hover:text-gray-700';

const SIDEBAR_ITEMS = [
  {
    enabled: false,
    name: 'dashboard',
    path: '/dashboard',
    title: 'Inicio',
    icon: <HomeIcon className={ICON_CLASSES} />,
  },
  {
    enabled: false,
    name: 'operations',
    path: '/operations',
    title: 'Operaciones',
    icon: <CashIcon className={ICON_CLASSES} />,
  },
  {
    enabled: true,
    name: 'folders',
    path: '/documents',
    title: 'Documentos',
    icon: <DocumentTextIcon className={ICON_CLASSES} />,
  },
  {
    enabled: true,
    name: 'customers',
    path: '/customers',
    title: 'Clientes',
    icon: <UserGroupIcon className={ICON_CLASSES} />,
  },
  {
    enabled: true,
    name: 'inventory',
    title: 'Inventario',
    icon: <InboxIcon className={ICON_CLASSES} />,
    items: [
      {
        enabled: true,
        name: 'products',
        path: '/products',
        title: 'Productos',
        icon: <ArchiveIcon className={ICON_CLASSES} />,
      },
      {
        enabled: true,
        name: 'categories',
        path: '/categories',
        title: 'Categorías',
        icon: <TagIcon className={ICON_CLASSES} />,
      },
      {
        enabled: true,
        name: 'subcategories',
        path: '/subcategories',
        title: 'Subcategorías',
        icon: <TagIcon className={ICON_CLASSES} />,
      },
    ],
  },
  {
    enabled: true,
    name: 'settings',
    path: '/settings',
    title: 'Configuración',
    icon: <CogIcon className={ICON_CLASSES} />,
    items: [
      {
        enabled: true,
        name: 'projects',
        path: '/projects',
        title: 'Proyectos',
        icon: <ClipboardListIcon className={ICON_CLASSES} />,
      },
      {
        enabled: true,
        name: 'users',
        path: '/users',
        title: 'Empleados',
        icon: <UsersIcon className={ICON_CLASSES} />,
      },
      {
        enabled: true,
        name: 'settings',
        path: '/settings',
        title: 'Contraseña',
        icon: <LockOpenIcon className={ICON_CLASSES} />,
      },
    ],
  },
];

export default function SidebarItems({ className, onCloseClick }) {
  const permissions = StorageService.get('userData').permissions;
  const position = StorageService.get('userData').position;

  const sidebarItems = constants.SIDEBAR_ITEMS_PERMISSIONS.filter(
    (obj) =>
      position === 'super_admin' ||
      obj.permissions.length === 0 ||
      obj.permissions.some((obj2) =>
        permissions.some((obj3) => obj3.name === obj2)
      )
  );

  function hasAccessToParent(items) {
    return items.some((obj) =>
      sidebarItems.some((obj2) => obj2.name === obj.name)
    );
  }

  return (
    <nav className={className}>
      {SIDEBAR_ITEMS.map((obj) => {
        if (!obj.enabled) return null;
        if (obj.items !== undefined) {
          if (!hasAccessToParent(obj.items)) return null;
          return (
            <SidebarItemParent
              key={obj.name}
              sidebarItem={obj}
              paths={obj.items.map((item) => item.path)}
            >
              {obj.items.map((item) => {
                if (!sidebarItems.some((obj2) => obj2.name === item.name))
                  return null;
                return (
                  <SidebarItem
                    key={item.name}
                    path={item.path}
                    onClick={onCloseClick}
                    isChild={true}
                  >
                    {item.icon}
                    {item.title}
                  </SidebarItem>
                );
              })}
            </SidebarItemParent>
          );
        }
        if (!sidebarItems.some((obj2) => obj2.name === obj.name)) return null;
        return (
          <SidebarItem
            key={obj.name}
            path={obj.path}
            onClick={onCloseClick}
            isChild={false}
          >
            {obj.icon}
            {obj.title}
          </SidebarItem>
        );
      })}
    </nav>
  );
}
