import { Transition } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth';
import { ClipboardListIcon, SearchIcon } from '@heroicons/react/outline';

import GeneralApi from '../../utils/generalApi';
import PaginationApi from '../../utils/paginationApi';

import ProjectCreateForm from '../../components/Project/ProjectCreateForm';
import ProjectEditForm from '../../components/Project/ProjectEditForm';
import ProjectCard from '../../components/Project/ProjectCard';

import {
  showErrorToast,
  showInfoToast,
  showSuccessToast,
} from '../../components/Toast';
import ConfirmModalContent from '../../components/ConfirmModalContent';
import LoadingSpinner from '../../components/LoadingSpinner';
import EmptyState from '../../components/EmptyState';
import Pagination from '../../components/Pagination';
import Modal from '../../components/Modal';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function ProjectList() {
  let history = useHistory();
  let query = useQuery();
  let auth = useAuth();

  // Project List
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [showedAlert, setShowedAlert] = useState(false);

  // Filters
  const [searchFilter, setSearchFilter] = useState('');

  // Pagination
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [total, setTotal] = useState(0);

  const amountPerPage = 9;

  const generalApi = new GeneralApi(auth, history);
  const projectsApi = new PaginationApi(
    '/projects/list',
    amountPerPage,
    auth,
    history
  );

  // Slide-overs
  // Edit Project
  const [isProjectEditOpen, setIsProjectEditOpen] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const [detailsProject, setDetailsProject] = useState({});

  // Confirm Modals
  // Disable Project
  const [isConfirmDisableOpen, setIsConfirmDisableOpen] = useState(false);
  const [loadingToggle, setLoadingToggle] = useState(false);

  // Delete Project
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  function setEditProjectData(project) {
    setSelectedProjectId(project._id);
    setDetailsProject(project);
  }

  async function createProject(data) {
    const result = await generalApi.post(`/projects`, data);
    if (!result.success) {
      showErrorToast(result.message);
      return result;
    }
    showSuccessToast('Proyecto creado');
    window.location.reload();
    return result;
  }

  async function updateProject(data) {
    const result = await generalApi.put(`/projects`, {
      ...data,
      project: selectedProjectId,
    });
    if (!result.success) {
      showErrorToast(result.message);
      return result;
    }
    showSuccessToast('Proyecto modificado');
    setIsProjectEditOpen(false);
    setSelectedProjectId('');
    getProjects();
    return result;
  }

  async function toggleProject(project_id, is_active) {
    setLoadingToggle(true);
    const result = await generalApi.post(`/projects/status`, {
      project: project_id,
      is_active: is_active,
    });
    if (!result.success) {
      showErrorToast(result.message);
      setLoadingToggle(false);
      return result;
    }
    showSuccessToast(
      is_active ? 'Proyecto habilitado' : 'Proyecto deshabilitado'
    );
    setIsConfirmDisableOpen(false);
    setLoadingToggle(false);
    setSelectedProjectId('');
    getProjects();
    return result;
  }

  async function deleteProject(project_id) {
    setLoadingDelete(true);
    const result = await generalApi.post(`/projects/delete`, {
      project: project_id,
    });
    if (!result.success) {
      showErrorToast(result.message);
      setLoadingDelete(false);
      return result;
    }
    showSuccessToast('Proyecto eliminado');
    setIsConfirmDeleteOpen(false);
    setLoadingDelete(false);
    setSelectedProjectId('');
    getProjects();
    return result;
  }

  useEffect(() => {
    getProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilter, page]);

  useEffect(() => {
    if (query.get('needed') && !showedAlert) {
      showInfoToast('Necesita crear un proyecto para registrar sus clientes');
      setShowedAlert(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showedAlert]);

  async function getProjects() {
    setLoading(true);
    let result = await projectsApi.getContent(page, {
      search: searchFilter,
    });
    if (!result.success) {
      setLoading(false);
      return showErrorToast(result.message);
    }
    setProjects(result.data.projects);
    setMaxPage(result.data.pagination.maxPage);
    setTotal(result.data.pagination.total);
    setLoading(false);
  }

  return (
    <div>
      <Modal
        isOpen={isConfirmDisableOpen}
        onClose={() => {
          setIsConfirmDisableOpen(false);
        }}
      >
        <ConfirmModalContent
          onClose={() => {
            setIsConfirmDisableOpen(false);
          }}
          onConfirm={() => {
            toggleProject(selectedProjectId, false);
          }}
          title="Deshabilitar proyecto"
          content="¿Está seguro que desea deshabilitar este proyecto? El proyecto ya no podrá ser usado."
          buttonLoading={loadingToggle}
        />
      </Modal>

      <Modal
        isOpen={isConfirmDeleteOpen}
        onClose={() => {
          setIsConfirmDeleteOpen(false);
        }}
      >
        <ConfirmModalContent
          onClose={() => {
            setIsConfirmDeleteOpen(false);
          }}
          onConfirm={() => {
            deleteProject(selectedProjectId);
          }}
          title="Eliminar proyecto"
          content="¿Está seguro que desea eliminar este proyecto?"
          buttonLoading={loadingDelete}
        />
      </Modal>

      {/* Header */}
      <div className="px-4 py-4 bg-white shadow sm:px-6 lg:px-8">
        <div className="md:flex md:items-center md:justify-between">
          <h1 className="text-2xl font-bold leading-6 text-gray-900 py-1.5">
            Proyectos
          </h1>
        </div>
      </div>

      {/* Projects grid */}
      <div className="relative z-0 flex flex-col-reverse flex-1 overflow-hidden sm:flex-row">
        <main className="relative z-0 flex-1 px-0 overflow-y-auto focus:outline-none sm:px-6 lg:px-8">
          <div className="flex flex-col mb-4 sm:my-4">
            <div className="min-w-full align-middle">
              <div className="flex items-center justify-between px-4 py-3 mb-3 bg-white border-t border-gray-200 shadow sm:rounded-lg sm:px-6">
                <div className="w-full">
                  <label htmlFor="inp_search" className="sr-only">
                    Busca por nombre del proyecto
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      {/* Heroicon name: search */}
                      <SearchIcon className="w-5 h-5 text-gray-400" />
                    </div>
                    <input
                      id="inp_search"
                      name="search"
                      className="block w-full py-2 pl-10 pr-3 leading-5 placeholder-gray-500 bg-white border border-gray-300 rounded-md focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                      placeholder="Busca por nombre del proyecto"
                      type="search"
                      value={searchFilter}
                      onChange={(e) => {
                        setSearchFilter(e.target.value);
                      }}
                    ></input>
                  </div>
                </div>
              </div>
              <Transition
                show={loading}
                className="flex items-center p-4 bg-white shadow sm:rounded-lg"
              >
                <LoadingSpinner color="primary"></LoadingSpinner>
              </Transition>
              <Transition
                show={!loading}
                as="ul"
                className="grid grid-cols-1 gap-x-6 gap-y-3 md:grid-cols-2"
              >
                {projects.map((obj) => (
                  <ProjectCard
                    key={obj._id}
                    project={obj}
                    searchWords={[searchFilter]}
                    onEditClick={(project) => {
                      setEditProjectData(project);
                      setIsProjectEditOpen(true);
                    }}
                    onToggleClick={(project) => {
                      setSelectedProjectId(project._id);
                      if (project.is_active)
                        return setIsConfirmDisableOpen(true);
                      toggleProject(project._id, true);
                    }}
                    onDeleteClick={(project) => {
                      setSelectedProjectId(project._id);
                      setIsConfirmDeleteOpen(true);
                    }}
                  ></ProjectCard>
                ))}
              </Transition>
              <Transition
                show={!loading && projects.length === 0}
                className="px-4 py-8 bg-white shadow sm:rounded-lg"
              >
                <EmptyState
                  title="No se encontraron resultados"
                  subtitle={
                    searchFilter === ''
                      ? 'Empieza creando un nuevo proyecto en el panel derecho'
                      : 'No encuentras lo que buscas? Verifica que no sea un error o crea un nuevo proyecto en el panel derecho'
                  }
                  icon={
                    <ClipboardListIcon className="w-12 h-12 mx-auto text-gray-400" />
                  }
                />
              </Transition>
              {/* Pagination */}
              <Pagination
                page={page}
                amountPerPage={amountPerPage}
                total={total}
                maxPage={maxPage}
                onPreviousPage={() => {
                  setPage(page - 1);
                }}
                onNextPage={() => {
                  setPage(page + 1);
                }}
              />
            </div>
          </div>
        </main>
        <aside className="relative flex flex-col w-full px-0 mx-auto xl:flex-shrink-0 max-w-96 sm:max-w-none sm:w-64 xl:w-96 sm:pr-6 lg:pr-8">
          <div className="flex flex-col mt-4 sm:my-4">
            <div className="min-w-full align-middle">
              <div className="flex flex-col items-center justify-between mb-3 bg-white border-t border-gray-200 shadow sm:rounded-lg">
                <div className="w-full p-4 bg-white border-b border-gray-200 sm:rounded-t-lg sm:px-6">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    {isProjectEditOpen ? 'Editar Proyecto' : 'Crear Proyecto'}
                  </h3>
                  <p className="hidden mt-1 text-sm text-gray-500 sm:block">
                    {isProjectEditOpen
                      ? 'Llene los datos del formulario para editar el proyecto.'
                      : 'Llene los datos del formulario para crear un nuevo proyecto.'}
                  </p>
                </div>
                <div className="w-full bg-white border-b border-gray-200 sm:rounded-lg">
                  {isProjectEditOpen ? (
                    <ProjectEditForm
                      project={detailsProject}
                      toggleFocus={selectedProjectId}
                      onSubmit={updateProject}
                      onCancelClick={() => {
                        setIsProjectEditOpen(false);
                      }}
                    ></ProjectEditForm>
                  ) : (
                    <ProjectCreateForm
                      toggleFocus={true}
                      onSubmit={createProject}
                    ></ProjectCreateForm>
                  )}
                </div>
              </div>
            </div>
          </div>
        </aside>
      </div>
    </div>
  );
}
