import { constants } from './constants';

class PdfClient {
  constructor() {
    this.readyPromise = new Promise((resolve) => {
      if (window.AdobeDC) {
        resolve();
      } else {
        document.addEventListener('adobe_dc_view_sdk.ready', () => {
          resolve();
        });
      }
    });
    this.adobeDCView = undefined;
  }

  ready() {
    return this.readyPromise;
  }

  previewFile(fileName, pdfUrl, viewerConfig) {
    const config = { clientId: constants.ADOBE_KEY };
    this.adobeDCView = new window.AdobeDC.View(config);

    const previewFilePromise = this.adobeDCView.previewFile(
      {
        content: {
          location: { url: pdfUrl },
        },
        metaData: {
          fileName,
          id: '6d07d124-ac85-43b3-a867-36930f502ac6',
        },
      },
      viewerConfig
    );

    return previewFilePromise;
  }
}

export default PdfClient;
