import { Transition } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import highlightText from '@brianmcallister/highlight-text';
import { ChevronRightIcon, SearchIcon } from '@heroicons/react/outline';
import { CashIcon } from '@heroicons/react/solid';

import { useAuth } from '../../contexts/Auth';
import GeneralApi from '../../utils/generalApi';
import PaginationApi from '../../utils/paginationApi';
import { formatDate } from '../../utils/dateUtils';
import StorageService from '../../utils/storage';

import { showErrorToast, showSuccessToast } from '../../components/Toast';
import LoadingSpinner from '../../components/LoadingSpinner';
import SlideOver from '../../components/SlideOver';
import TabItem from '../../components/TabItem';
import ConfirmModalContent from '../../components/ConfirmModalContent';

import OperationCreateForm from '../../components/Operation/CreateForm';
import OperationDetails from '../../components/Operation/Details';
import OperationEditForm from '../../components/Operation/EditForm';
import PageHeader from '../../components/PageHeader';
import Pagination from '../../components/Pagination';
import Modal from '../../components/Modal';

export default function OperationList() {
  let history = useHistory();
  let auth = useAuth();

  const position = StorageService.get('userData').position;

  // Operation List
  const [loading, setLoading] = useState(true);
  const [operations, setOperations] = useState([]);

  // Filters
  const [kindFilter, setKindFilter] = useState('all');
  const [searchFilter, setSearchFilter] = useState('');

  // Pagination
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [total, setTotal] = useState(0);

  const amountPerPage = 9;

  const generalApi = new GeneralApi(auth, history);
  const operationsApi = new PaginationApi(
    '/operations/list',
    amountPerPage,
    auth,
    history
  );

  // Slide-overs
  // Create Operation
  const [isOperationCreateOpen, setIsOperationCreateOpen] = useState(false);

  // Edit Operation
  const [isOperationEditOpen, setIsOperationEditOpen] = useState(false);
  const [selectedOperationId, setSelectedOperationId] = useState('');

  // Operation Details
  const [isOperationDetailsOpen, setIsOperationDetailsOpen] = useState(false);
  const [detailsOperation, setDetailsOperation] = useState({});

  // Confirm Modals
  // Delete Operation
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  function setEditOperationData(operation) {
    setSelectedOperationId(operation._id);
    setDetailsOperation(operation);
  }

  function setDetailsOperationData(operation) {
    setSelectedOperationId(operation._id);
    setDetailsOperation(operation);
    setIsOperationDetailsOpen(true);
  }

  async function createOperation(data) {
    const result = await generalApi.post(`/operations`, data);
    if (!result.success) {
      showErrorToast(result.message);
      return result;
    }
    showSuccessToast('Operación creada');
    setIsOperationCreateOpen(false);
    getOperations();
    return result;
  }

  async function updateOperation(data) {
    const result = await generalApi.put(`/operations`, {
      ...data,
      operation: selectedOperationId,
    });
    if (!result.success) {
      showErrorToast(result.message);
      return result;
    }
    showSuccessToast('Operación modificada');
    setIsOperationEditOpen(false);
    setSelectedOperationId('');
    getOperations();
    return result;
  }

  async function deleteOperation(operation_id) {
    setLoadingDelete(true);
    const result = await generalApi.post(`/operations/delete`, {
      operation: operation_id,
    });
    if (!result.success) {
      showErrorToast(result.message);
      setLoadingDelete(false);
      return result;
    }
    showSuccessToast('Operación eliminada');
    setIsConfirmDeleteOpen(false);
    setIsOperationDetailsOpen(false);
    setLoadingDelete(false);
    setSelectedOperationId('');
    getOperations();
    return result;
  }

  useEffect(() => {
    getOperations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kindFilter, searchFilter, page]);

  async function getOperations() {
    setLoading(true);
    let result = await operationsApi.getContent(page, {
      search: searchFilter,
      kind: kindFilter,
      project: StorageService.get('chosenProject')?._id,
    });
    if (!result.success) {
      setLoading(false);
      return showErrorToast(result.message);
    }
    setOperations(result.data.operations);
    setMaxPage(result.data.pagination.maxPage);
    setTotal(result.data.pagination.total);
    setLoading(false);
  }

  return (
    <div>
      {/* Slide-over Create */}
      <SlideOver
        isOpen={isOperationCreateOpen}
        onClose={() => {
          setIsOperationCreateOpen(false);
        }}
      >
        <OperationCreateForm
          toggleFocus={isOperationCreateOpen}
          onSubmit={createOperation}
          onCancelClick={() => {
            setIsOperationCreateOpen(false);
          }}
        ></OperationCreateForm>
      </SlideOver>

      {/* Slide-over Edit */}
      <SlideOver
        isOpen={isOperationEditOpen}
        onClose={() => {
          setIsOperationEditOpen(false);
        }}
      >
        <OperationEditForm
          operation={detailsOperation}
          toggleFocus={isOperationEditOpen}
          onSubmit={updateOperation}
          onCancelClick={() => {
            setIsOperationEditOpen(false);
          }}
        />
      </SlideOver>

      {/* Slide-over Details */}
      <SlideOver
        isOpen={isOperationDetailsOpen}
        onClose={() => {
          setIsOperationDetailsOpen(false);
        }}
      >
        <OperationDetails
          operation={detailsOperation}
          toggleFocus={isOperationDetailsOpen}
          onCloseClick={() => {
            setIsOperationDetailsOpen(false);
            setSelectedOperationId('');
          }}
          onEditClick={(operation) => {
            setIsOperationDetailsOpen(false);
            setEditOperationData(operation);
            setIsOperationEditOpen(true);
          }}
          onDeleteClick={() => {
            setIsConfirmDeleteOpen(true);
          }}
        ></OperationDetails>
      </SlideOver>

      <Modal
        isOpen={isConfirmDeleteOpen}
        onClose={() => {
          setIsConfirmDeleteOpen(false);
        }}
      >
        <ConfirmModalContent
          onClose={() => {
            setIsConfirmDeleteOpen(false);
          }}
          onConfirm={() => {
            deleteOperation(selectedOperationId);
          }}
          title="Eliminar operación"
          content="¿Está seguro que desea eliminar esta operación?"
          buttonLoading={loadingDelete}
        />
      </Modal>

      {/* Header */}
      <div className="px-4 py-4 bg-white shadow sm:pb-0 sm:px-6 lg:px-8">
        <PageHeader
          title="Operaciones"
          buttonText="Crear operación"
          buttonAction={() => {
            setSelectedOperationId('');
            setIsOperationCreateOpen(true);
          }}
        />
        {/* Operation Position filter */}
        <div className="mt-4">
          {/* Dropdown menu on small screens */}
          <div className="sm:hidden">
            <label htmlFor="selected-tab" className="sr-only">
              Escoger tipo de operación
            </label>
            <select
              id="selected-tab"
              name="selected-tab"
              className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
              value={kindFilter}
              onChange={(event) => {
                setKindFilter(event.target.value);
              }}
            >
              <option value="all">Todos</option>
              <option value="Ingreso">Ingresos</option>
              <option value="Egreso">Egresos</option>
            </select>
          </div>
          {/* Tabs at small breakpoint and up */}
          <div className="hidden sm:block">
            <nav className="flex -mb-px space-x-8">
              <TabItem
                content="Todos"
                active={kindFilter === 'all'}
                onClick={() => {
                  setKindFilter('all');
                }}
              ></TabItem>
              <TabItem
                content="Ingresos"
                active={kindFilter === 'Ingreso'}
                onClick={() => {
                  setKindFilter('Ingreso');
                }}
              ></TabItem>
              <TabItem
                content="Egresos"
                active={kindFilter === 'Egreso'}
                onClick={() => {
                  setKindFilter('Egreso');
                }}
              ></TabItem>
            </nav>
          </div>
        </div>
      </div>

      {/* Operations grid */}
      <div className="px-0 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex flex-col mt-4">
          <div className="min-w-full align-middle">
            <div className="flex items-center justify-between px-4 py-3 mb-3 bg-white border-t border-gray-200 shadow sm:rounded-lg sm:px-6">
              <div className="w-full max-w-lg lg:max-w-xs">
                <label htmlFor="inp_search" className="sr-only">
                  Buscar
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    {/* Heroicon name: search */}
                    <SearchIcon className="w-5 h-5 text-gray-400" />
                  </div>
                  <input
                    id="inp_search"
                    name="search"
                    className="block w-full py-2 pl-10 pr-3 leading-5 placeholder-gray-500 bg-white border border-gray-300 rounded-md focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                    placeholder="Buscar"
                    type="search"
                    value={searchFilter}
                    onChange={(e) => {
                      setSearchFilter(e.target.value);
                    }}
                  ></input>
                </div>
              </div>
            </div>
            <Transition
              show={loading}
              className="flex items-center p-4 bg-white shadow sm:rounded-lg"
            >
              <LoadingSpinner color="primary"></LoadingSpinner>
            </Transition>
            <Transition show={!loading} className="shadow sm:hidden">
              <ul className="mt-2 overflow-hidden divide-y divide-gray-200 shadow sm:hidden">
                {operations.map((obj) => (
                  <li key={obj._id}>
                    <div
                      onClick={() => {
                        setDetailsOperationData(obj);
                        setIsOperationDetailsOpen(true);
                      }}
                      className="block px-4 py-4 bg-white hover:bg-gray-50"
                    >
                      <span className="flex items-center space-x-4">
                        <span className="flex flex-1 space-x-2 truncate">
                          {/* Heroicon name: cash */}
                          <CashIcon className="flex-shrink-0 w-5 h-5 text-gray-400" />
                          <span className="flex flex-col text-sm text-gray-500 truncate">
                            <span
                              className="truncate"
                              dangerouslySetInnerHTML={{
                                __html: highlightText(obj.motive || '', [
                                  searchFilter,
                                ]),
                              }}
                            ></span>
                            <span>
                              S/{' '}
                              <span className="font-medium text-gray-900">
                                {obj.amount.toFixed(2)}
                              </span>
                            </span>
                            <span>
                              {formatDate(obj.date)}
                              <span
                                className={`${
                                  obj.kind === 'Ingreso'
                                    ? 'bg-green-100 text-green-800'
                                    : 'bg-red-100 text-red-800'
                                } inline-flex items-center text-center px-2.5 py-0.5 ml-2 rounded-full text-xs font-medium capitalize`}
                              >
                                {obj.kind}
                              </span>
                            </span>

                            {position === 'super_admin' ||
                            position === 'admin' ? (
                              <span>
                                {obj.created_by.first_name}{' '}
                                {obj.created_by.last_name_p}
                              </span>
                            ) : (
                              <></>
                            )}
                          </span>
                        </span>
                        {/* Heroicon name: chevron-right */}
                        <ChevronRightIcon className="flex-shrink-0 w-5 h-5 text-gray-400" />
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </Transition>
            <Transition
              show={!loading}
              className="hidden min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg sm:block"
            >
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                      Motivo
                    </th>
                    <th className="px-6 py-3 text-xs font-medium tracking-wider text-right text-gray-500 uppercase bg-gray-50">
                      Monto
                    </th>
                    <th className="px-6 py-3 text-xs font-medium tracking-wider text-right text-gray-500 uppercase bg-gray-50">
                      Comprobante
                    </th>
                    <th className="px-6 py-3 text-xs font-medium tracking-wide text-left text-gray-500 uppercase bg-gray-50">
                      Tipo
                    </th>
                    {position === 'super_admin' || position === 'admin' ? (
                      <th className="px-6 py-3 text-xs font-medium tracking-wider text-right text-gray-500 uppercase bg-gray-50">
                        Empleado
                      </th>
                    ) : (
                      <></>
                    )}
                    <th className="px-6 py-3 text-xs font-medium tracking-wider text-right text-gray-500 uppercase bg-gray-50">
                      Fecha
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {operations.map((obj) => (
                    <tr key={obj._id} className="bg-white">
                      <td className="w-full px-4 py-2 text-sm text-gray-900 max-w-0 whitespace-nowrap">
                        <div className="flex">
                          <button
                            onClick={() => {
                              setDetailsOperationData(obj);
                              setIsOperationDetailsOpen(true);
                            }}
                            className="inline-flex w-full p-2 space-x-2 text-sm truncate rounded-md cursor-pointer group focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary-500"
                          >
                            {/* Heroicon name: cash */}
                            <CashIcon className="flex-shrink-0 hidden w-5 h-5 text-gray-400 group-hover:text-gray-500 md:block" />
                            <p
                              className="text-gray-500 underline truncate group-hover:text-gray-900"
                              dangerouslySetInnerHTML={{
                                __html: highlightText(obj.motive || '', [
                                  searchFilter,
                                ]),
                              }}
                            ></p>
                          </button>
                        </div>
                      </td>
                      <td className="px-6 py-4 text-sm text-right text-gray-500 whitespace-nowrap">
                        S/{' '}
                        <span className="font-medium text-gray-900">
                          {obj.amount.toFixed(2)}
                        </span>
                      </td>
                      <td className="px-6 py-4 text-sm text-right text-gray-500 whitespace-nowrap">
                        {obj.voucher_kind}
                        {obj.voucher_kind === 'Sin comprobante' ? (
                          <></>
                        ) : (
                          <span>: {obj.voucher_number}</span>
                        )}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                        <span
                          className={`${
                            obj.kind === 'Ingreso'
                              ? 'bg-green-100 text-green-800'
                              : 'bg-red-100 text-red-800'
                          } inline-flex items-center text-center px-2.5 py-0.5 ml-2 rounded-full text-xs font-medium capitalize`}
                        >
                          {obj.kind}
                        </span>
                      </td>
                      {position === 'super_admin' || position === 'admin' ? (
                        <td className="px-6 py-4 text-sm text-right text-gray-500 whitespace-nowrap">
                          {obj.created_by.first_name}{' '}
                          {obj.created_by.last_name_p}
                        </td>
                      ) : (
                        <></>
                      )}
                      <td className="px-6 py-4 text-sm text-right text-gray-500 whitespace-nowrap">
                        {formatDate(obj.date)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Transition>
            <Transition
              show={!loading && operations.length === 0}
              className="flex items-center p-4 mt-3 bg-white shadow sm:rounded-lg"
            >
              <p>No se encontraron resultados</p>
            </Transition>
            {/* Pagination */}
            <Pagination
              page={page}
              amountPerPage={amountPerPage}
              total={total}
              maxPage={maxPage}
              onPreviousPage={() => {
                setPage(page - 1);
              }}
              onNextPage={() => {
                setPage(page + 1);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
