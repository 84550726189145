import InputErrorMessage from '../InputErrorMessage';
import InputFocusDot from '../InputFocusDot';

export default function BaseInput({
  // Input
  idPrefix = '',
  label,
  placeholder = label,
  type = 'text',
  options,
  cornerAction,
  // Form
  name,
  register,
  errors,
  rules = {},
  inputRef,
  // Dots
  showDot = true,
  setCurrentDot,
  previousDot,
  currentDot,
  dotIndex,
  // Classes
  parentClass = '',
  labelClass = '',
  inputParentClass = '',
  inputClass = '',
  ...props
}) {
  const inputId = `inp_${idPrefix}${idPrefix !== '' && '_'}${name}`;
  return (
    <div className={parentClass}>
      <label
        htmlFor={inputId}
        className={`${labelClass} ${
          cornerAction ? 'flex justify-between' : 'block'
        } block text-sm font-medium text-gray-900`}
      >
        {label}
        {cornerAction && (
          <button
            type="button"
            className="transition-all rounded-md outline-none text-primary-500 hover:underline focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            onClick={cornerAction?.onClick}
          >
            {cornerAction?.text}
          </button>
        )}
      </label>
      <div className={`${inputParentClass} relative mt-1`}>
        {type === 'select' ? (
          <select
            type={type}
            id={inputId}
            name={name}
            ref={inputRef ? inputRef : register(rules)}
            onFocus={() => setCurrentDot(dotIndex)}
            className={`${
              errors[name] ? 'inp-border-red' : 'inp-border-primary'
            } ${inputClass} block w-full shadow-sm sm:text-sm rounded-md peer`}
            {...props}
          >
            {options}
          </select>
        ) : type === 'textarea' ? (
          <textarea
            type={type}
            id={inputId}
            name={name}
            placeholder={placeholder}
            ref={inputRef ? inputRef : register(rules)}
            onFocus={() => setCurrentDot(dotIndex)}
            className={`${
              errors[name] ? 'inp-border-red' : 'inp-border-primary'
            } ${inputClass} block w-full shadow-sm sm:text-sm rounded-md peer`}
            {...props}
          ></textarea>
        ) : (
          <input
            type={type}
            id={inputId}
            name={name}
            placeholder={placeholder}
            ref={inputRef ? inputRef : register(rules)}
            onFocus={() => setCurrentDot(dotIndex)}
            className={`${
              errors[name] ? 'inp-border-red' : 'inp-border-primary'
            } ${inputClass} block w-full shadow-sm sm:text-sm rounded-md peer`}
            {...props}
          />
        )}
        {showDot && (
          <InputFocusDot
            previousDot={previousDot}
            nextDot={currentDot}
            dotIndex={dotIndex}
            hasError={errors[name]}
          />
        )}
      </div>
      <InputErrorMessage error={errors[name]}></InputErrorMessage>
    </div>
  );
}
