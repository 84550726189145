import { Menu, Transition } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  ExclamationIcon,
  LoginIcon,
  MenuAlt2Icon,
  SelectorIcon,
  XIcon,
} from '@heroicons/react/outline';

import { useAuth } from '../../contexts/Auth';

import logo from '../../resources/img/logo.svg';
import SidebarButton from './SidebarButton';
import SidebarItems from './SidebarItems';
import { showErrorToast } from '../Toast';

import StorageService from '../../utils/storage';
import GeneralApi from '../../utils/generalApi';
import { constants } from '../../utils/constants';
import LoadingSpinner from '../LoadingSpinner';

export default function Sidebar(props) {
  let history = useHistory();
  let auth = useAuth();

  const generalApi = new GeneralApi(auth, history);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDisconnected, setIsDisconnected] = useState(false);

  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [chosenProject, setChosenProject] = useState(
    StorageService.get('userData')?.chosen_project
  );

  useEffect(() => {
    handleConnectionChange();
    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);
  }, []);

  useEffect(() => {
    getProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      fetch(`${constants.API_URL}/utils/status`, {
        mode: 'no-cors',
      })
        .then(() => setIsDisconnected(false))
        .catch(() => setIsDisconnected(true));
      return;
    }

    return setIsDisconnected(true);
  };

  async function getProjects() {
    setLoading(true);
    const result = await generalApi.post(`/projects/list/user`);
    if (!result.success) {
      showErrorToast(result.message);
      setLoading(false);
      return result;
    }
    setProjects(result.data.projects);
    setChosenProject(result.data.chosenProject);
    if (
      result.data.projects.length > 0 &&
      result.data.chosenProject?._id !==
        StorageService.get('chosenProject')?._id
    ) {
      StorageService.set('chosenProject', result.data.chosenProject);
      window.location.reload();
    } else setLoading(false);
  }

  async function submitChangeProject(newProject) {
    setLoading(true);
    const result = await generalApi.put(`/users/project`, {
      project: newProject,
    });
    if (!result.success) {
      showErrorToast(result.message);
      setLoading(false);
      return;
    }
    getProjects();
  }

  return (
    <div className="flex h-screen overflow-hidden bg-gray-100">
      {/* Off-canvas menu for mobile, show/hide based on off-canvas menu state. */}
      <Transition show={isSidebarOpen}>
        <div className="lg:hidden">
          <div className="fixed inset-0 z-40 flex">
            {/*
              Off-canvas menu overlay, show/hide based on off-canvas menu state.

              Entering: "transition-opacity ease-linear duration-300"
                From: "opacity-0"
                To: "opacity-100"
              Leaving: "transition-opacity ease-linear duration-300"
                From: "opacity-100"
                To: "opacity-0"
            */}
            <Transition.Child
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="fixed inset-0"
              aria-hidden="true"
              onClick={() => setIsSidebarOpen(false)}
            >
              <div className="absolute inset-0 bg-gray-600 opacity-75"></div>
            </Transition.Child>
            {/*
              Off-canvas menu, show/hide based on off-canvas menu state.

              Entering: "transition ease-in-out duration-300 transform"
                From: "-translate-x-full"
                To: "translate-x-0"
              Leaving: "transition ease-in-out duration-300 transform"
                From: "translate-x-0"
                To: "-translate-x-full"
            */}
            <Transition.Child
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
              className="relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-white"
            >
              <div className="absolute top-0 right-0 pt-2 -mr-12">
                <button
                  onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                  className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                >
                  <span className="sr-only">Close sidebar</span>
                  {/* Heroicon name: x */}
                  <XIcon className="w-6 h-6 text-white" />
                </button>
              </div>
              <div className="flex items-center flex-shrink-0 px-4">
                <img
                  className="mx-auto max-h-20"
                  src={StorageService.get('userData').companyLogo || logo}
                  alt="Company logo"
                ></img>
              </div>

              <Menu
                as="div"
                className="relative inline-block mx-2 mt-4 text-left"
              >
                {({ open }) => (
                  <>
                    <div>
                      <Menu.Button
                        className={`${
                          open && 'border-primary-500'
                        } group w-full rounded-md px-3.5 py-2 text-sm font-medium border-2 border-gray-200 text-gray-700 transition-all hover:border-primary-500 hover:bg-gray-100 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray focus:outline-none focus:ring-primary-500`}
                      >
                        <span className="flex items-center justify-between w-full">
                          <span className="flex items-center justify-between min-w-0 space-x-3">
                            <span className="flex-1 min-w-0">
                              {loading ? (
                                <LoadingSpinner />
                              ) : (
                                <p className="text-sm font-medium text-gray-900 truncate">
                                  {projects.length === 0
                                    ? 'No hay proyectos'
                                    : chosenProject?.name
                                    ? chosenProject?.name
                                    : 'Elegir proyecto'}
                                </p>
                              )}
                            </span>
                          </span>
                          {/*  Heroicon name: selector */}
                          <SelectorIcon
                            className={`${
                              open && 'text-gray-500'
                            } flex-shrink-0 w-5 h-5 text-gray-400 group-hover:text-gray-500`}
                          />
                        </span>
                      </Menu.Button>
                    </div>

                    <Transition
                      show={open}
                      enter="transition ease-out duration-100"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Menu.Items
                        static
                        className="absolute right-0 z-10 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      >
                        {projects.map((obj) => (
                          <Menu.Item key={obj._id}>
                            {({ active }) => (
                              <button
                                key={obj._id}
                                className={`${
                                  active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700'
                                } block px-4 py-2 text-sm w-full text-left`}
                                onClick={() => submitChangeProject(obj._id)}
                              >
                                {obj?.name}
                              </button>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>

              <div className="flex-1 h-0 mt-5 overflow-y-auto">
                <SidebarItems
                  onCloseClick={() => setIsSidebarOpen(!isSidebarOpen)}
                  className="px-2 space-y-3 mt/4"
                />
              </div>

              <div className="px-4 py-2 bg-white">
                <SidebarButton
                  onClick={() => {
                    auth.signout(() => history.push('/'));
                  }}
                >
                  <LoginIcon className="w-6 h-6 mr-3 text-gray-400 group-hover:text-gray-500" />
                  <span>Cerrar Sesión</span>
                </SidebarButton>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </div>
      </Transition>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:flex lg:flex-shrink-0">
        <div className="flex flex-col w-64">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col flex-grow pt-6 pb-4 overflow-y-auto bg-white border-r border-gray-200">
            <div className="flex flex-col flex-grow">
              <Menu
                as="div"
                className="relative inline-block mx-2 mb-2 text-left "
              >
                {({ open }) => (
                  <>
                    <div>
                      <Menu.Button
                        className={`${
                          open && 'border-primary-500'
                        } group w-full rounded-md px-3.5 py-2 text-sm font-medium border-2 border-gray-200 text-gray-700 transition-all hover:border-primary-500 hover:bg-gray-100 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray focus:outline-none focus:ring-primary-500`}
                      >
                        <span className="flex items-center justify-between w-full">
                          <span className="flex items-center justify-between min-w-0 space-x-3">
                            <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-primary-500">
                              <span className="font-medium leading-none text-white">
                                {StorageService.get('userData')
                                  .name.substr(0, 2)
                                  .toUpperCase()}
                              </span>
                            </span>
                            <span className="flex-1 min-w-0 truncate">
                              <p className="text-sm font-medium text-gray-900 truncate">
                                {StorageService.get('userData').name}
                              </p>
                            </span>
                          </span>
                          {/*  Heroicon name: selector */}
                          <SelectorIcon className="flex-shrink-0 w-5 h-5 text-gray-400 group-hover:text-gray-500" />
                        </span>
                      </Menu.Button>
                    </div>
                    <Transition
                      show={open}
                      enter="transition ease-out duration-100"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Menu.Items
                        static
                        className="absolute right-0 z-10 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      >
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              className={`${
                                active
                                  ? 'bg-gray-100 text-gray-900'
                                  : 'text-gray-700'
                              } block px-4 py-2 text-sm`}
                              to="/settings"
                            >
                              Configuración
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={`${
                                active
                                  ? 'bg-gray-100 text-gray-900'
                                  : 'text-gray-700'
                              } block px-4 py-2 text-sm cursor-pointer w-full text-left`}
                              onClick={() => {
                                auth.signout(() => history.push('/'));
                              }}
                            >
                              Cerrar sesión
                            </button>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
              <Menu
                as="div"
                className="relative inline-block mx-2 mt-2 text-left"
              >
                {({ open }) => (
                  <>
                    <div>
                      <Menu.Button
                        className={`${
                          open && 'border-primary-500'
                        } group w-full rounded-md px-3.5 py-2 text-sm font-medium border-2 border-gray-200 text-gray-700 transition-all hover:border-primary-500 hover:bg-gray-100 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray focus:outline-none focus:ring-primary-500`}
                      >
                        <span className="flex items-center justify-between w-full">
                          <span className="flex items-center justify-between min-w-0 space-x-3">
                            <span className="flex-1 min-w-0">
                              {loading ? (
                                <LoadingSpinner />
                              ) : (
                                <p className="text-sm font-medium text-gray-900 truncate">
                                  {projects.length === 0
                                    ? 'No hay proyectos'
                                    : chosenProject?.name
                                    ? chosenProject?.name
                                    : 'Elegir proyecto'}
                                </p>
                              )}
                            </span>
                          </span>
                          {/*  Heroicon name: selector */}
                          <SelectorIcon
                            className={`${
                              open && 'text-gray-500'
                            } flex-shrink-0 w-5 h-5 text-gray-400 group-hover:text-gray-500`}
                          />
                        </span>
                      </Menu.Button>
                    </div>

                    <Transition
                      show={open}
                      enter="transition ease-out duration-100"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Menu.Items
                        static
                        className="absolute right-0 z-10 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      >
                        <div className="py-1">
                          {projects.map((obj) => (
                            <Menu.Item key={obj._id}>
                              {({ active }) => (
                                <button
                                  key={obj._id}
                                  className={`${
                                    active
                                      ? 'bg-gray-100 text-gray-900'
                                      : 'text-gray-700'
                                  } block px-4 py-2 text-sm w-full text-left`}
                                  onClick={() => submitChangeProject(obj._id)}
                                >
                                  {obj?.name}
                                </button>
                              )}
                            </Menu.Item>
                          ))}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
              {isDisconnected && (
                <div className="p-4 mt-3 border-l-4 border-red-400 bg-red-50">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      {/* Heroicon name: exclamation */}
                      <ExclamationIcon className="w-5 h-5 text-red-400" />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm text-red-700">No hay conexión</p>
                    </div>
                  </div>
                </div>
              )}
              <SidebarItems className="flex-1 px-2 mt-4 space-y-2 bg-white" />
            </div>
          </div>
          <div className="flex flex-col bg-white border-r border-gray-200">
            <div className="flex items-center flex-shrink-0 px-4">
              <img
                className="mx-auto max-h-20"
                src={StorageService.get('userData').companyLogo || logo}
                alt="Company logo"
              ></img>
            </div>
            <div className="px-4 py-2 mt-2 bg-white">
              <SidebarButton
                onClick={() => {
                  auth.signout(() => history.push('/'));
                }}
              >
                <LoginIcon className="w-6 h-6 mr-3 text-gray-400 group-hover:text-gray-500" />
                <span>Cerrar Sesión</span>
              </SidebarButton>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col flex-1 w-0 overflow-hidden">
        <div className="relative z-10 flex flex-shrink-0 h-16 bg-white shadow lg:hidden">
          <button
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            className="px-4 text-gray-500 border-r border-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 lg:hidden"
          >
            <span className="sr-only">Open sidebar</span>
            {/* Heroicon name: menu-alt-2 */}
            <MenuAlt2Icon className="w-6 h-6" />
          </button>
          {isDisconnected && (
            <div className="px-2 py-4 bg-red-100">
              {/* Heroicon name: exclamation */}
              <ExclamationIcon className="w-8 h-8 text-red-400" />
            </div>
          )}
          <div className="flex justify-between flex-1 px-4">
            <div className="flex flex-1"></div>
            <div className="flex items-center lg:ml-6">
              {/* Profile dropdown */}
              <div className="relative inline-block text-left">
                <Menu>
                  {({ open }) => (
                    <>
                      <Menu.Button className="flex items-center max-w-xs text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                        <span className="sr-only">Open user menu</span>
                        <span className="flex items-center justify-between w-full">
                          <span className="flex items-center justify-between min-w-0 space-x-3">
                            <span className="inline-flex items-center justify-center w-8 h-8 rounded-full bg-primary-500">
                              <span className="text-sm font-medium leading-none text-white">
                                {StorageService.get('userData')
                                  .name.substr(0, 2)
                                  .toUpperCase()}
                              </span>
                            </span>
                            <span className="flex-1 min-w-0">
                              <p className="text-sm font-medium text-gray-900 truncate">
                                {StorageService.get('userData').name}
                              </p>
                            </span>
                          </span>
                          {/*  Heroicon name: selector */}
                          <SelectorIcon className="flex-shrink-0 w-5 h-5 text-gray-400 group-hover:text-gray-500" />
                        </span>
                      </Menu.Button>

                      <Transition
                        show={open}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          static
                          className="absolute right-0 w-56 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
                        >
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                className={`${
                                  active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700'
                                } block px-4 py-2 text-sm`}
                                to="/settings"
                              >
                                Configuración
                              </Link>
                            )}
                          </Menu.Item>
                          {/* 
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                className={`${
                                  active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700'
                                } block px-4 py-2 text-sm`}
                                to="/profile"
                              >
                                Mi perfil
                              </Link>
                            )}
                          </Menu.Item>*/}
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                className={`${
                                  active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700'
                                } block px-4 py-2 text-sm cursor-pointer w-full text-left`}
                                onClick={() => {
                                  auth.signout(() => history.push('/'));
                                }}
                              >
                                Cerrar sesión
                              </button>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>
            </div>
          </div>
        </div>

        <main
          className="relative flex-1 overflow-y-auto focus:outline-none"
          tabIndex="0"
        >
          {props.children}
        </main>
      </div>
    </div>
  );
}
