import highlightText from '@brianmcallister/highlight-text';
import { InformationCircleIcon, PencilAltIcon } from '@heroicons/react/outline';
import { IdentificationIcon, MailIcon } from '@heroicons/react/solid';

import { constants } from '../../utils/constants';

export default function UserCard({
  user,
  searchWords,
  onEditClick,
  onDetailsClick,
}) {
  return (
    <li className="col-span-1 bg-white divide-y divide-gray-200 shadow sm:rounded-lg">
      <div className="flex items-center justify-between w-full px-6 py-4 space-x-6">
        <div className="flex-1 truncate">
          <div className="flex items-center space-x-3">
            <h3
              className="text-sm font-medium text-gray-900 truncate"
              dangerouslySetInnerHTML={{
                __html: highlightText(
                  `${user.first_name} ${user.last_name_p} ${user.last_name_m}`,
                  searchWords
                ),
              }}
            ></h3>
          </div>
          <p className="flex items-center mt-2 text-sm text-gray-500">
            <IdentificationIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
            <span
              className="truncate"
              dangerouslySetInnerHTML={{
                __html: highlightText(user.document_number, searchWords),
              }}
            ></span>
          </p>
          <p className="flex items-center mt-2 text-sm text-gray-500">
            <MailIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
            <span
              className="truncate"
              dangerouslySetInnerHTML={{
                __html: highlightText(user.email, searchWords),
              }}
            ></span>
          </p>
        </div>
        <span
          className={`${
            user.is_active
              ? 'text-green-800 bg-green-100'
              : 'text-red-800 bg-red-100'
          } flex-shrink-0 inline-block px-2 py-0.5 text-xs font-medium rounded-full`}
        >
          {constants.USER_POSITIONS_DISPLAY[user.position]}
        </span>
      </div>
      <div>
        <div className="flex -mt-px divide-x divide-gray-200">
          <div className="flex flex-1 w-0">
            <button
              onClick={() => {
                onEditClick(user);
              }}
              className="inline-flex items-center justify-center flex-1 w-0 py-3 -mr-px text-sm font-medium text-gray-700 border border-transparent rounded-bl-lg hover:text-gray-500"
            >
              <PencilAltIcon className="w-5 h-5 text-gray-400" />
              <span className="ml-3">Editar</span>
            </button>
          </div>
          <div className="flex flex-1 w-0 -ml-px">
            <button
              onClick={() => {
                onDetailsClick(user);
              }}
              className="inline-flex items-center justify-center flex-1 w-0 py-3 text-sm font-medium text-gray-700 border border-transparent rounded-br-lg hover:text-gray-500"
            >
              <InformationCircleIcon className="w-5 h-5 text-gray-400" />
              <span className="ml-3">Detalles</span>
            </button>
          </div>
        </div>
      </div>
    </li>
  );
}
