const nodeEnv = process.env.NODE_ENV;
const environment = process.env.REACT_APP_ENVIRONMENT;

console.log(process.env);
export const constants = {
  API_URL:
    environment === 'dev'
      ? 'https://zegaru-dev-erp.herokuapp.com'
      : nodeEnv === 'production'
      ? 'https://zegaru-erp.herokuapp.com'
      : 'http://192.168.0.106:3001',

  ADOBE_KEY:
    environment === 'dev' || nodeEnv === 'production'
      ? '56f2d72ab51d4ac2a70483850958d0d1'
      : 'f0426bc93f8e466ca31f45d2b8e47a4d',

  USER_POSITIONS_FILTER: [
    { value: 'all', content: 'Todos' },
    { value: 'super_admin,admin', content: 'Administradores' },
    //{ value: 'operations', content: 'Operaciones' },
    //{ value: 'seller', content: 'Vendedores' },
    { value: 'inventory', content: 'Inventario' },
    { value: 'crm', content: 'CRM' },
    //{ value: 'expenses', content: 'Compras' },
  ],

  USER_POSITIONS_DISPLAY: {
    all: 'Todos',
    super_admin: 'Administrador',
    operations: 'Operaciones',
    admin: 'Administrador',
    seller: 'Vendedor',
    inventory: 'Inventario',
    crm: 'CRM',
    expenses: 'Compras',
  },

  DOCUMENT_KIND_DISPLAY: {
    dni: 'DNI',
    passport: 'Pasaporte',
    other: 'Otro',
  },

  FILE_TYPE_DISPLAY: {
    image: 'Imagen',
    pdf: 'PDF',
    word: 'Word',
    excel: 'Excel',
    other: 'Otro',
  },

  ERROR_MESSAGES: {
    REQUIRED: 'Este campo es obligatorio',
    REQUIRED_PLURAL: 'Este campo es obligatorio',
    WRONG_EMAIL: 'Ingrese un correo válido',
    DIGITS_ONLY: 'Ingrese solo números',
    LENGTH: (length) => `Ingrese ${length} caracteres`,
    MIN_LENGTH: (minLength) => `Ingrese como mínimo ${minLength} caracteres`,
    MAX_LENGTH: (maxLength) => `Ingrese como máximo ${maxLength} caracteres`,
  },

  SIDEBAR_ITEMS_PERMISSIONS: [
    {
      name: 'dashboard',
      path: '/dashboard',
      permissions: [],
    },
    {
      name: 'settings',
      path: '/settings',
      permissions: [],
    },
    {
      name: 'operations',
      path: '/operations',
      permissions: [
        'operation.post',
        'operation.put',
        'operation.delete.post',
        'operation.list.post',
        'operation.list.active.post',
      ],
    },
    {
      name: 'users',
      path: '/users',
      permissions: [
        'user.employee.post',
        'user.employee.put',
        'user.status.post',
        'user.delete.post',
        'user.list.post',
        'user.list.active.post',
      ],
    },
    {
      name: 'products',
      path: '/products',
      permissions: [
        'product.post',
        'product.put',
        'product.status.post',
        'product.delete.post',
        'product.list.post',
        'product.list.active.post',
      ],
    },
    {
      name: 'categories',
      path: '/categories',
      permissions: [
        'category.post',
        'category.put',
        'category.status.post',
        'category.delete.post',
        'category.list.post',
        'category.list.active.post',
      ],
    },
    {
      name: 'subcategories',
      path: '/subcategories',
      permissions: [
        'subcategory.post',
        'subcategory.put',
        'subcategory.status.post',
        'subcategory.delete.post',
        'subcategory.list.post',
        'subcategory.list.active.post',
        'subcategory.list.active.category.post',
      ],
    },
    {
      name: 'projects',
      permissions: [
        'project.post',
        'project.put',
        'project.status.post',
        'project.delete.post',
        'project.list.post',
        'project.list.active.post',
        'project.list.user.post',
      ],
    },
    {
      name: 'customers',
      permissions: [
        'customer.post',
        'customer.put',
        'customer.details.post',
        'customer.delete.post',
        'customer.list.post',
        'customer.list.active.post',
        'customer.payment.post',
        'customer.payment.put',
        'customer.payment.list.post',
        'customer.payment.delete.post',
        'customer.payment.image.post',
      ],
    },
    {
      name: 'folders',
      path: '/folders',
      permissions: [
        'folder.post',
        'folder.put',
        'folder.status.post',
        'folder.delete.post',
        'folder.list.post',
        'folder.list.active.post',
      ],
    },
  ],
};
