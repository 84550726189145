let constants = {
  TOAST_KINDS: ['SUCCESS', 'ERROR', 'INFO'],
  TOAST_MESSAGES: {
    SUCCESS: 'Operación completada',
    ERROR: 'Ocurrió un error',
    INFO: 'Información',
  },
  TOAST_COLORS: {
    SUCCESS: 'border-green-500',
    ERROR: 'border-red-500',
    INFO: 'border-primary-500',
  },
  TOAST_ICONS: {
    SUCCESS: `<svg class="h-8 w-8 text-green-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>`,
    ERROR: `<svg class="h-8 w-8 text-red-500" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
              <path d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
            </svg>`,
    INFO: `<svg class="h-8 w-8 text-primary-400" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
              <path d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
            </svg>`,
  },
};

export function showSuccessToast(msg) {
  showToast(constants.TOAST_KINDS[0], msg);
}
export function showErrorToast(msg) {
  showToast(constants.TOAST_KINDS[1], msg);
}
export function showInfoToast(msg) {
  showToast(constants.TOAST_KINDS[2], msg);
}

function buildToast(id, kind, msg) {
  let toast = document.createElement('div');
  toast.innerHTML = `<div
      id="toast_${id}"
      key="${id}"
      class='sm:mt-2 p-4 flex max-w-md sm:max-w-sm w-auto bg-white shadow-xl sm:rounded-lg border-2 ${
        constants.TOAST_COLORS[kind]
      } pointer-events-auto hover:bg-gray-50 transition-transform animate-toast-in-right'
    >
      <!--button onClick={() => deleteToast(toastProperties.id)}>X</button-->
      <div class="overflow-hidden flex-1">
        <div>
          <div class="flex items-start">
            <div class="flex-shrink-0">
              ${constants.TOAST_ICONS[kind]}
            </div>
            <div class="ml-3 flex-1 pt-2">
              <p class="text-base leading-5 font-medium text-gray-900">
                ${msg || constants.TOAST_MESSAGES[kind]}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>`;
  return toast;
}

function showToast(kind, msg) {
  const id = Math.floor(Math.random() * 101 + 1);
  let toast = buildToast(id, kind, msg);

  //const rootElement = document.body;
  const rootElement = document.getElementById('toast-container');
  if (!rootElement) return;
  rootElement.insertBefore(toast, rootElement.firstChild);
  setTimeout(function () {
    //let node = document.getElementById('toast_'+id)
    toast.parentNode.removeChild(toast);
  }, 8000);
}
