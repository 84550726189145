import { XIcon } from '@heroicons/react/outline';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import { Document, Page } from 'react-pdf';

import { validateFile } from '../../utils/fileUtils';
import { constants } from '../../utils/constants';
import GeneralApi from '../../utils/generalApi';
import { useAuth } from '../../contexts/Auth';

import JoinedInput from '../Inputs/JoinedInput';
import LoadingSpinner from '../LoadingSpinner';
import BaseInput from '../Inputs/BaseInput';
import { showErrorToast } from '../Toast';

const defaultRequiredRule = {
  required: constants.ERROR_MESSAGES.REQUIRED,
};

export default function CreatePaymentForm({
  customerId,
  toggleFocus,
  onSubmit,
  onCancelClick,
}) {
  const {
    register,
    handleSubmit,
    errors,
    reset,
    setError,
    clearErrors,
    setValue,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useForm();
  let history = useHistory();
  let auth = useAuth();

  const generalApi = new GeneralApi(auth, history);

  const [voucherKind, setVoucherKind] = useState('Voucher');
  const [customer, setCustomer] = useState(null);
  const [voucherFile, setVoucherFile] = useState(null);
  const initialFocusRef = useRef();

  const [previousDot, setPreviousDot] = useState(0);
  const [currentDot, setCurrentDot] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setPreviousDot(currentDot);
    }, 500);
  }, [currentDot]);

  const buildBaseInput = (label, type, name, rules, dotIndex, props) => {
    return (
      <BaseInput
        showDot
        label={label}
        type={type}
        name={name}
        register={register}
        errors={errors}
        rules={rules}
        setCurrentDot={setCurrentDot}
        previousDot={previousDot}
        currentDot={currentDot}
        dotIndex={dotIndex}
        {...props}
      />
    );
  };

  async function submit(data) {
    let response = await onSubmit(data, voucherFile);
    if (!response.success) {
      setError('server', response.message);
      setTimeout(() => {
        clearErrors('server');
      }, 200);
    }
  }

  useEffect(() => {
    if (isSubmitSuccessful) reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  useEffect(() => {
    if (toggleFocus) {
      setTimeout(() => {
        initialFocusRef.current.focus();
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleFocus]);

  async function getCustomerDetails() {
    let result = await generalApi.post('/customers/details', {
      customer: customerId,
    });
    if (!result.success) {
      return showErrorToast(result.message);
    }
    setCustomer(result.data.customer);
    if (result.data.customer.payments?.length === 0)
      setValue('description', 'Separación');
    else
      setValue('description', `Pago ${result.data.customer.payments.length}`);
  }

  useEffect(() => {
    getCustomerDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  let checkFile = async (event) => {
    let isValid = validateFile(event.target, 'voucher');
    if (!isValid) {
      setVoucherFile(null);
      showErrorToast('Formato de voucher no válido, debe ser un archivo .pdf');
      return;
    }
    setVoucherFile(event.target.files[0]);
  };

  return (
    <form
      onSubmit={handleSubmit(submit)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') return e.preventDefault();
      }}
      className="flex flex-col h-full bg-white divide-y divide-gray-200 shadow-xl"
    >
      <div className="flex-1 h-0 overflow-y-auto">
        <div className="py-6 pr-4 pl-7 bg-primary-700 sm:pl-7 sm:pr-6">
          <div className="flex items-center justify-between">
            <h2
              id="slide-over-heading"
              className="text-lg font-medium text-white"
            >
              Nuevo Pago
            </h2>
            <div className="flex items-center ml-3 h-7">
              <button
                type="button"
                onClick={onCancelClick}
                className="rounded-md bg-primary-700 text-primary-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
              >
                <span className="sr-only">Close panel</span>
                {/* Heroicon name: x */}
                <XIcon className="w-6 h-6" />
              </button>
            </div>
          </div>
          <div className="mt-1">
            <p className="text-sm text-primary-300">
              Llene los datos del formulario para crear un nuevo pago.
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-between flex-1">
          <div className="pr-4 divide-y divide-gray-200 pl-7 sm:pl-7 sm:pr-6">
            <div className="pt-6 pb-5 space-y-3">
              {buildBaseInput(
                'Descripción',
                'text',
                'description',
                defaultRequiredRule,
                0,
                {
                  inputRef: (e) => {
                    if (e != null) {
                      register(e, {
                        required: constants.ERROR_MESSAGES.REQUIRED,
                      });
                      initialFocusRef.current = e;
                    }
                  },
                }
              )}
              <JoinedInput
                isVertical
                hideSecond={voucherKind === 'Sin comprobante'}
                label="Operación"
                register={register}
                errors={errors}
                errorName="voucher_number"
                setCurrentDot={setCurrentDot}
                previousDot={previousDot}
                currentDot={currentDot}
                inputs={[
                  {
                    label: 'Tipo de operación',
                    type: 'select',
                    name: 'voucher_kind',
                    rules: {},
                    showDot: true,
                    dotIndex: 1,
                    defaultValue: 'dni',
                    options: (
                      <>
                        <option value="Voucher">Voucher</option>
                        <option value="Transferencia">Transferencia</option>
                        <option value="Sin comprobante">Sin comprobante</option>
                      </>
                    ),
                    onChange: (e) => {
                      setVoucherKind(e.target.value);
                    },
                  },
                  {
                    label: 'Número de operación',
                    type: 'text',
                    name: 'voucher_number',
                    rules: defaultRequiredRule,
                    showDot: true,
                    dotIndex: 2,
                  },
                ]}
              />
              {buildBaseInput(
                'Monto (S/)',
                'number',
                'amount',
                defaultRequiredRule,
                voucherKind !== 'Sin comprobante' ? 3 : 2,
                {
                  cornerAction: {
                    text: 'Asignar monto máximo',
                    onClick: () => {
                      if (customer)
                        setValue(
                          'amount',
                          customer.initial_fee - customer.paid
                        );
                    },
                  },
                }
              )}
              {buildBaseInput(
                'Observación',
                'text',
                'observation',
                {},
                voucherKind !== 'Sin comprobante' ? 4 : 3,
                {}
              )}
              {buildBaseInput(
                'Fecha',
                'date',
                'date',
                defaultRequiredRule,
                voucherKind !== 'Sin comprobante' ? 5 : 4,
                {
                  defaultValue: new Date().toISOString().substr(0, 10),
                }
              )}
              <div className="relative flex flex-col items-center justify-center max-w-lg px-2 pt-5 pb-6 space-y-4 border-2 border-gray-300 border-dashed rounded-md sm:space-y-0 sm:flex-row justify-evenly">
                <div className="space-y-1 text-center">
                  <svg
                    className="w-12 h-12 mx-auto text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div className="flex text-sm text-gray-600">
                    <label
                      htmlFor="inp_voucher_file"
                      className="relative mx-auto font-medium bg-white rounded-md cursor-pointer text-primary-600 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                    >
                      <span>
                        {voucherFile ? 'Cambiar voucher' : 'Elegir voucher'}
                      </span>
                      <input
                        id="inp_voucher_file"
                        name="inp_voucher_file"
                        type="file"
                        className="sr-only"
                        accept=".pdf"
                        onChange={checkFile}
                      />
                    </label>
                  </div>
                  <p className="text-xs text-gray-500">PDF hasta 10MB</p>
                </div>
                {voucherFile ? (
                  <Document
                    file={voucherFile}
                    className="sm:max-w-[50%] max-h-64 p-1 border-2 rounded-lg border-primary-200 overflow-hidden"
                    renderMode="svg"
                  >
                    <Page pageNumber={1} width={150} className="mx-auto" />
                  </Document>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end flex-shrink-0 px-4 py-4">
        <button
          type="button"
          onClick={onCancelClick}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          Cancelar
        </button>
        <button
          type="submit"
          className="inline-flex justify-center px-4 py-2 ml-4 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          {isSubmitting ? (
            <LoadingSpinner></LoadingSpinner>
          ) : (
            <span>Crear pago</span>
          )}
        </button>
      </div>
    </form>
  );
}
