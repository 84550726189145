import { Transition } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth';
import { FolderOpenIcon, SearchIcon } from '@heroicons/react/outline';

import PaginationApi from '../../utils/paginationApi';
import { checkFileType } from '../../utils/fileUtils';
import GeneralApi from '../../utils/generalApi';

import CreateForm from '../../components/Folder/CreateForm';
import EditForm from '../../components/Folder/EditForm';
import ItemCard from '../../components/Folder/ItemCard';

import CreateDocumentForm from '../../components/Document/CreateDocumentForm';
import DocumentsDetails from '../../components/Document/DocumentsDetails';
import { showErrorToast, showSuccessToast } from '../../components/Toast';
import ConfirmModalContent from '../../components/ConfirmModalContent';
import LoadingSpinner from '../../components/LoadingSpinner';
import EmptyState from '../../components/EmptyState';
import PageHeader from '../../components/PageHeader';
import SlideOver from '../../components/SlideOver';
import TabItem from '../../components/TabItem';
import Modal from '../../components/Modal';

export default function FolderList() {
  let history = useHistory();
  let auth = useAuth();

  // Folder List
  const [loading, setLoading] = useState(true);
  const [folders, setFolders] = useState([]);
  const [customers, setCustomers] = useState([]);

  // Filters
  const [kindFilter, setKindFilter] = useState('others');
  const [searchFilter, setSearchFilter] = useState('');

  const amountPerPage = 1000;
  const page = 1;

  const generalApi = new GeneralApi(auth, history);
  const customersApi = new PaginationApi(
    '/customers/list',
    amountPerPage,
    auth,
    history
  );
  const foldersApi = new PaginationApi(
    '/folders/list',
    amountPerPage,
    auth,
    history
  );

  // Slide-overs
  // Create Folder
  const [isFolderCreateOpen, setIsFolderCreateOpen] = useState(false);
  const [createColor, setCreateColor] = useState({ hex: '#3B82F6' });

  // Edit Folder
  const [isFolderEditOpen, setIsFolderEditOpen] = useState(false);
  const [selectedFolderId, setSelectedFolderId] = useState(null);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [detailsFolder, setDetailsFolder] = useState({});
  const [editColor, setEditColor] = useState({ hex: '#3B82F6' });

  // Documents
  const [isDocumentsOpen, setIsDocumentsOpen] = useState(false);
  const [isCreateDocumentOpen, setIsCreateDocumentOpen] = useState(false);
  const [refreshDocuments, setRefreshDocuments] = useState(true);

  // Confirm Modals
  // Delete Folder
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  function setEditFolderData(folder) {
    setSelectedFolderId(folder._id);
    setEditColor({ hex: folder.color });
    setDetailsFolder(folder);
  }

  async function createFolder(data) {
    const result = await generalApi.post(`/folders`, data);
    if (!result.success) {
      showErrorToast(result.message);
      return result;
    }
    showSuccessToast('Carpeta creada');
    setIsFolderCreateOpen(false);
    getFolders();
    return result;
  }

  async function updateFolder(data) {
    const result = await generalApi.put(`/folders`, {
      ...data,
      folder: selectedFolderId,
    });
    if (!result.success) {
      showErrorToast(result.message);
      return result;
    }
    showSuccessToast('Carpeta modificada');
    setIsFolderEditOpen(false);
    setSelectedFolderId(null);
    getFolders();
    return result;
  }

  async function deleteFolder(folder_id) {
    setLoadingDelete(true);
    const result = await generalApi.post(`/folders/delete`, {
      folder: folder_id,
    });
    if (!result.success) {
      showErrorToast(result.message);
      setLoadingDelete(false);
      return result;
    }
    showSuccessToast('Carpeta eliminada');
    setIsConfirmDeleteOpen(false);
    setLoadingDelete(false);
    setSelectedFolderId(null);
    getFolders();
    return result;
  }

  async function createDocument(data, file) {
    if (!file) {
      return { success: false, message: 'Seleccione un archivo válido' };
    }

    let fileData = new FormData();

    if (selectedCustomerId) fileData.append('customer', selectedCustomerId);
    else if (selectedFolderId) fileData.append('folder', selectedFolderId);
    fileData.append('name', data.name);
    if (data.comment) fileData.append('comment', data.comment);
    fileData.append('file_type', checkFileType(file.name));
    fileData.append('upfile', file);

    const result = await generalApi.post(`/folders/documents`, fileData);
    if (!result?.success) {
      showErrorToast(result?.message);
      return result;
    }
    showSuccessToast('Documento creado');
    setIsCreateDocumentOpen(false);
    setRefreshDocuments(true);
    if (kindFilter === 'customers') getCustomers();
    else getFolders();
    return result;
  }

  useEffect(() => {
    if (kindFilter === 'customers') getCustomers();
    else getFolders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kindFilter, searchFilter, page]);

  async function getFolders() {
    setLoading(true);
    let result = await foldersApi.getContent(page, {
      search: searchFilter,
    });
    if (!result.success) {
      setLoading(false);
      return showErrorToast(result.message);
    }
    setFolders(result.data.folders);
    setLoading(false);
  }

  async function getCustomers() {
    setLoading(true);
    let result = await customersApi.getContent(page, {
      search: searchFilter,
    });
    if (!result.success) {
      setLoading(false);
      return showErrorToast(result.message);
    }
    setCustomers(result.data.customers);
    setLoading(false);
  }

  return (
    <div>
      <Modal
        isOpen={isConfirmDeleteOpen}
        onClose={() => {
          setIsConfirmDeleteOpen(false);
        }}
      >
        <ConfirmModalContent
          onClose={() => {
            setIsConfirmDeleteOpen(false);
          }}
          onConfirm={() => {
            deleteFolder(selectedFolderId);
          }}
          title="Eliminar carpeta"
          content="¿Está seguro que desea eliminar esta carpeta?"
          buttonLoading={loadingDelete}
        />
      </Modal>

      <SlideOver
        isOpen={isDocumentsOpen}
        isBehind={isCreateDocumentOpen}
        onClose={() => {
          setIsDocumentsOpen(false);
        }}
      >
        <DocumentsDetails
          folderId={selectedFolderId}
          customerId={selectedCustomerId}
          toggleFocus={isDocumentsOpen}
          refreshDocuments={refreshDocuments}
          setRefreshDocuments={setRefreshDocuments}
          onCreateDocumentClick={() => {
            setIsCreateDocumentOpen(true);
          }}
          onCloseClick={() => {
            setIsDocumentsOpen(false);
          }}
        />
        <SlideOver
          isOpen={isCreateDocumentOpen}
          onClose={() => {
            setIsCreateDocumentOpen(false);
          }}
        >
          <CreateDocumentForm
            toggleFocus={isCreateDocumentOpen}
            onSubmit={createDocument}
            onCancelClick={() => {
              setIsCreateDocumentOpen(false);
            }}
          />
        </SlideOver>
      </SlideOver>

      {/* Header */}
      <div className="px-4 py-4 bg-white shadow sm:pb-0 sm:px-6 lg:px-8">
        <PageHeader title="Documentos" />
        {/* Folder Kind filter */}
        <div className="mt-4">
          {/* Dropdown menu on small screens */}
          <div className="sm:hidden">
            <label htmlFor="selected-tab" className="sr-only">
              Escoger tipo de documento
            </label>
            <select
              id="selected-tab"
              name="selected-tab"
              className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
              value={kindFilter}
              onChange={(event) => {
                setKindFilter(event.target.value);
              }}
            >
              <option value="others">Administrativos</option>
              <option value="customer">Clientes</option>
            </select>
          </div>
          {/* Tabs at small breakpoint and up */}
          <div className="hidden sm:block">
            <nav className="flex -mb-px space-x-8">
              <TabItem
                content="Administrativos"
                active={kindFilter === 'others'}
                onClick={() => {
                  setKindFilter('others');
                }}
              />
              <TabItem
                content="Clientes"
                active={kindFilter === 'customers'}
                onClick={() => {
                  setKindFilter('customers');
                }}
              />
            </nav>
          </div>
        </div>
      </div>

      {/* Folders grid */}
      <div className="relative z-0 flex flex-col-reverse flex-1 overflow-hidden sm:flex-row">
        <main className="relative z-0 flex-1 px-0 overflow-y-auto focus:outline-none sm:px-6 lg:px-8">
          <div className="flex flex-col mb-4 sm:my-4">
            <div className="min-w-full align-middle">
              <div className="flex items-center justify-between px-4 py-3 mb-3 bg-white border-t border-gray-200 shadow sm:rounded-lg sm:px-6">
                <div className="w-full">
                  <label htmlFor="inp_search" className="sr-only">
                    {kindFilter === 'customers'
                      ? 'Busca por nombre o documento del cliente'
                      : 'Busca por nombre de la carpeta'}
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      {/* Heroicon name: search */}
                      <SearchIcon className="w-5 h-5 text-gray-400" />
                    </div>
                    <input
                      id="inp_search"
                      name="search"
                      className="block w-full py-2 pl-10 pr-3 leading-5 placeholder-gray-500 bg-white border border-gray-300 rounded-md focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                      placeholder={
                        kindFilter === 'customers'
                          ? 'Busca por nombre o número de documento del cliente'
                          : 'Busca por nombre de la carpeta'
                      }
                      type="search"
                      value={searchFilter}
                      onChange={(e) => {
                        setSearchFilter(e.target.value);
                      }}
                    ></input>
                  </div>
                </div>
              </div>
              <Transition
                show={loading}
                className="flex items-center p-4 bg-white shadow sm:rounded-lg"
              >
                <LoadingSpinner color="primary"></LoadingSpinner>
              </Transition>
              <Transition
                show={!loading}
                as="ul"
                className={`${
                  kindFilter === 'customers'
                    ? 'md:grid-cols-3'
                    : 'md:grid-cols-2'
                } grid grid-cols-1 gap-x-6 gap-y-3 `}
              >
                {kindFilter === 'customers'
                  ? customers.map((obj) => (
                      <ItemCard
                        key={obj._id}
                        kind="customer"
                        folder={{
                          _id: obj._id,
                          name: `${obj.first_name} ${obj.last_name_p} ${obj.last_name_m}`,
                          document_kind: obj.document_kind,
                          document_number: obj.document_number,
                          color: '#0ea5e9',
                          documents: obj.documents,
                          is_active: obj.is_active,
                        }}
                        searchWords={[searchFilter]}
                        onDocumentsClick={(customer) => {
                          setSelectedFolderId(null);
                          setSelectedCustomerId(customer._id);
                          setIsDocumentsOpen(true);
                          setRefreshDocuments(true);
                        }}
                      />
                    ))
                  : folders.map((obj) => (
                      <ItemCard
                        key={obj._id}
                        folder={obj}
                        searchWords={[searchFilter]}
                        onEditClick={(folder) => {
                          setEditFolderData(folder);
                          setIsFolderEditOpen(true);
                        }}
                        onDeleteClick={(folder) => {
                          setSelectedFolderId(folder._id);
                          setIsConfirmDeleteOpen(true);
                        }}
                        onDocumentsClick={(folder) => {
                          setSelectedCustomerId(null);
                          setSelectedFolderId(folder._id);
                          setIsDocumentsOpen(true);
                          setRefreshDocuments(true);
                        }}
                      />
                    ))}
              </Transition>
              <Transition
                show={
                  !loading &&
                  ((kindFilter === 'customers' && customers.length === 0) ||
                    (kindFilter === 'others' && folders.length === 0))
                }
                className="px-4 py-8 mt-3 bg-white shadow sm:rounded-lg"
              >
                <EmptyState
                  title="No se encontraron resultados"
                  subtitle={
                    searchFilter === '' && kindFilter === 'customers'
                      ? 'Empieza creando un nuevo cliente'
                      : searchFilter === '' && kindFilter === 'others'
                      ? 'Empieza creando una nueva carpeta en el panel derecho'
                      : kindFilter === 'customers'
                      ? 'No encuentras lo que buscas? Verifica que no sea un error o crea un nuevo cliente'
                      : 'No encuentras lo que buscas? Verifica que no sea un error o crea una nueva carpeta en el panel derecho'
                  }
                  buttonText={kindFilter === 'customers' && 'Crear cliente'}
                  buttonAction={() => history.push('/customers')}
                  icon={
                    <FolderOpenIcon className="w-12 h-12 mx-auto text-gray-400" />
                  }
                />
              </Transition>
            </div>
          </div>
        </main>
        {kindFilter === 'others' && (
          <aside className="relative flex flex-col w-full px-0 mx-auto xl:flex-shrink-0 max-w-96 sm:max-w-none sm:w-64 xl:w-96 sm:pr-6 lg:pr-8">
            <div className="flex flex-col mt-4 sm:my-4">
              <div className="min-w-full align-middle">
                <div className="flex flex-col items-center justify-between mb-3 bg-white border-t border-gray-200 shadow sm:rounded-lg">
                  <div className="w-full p-4 bg-white border-b border-gray-200 sm:rounded-t-lg sm:px-6">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      {isFolderEditOpen ? 'Editar Carpeta' : 'Crear Carpeta'}
                    </h3>
                    <p className="hidden mt-1 text-sm text-gray-500 sm:block">
                      {isFolderEditOpen
                        ? 'Llene los datos del formulario para editar la carpeta.'
                        : 'Llene los datos del formulario para crear una nueva carpeta.'}
                    </p>
                  </div>
                  <div className="w-full bg-white border-b border-gray-200 sm:rounded-lg">
                    {isFolderEditOpen ? (
                      <EditForm
                        folder={detailsFolder}
                        color={editColor}
                        toggleFocus={selectedFolderId}
                        onColorChange={(color) => {
                          setEditColor(color);
                        }}
                        onSubmit={updateFolder}
                        onCancelClick={() => {
                          setIsFolderEditOpen(false);
                        }}
                      ></EditForm>
                    ) : (
                      <CreateForm
                        color={createColor}
                        toggleFocus={isFolderCreateOpen}
                        onColorChange={(color) => {
                          setCreateColor(color);
                        }}
                        onSubmit={createFolder}
                      ></CreateForm>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </aside>
        )}
      </div>
    </div>
  );
}
