import { Transition } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import highlightText from '@brianmcallister/highlight-text';
import {
  ChevronRightIcon,
  SearchIcon,
  UserGroupIcon,
} from '@heroicons/react/outline';
import { UserCircleIcon } from '@heroicons/react/solid';

import { checkFileType } from '../../utils/fileUtils';
import PaginationApi from '../../utils/paginationApi';
import { constants } from '../../utils/constants';
import StorageService from '../../utils/storage';
import GeneralApi from '../../utils/generalApi';
import { useAuth } from '../../contexts/Auth';

import CreateDocumentForm from '../../components/Document/CreateDocumentForm';
import CreatePaymentForm from '../../components/Customer/CreatePaymentForm';
import DocumentsDetails from '../../components/Document/DocumentsDetails';
import PaymentsDetails from '../../components/Customer/PaymentsDetails';
import CustomerCreateForm from '../../components/Customer/CreateForm';
import CustomerEditForm from '../../components/Customer/EditForm';
import CustomerDetails from '../../components/Customer/Details';

import { showErrorToast, showSuccessToast } from '../../components/Toast';
import ConfirmModalContent from '../../components/ConfirmModalContent';
import LoadingSpinner from '../../components/LoadingSpinner';
import EmptyState from '../../components/EmptyState';
import PageHeader from '../../components/PageHeader';
import Pagination from '../../components/Pagination';
import SlideOver from '../../components/SlideOver';
import TabItem from '../../components/TabItem';
import Modal from '../../components/Modal';

export default function CustomerList() {
  let history = useHistory();
  let auth = useAuth();

  // Customer List
  const [loading, setLoading] = useState(true);
  const [customers, setCustomers] = useState([]);

  // Filters
  const [statusFilter, setStatusFilter] = useState('all');
  const [searchFilter, setSearchFilter] = useState('');

  // Pagination
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [total, setTotal] = useState(0);

  const amountPerPage = 9;

  const generalApi = new GeneralApi(auth, history);
  const customersApi = new PaginationApi(
    '/customers/list',
    amountPerPage,
    auth,
    history
  );

  // Slide-overs
  // Create Customer
  const [isCustomerCreateOpen, setIsCustomerCreateOpen] = useState(false);

  // Edit Customer
  const [isCustomerEditOpen, setIsCustomerEditOpen] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState('');

  // Customer Details
  const [isCustomerDetailsOpen, setIsCustomerDetailsOpen] = useState(false);
  const [detailsCustomer, setDetailsCustomer] = useState({});
  const [refreshDetails, setRefreshDetails] = useState(true);

  // Payments
  const [isCustomerPaymentsOpen, setIsCustomerPaymentsOpen] = useState(false);
  const [isCreatePaymentOpen, setIsCreatePaymentOpen] = useState(false);
  const [refreshPayments, setRefreshPayments] = useState(true);

  // Documents
  const [isCustomerDocumentsOpen, setIsCustomerDocumentsOpen] = useState(false);
  const [isCreateDocumentOpen, setIsCreateDocumentOpen] = useState(false);
  const [refreshDocuments, setRefreshDocuments] = useState(true);

  // Confirm Modals
  // Delete Customer
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  function setEditCustomerData(customer) {
    setSelectedCustomerId(customer._id);
    setDetailsCustomer(customer);
  }

  function setDetailsCustomerData(customerId) {
    setSelectedCustomerId(customerId);
    setIsCustomerDetailsOpen(true);
    setRefreshDetails(true);
  }

  async function createCustomer(data) {
    const result = await generalApi.post(`/customers`, data);
    if (!result.success) {
      showErrorToast(result.message);
      return result;
    }
    showSuccessToast('Cliente creado');
    setIsCustomerCreateOpen(false);
    setDetailsCustomerData(result.data.customer);
    getCustomers();
    return result;
  }

  async function updateCustomer(data) {
    const result = await generalApi.put(`/customers`, {
      ...data,
      customer: selectedCustomerId,
    });
    if (!result.success) {
      showErrorToast(result.message);
      return result;
    }
    showSuccessToast('Cliente modificado');
    setIsCustomerEditOpen(false);
    //setIsCustomerDetailsOpen(false);
    //setSelectedCustomerId('');
    getCustomers();
    setRefreshDetails(true);
    return result;
  }

  async function deleteCustomer(customer_id) {
    setLoadingDelete(true);
    const result = await generalApi.post(`/customers/delete`, {
      customer: customer_id,
    });
    if (!result.success) {
      showErrorToast(result.message);
      setLoadingDelete(false);
      return result;
    }
    showSuccessToast('Cliente eliminado');
    setIsConfirmDeleteOpen(false);
    setIsCustomerDetailsOpen(false);
    setLoadingDelete(false);
    setSelectedCustomerId('');
    getCustomers();
    return result;
  }

  async function createPayment(data, file) {
    const result = await generalApi.post(`/customers/payments`, {
      ...data,
      customer: selectedCustomerId,
    });
    if (!result?.success) {
      showErrorToast(result?.message);
      return result;
    }
    if (file !== null) {
      let fileData = new FormData();

      fileData.append('customer', selectedCustomerId);
      fileData.append('payment', result.data.payment);
      fileData.append('upfile', file);

      setLoading(true);
      const fileResult = await generalApi.post(
        `/customers/payments/image`,
        fileData
      );
      if (!fileResult.success) {
        setLoading(false);
        showErrorToast(fileResult.message);
        return fileResult;
      }
    }
    showSuccessToast('Pago creado');
    setIsCreatePaymentOpen(false);
    setRefreshPayments(true);
    getCustomers();
    return result;
  }

  async function createDocument(data, file) {
    let fileData = new FormData();

    fileData.append('customer', selectedCustomerId);
    fileData.append('name', data.name);
    fileData.append('file_type', checkFileType(file.name));
    fileData.append('upfile', file);

    const result = await generalApi.post(`/folders/documents`, fileData);
    if (!result?.success) {
      showErrorToast(result?.message);
      return result;
    }
    showSuccessToast('Documento creado');
    setIsCreateDocumentOpen(false);
    setRefreshDocuments(true);
    getCustomers();
    return result;
  }

  useEffect(() => {
    getCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter, searchFilter, page]);

  async function getCustomers() {
    setLoading(true);
    let result = await customersApi.getContent(page, {
      search: searchFilter,
      status: statusFilter,
      project: StorageService.get('chosenProject')?._id,
    });
    if (!result.success) {
      setLoading(false);
      return showErrorToast(result.message);
    }
    setCustomers(result.data.customers);
    setMaxPage(result.data.pagination.maxPage);
    setTotal(result.data.pagination.total);
    setLoading(false);
  }

  return (
    <div>
      {/* Slide-over Create */}
      <SlideOver
        isOpen={isCustomerCreateOpen}
        onClose={() => {
          setIsCustomerCreateOpen(false);
        }}
      >
        <CustomerCreateForm
          toggleFocus={isCustomerCreateOpen}
          onSubmit={createCustomer}
          onCancelClick={() => {
            setIsCustomerCreateOpen(false);
          }}
        />
      </SlideOver>

      {/* Slide-over Details */}
      <SlideOver
        isOpen={isCustomerDetailsOpen}
        isBehind={
          isCustomerEditOpen ||
          isCustomerPaymentsOpen ||
          isCustomerDocumentsOpen
        }
        onClose={() => {
          setIsCustomerDetailsOpen(false);
        }}
      >
        <>
          {/* Slide-over Edit */}
          <SlideOver
            isOpen={isCustomerEditOpen}
            onClose={() => {
              setIsCustomerEditOpen(false);
            }}
          >
            <CustomerEditForm
              customer={detailsCustomer}
              toggleFocus={isCustomerEditOpen}
              onSubmit={updateCustomer}
              onCancelClick={() => {
                setIsCustomerEditOpen(false);
              }}
            />
          </SlideOver>
          {/* Slide-over Payments */}
          <SlideOver
            isOpen={isCustomerPaymentsOpen}
            isBehind={isCreatePaymentOpen}
            onClose={() => {
              setIsCustomerPaymentsOpen(false);
            }}
          >
            <PaymentsDetails
              customerId={selectedCustomerId}
              toggleFocus={isCustomerPaymentsOpen}
              refreshPayments={refreshPayments}
              setRefreshPayments={setRefreshPayments}
              onCreatePaymentClick={() => {
                setIsCreatePaymentOpen(true);
              }}
              onCloseClick={() => {
                setIsCustomerPaymentsOpen(false);
              }}
            />
            <SlideOver
              isOpen={isCreatePaymentOpen}
              onClose={() => {
                setIsCreatePaymentOpen(false);
              }}
            >
              <CreatePaymentForm
                customerId={selectedCustomerId}
                toggleFocus={isCreatePaymentOpen}
                onSubmit={createPayment}
                onCancelClick={() => {
                  setIsCreatePaymentOpen(false);
                }}
              />
            </SlideOver>
          </SlideOver>

          <SlideOver
            isOpen={isCustomerDocumentsOpen}
            isBehind={isCreateDocumentOpen}
            onClose={() => {
              setIsCustomerDocumentsOpen(false);
            }}
          >
            <DocumentsDetails
              customerId={selectedCustomerId}
              toggleFocus={isCustomerDocumentsOpen}
              refreshDocuments={refreshDocuments}
              setRefreshDocuments={setRefreshDocuments}
              onCreateDocumentClick={() => {
                setIsCreateDocumentOpen(true);
              }}
              onCloseClick={() => {
                setIsCustomerDocumentsOpen(false);
              }}
            />
            <SlideOver
              isOpen={isCreateDocumentOpen}
              onClose={() => {
                setIsCreateDocumentOpen(false);
              }}
            >
              <CreateDocumentForm
                toggleFocus={isCreateDocumentOpen}
                onSubmit={createDocument}
                onCancelClick={() => {
                  setIsCreateDocumentOpen(false);
                }}
              />
            </SlideOver>
          </SlideOver>

          <Modal
            isOpen={isConfirmDeleteOpen}
            onClose={() => {
              setIsConfirmDeleteOpen(false);
            }}
          >
            <ConfirmModalContent
              onClose={() => {
                setIsConfirmDeleteOpen(false);
              }}
              onConfirm={() => {
                deleteCustomer(selectedCustomerId);
              }}
              title="Eliminar cliente"
              content="¿Está seguro que desea eliminar este cliente?"
              buttonLoading={loadingDelete}
            />
          </Modal>
          <CustomerDetails
            customerId={selectedCustomerId}
            toggleFocus={isCustomerDetailsOpen}
            refreshDetails={refreshDetails}
            setRefreshDetails={setRefreshDetails}
            onCloseClick={() => {
              setIsCustomerDetailsOpen(false);
              setSelectedCustomerId('');
            }}
            onPaymentsClick={() => {
              setIsCustomerPaymentsOpen(true);
              setRefreshPayments(true);
            }}
            onDocumentsClick={() => {
              setIsCustomerDocumentsOpen(true);
              setRefreshDocuments(true);
            }}
            onEditClick={(customer) => {
              setEditCustomerData(customer);
              setIsCustomerEditOpen(true);
            }}
            onDeleteClick={() => {
              setIsConfirmDeleteOpen(true);
            }}
          ></CustomerDetails>
        </>
      </SlideOver>

      {/* Header */}
      <div className="px-4 py-4 bg-white shadow sm:pb-0 sm:px-6 lg:px-8">
        <PageHeader
          title="Clientes"
          buttonText="Crear cliente"
          buttonAction={() => {
            setSelectedCustomerId('');
            setIsCustomerCreateOpen(true);
          }}
        />
        {/* Customer Position filter */}
        <div className="mt-4">
          {/* Dropdown menu on small screens */}
          <div className="sm:hidden">
            <label htmlFor="selected-tab" className="sr-only">
              Escoger tipo de cliente
            </label>
            <select
              id="selected-tab"
              name="selected-tab"
              className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
              value={statusFilter}
              onChange={(event) => {
                setStatusFilter(event.target.value);
              }}
            >
              <option value="all">Todos</option>
              <option value="Pendiente">Pendientes</option>
              <option value="Pagado">Pagados</option>
            </select>
          </div>
          {/* Tabs at small breakpoint and up */}
          <div className="hidden sm:block">
            <nav className="flex -mb-px space-x-8">
              <TabItem
                content="Todos"
                active={statusFilter === 'all'}
                onClick={() => {
                  setStatusFilter('all');
                }}
              ></TabItem>
              <TabItem
                content="Pendientes"
                active={statusFilter === 'Pendiente'}
                onClick={() => {
                  setStatusFilter('Pendiente');
                }}
              ></TabItem>
              <TabItem
                content="Pagados"
                active={statusFilter === 'Pagado'}
                onClick={() => {
                  setStatusFilter('Pagado');
                }}
              ></TabItem>
            </nav>
          </div>
        </div>
      </div>

      {/* Customers grid */}
      <div className="px-0 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex flex-col mt-4">
          <div className="min-w-full align-middle">
            <div className="flex items-center justify-between px-4 py-3 mb-3 bg-white border-t border-gray-200 shadow sm:rounded-lg sm:px-6">
              <div className="w-full">
                <label htmlFor="inp_search" className="sr-only">
                  Busca por nombres, documento, correo o departamento
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    {/* Heroicon name: search */}
                    <SearchIcon className="w-5 h-5 text-gray-400" />
                  </div>
                  <input
                    id="inp_search"
                    name="search"
                    className="block w-full py-2 pl-10 pr-3 leading-5 placeholder-gray-500 bg-white border border-gray-300 rounded-md focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                    placeholder="Busca por nombres, documento, correo o departamento"
                    type="search"
                    value={searchFilter}
                    onChange={(e) => {
                      setSearchFilter(e.target.value);
                    }}
                  ></input>
                </div>
              </div>
            </div>
            <Transition
              show={loading}
              className="flex items-center p-4 bg-white shadow sm:rounded-lg"
            >
              <LoadingSpinner color="primary"></LoadingSpinner>
            </Transition>
            <Transition show={!loading} className="shadow sm:hidden">
              <ul className="mt-2 overflow-hidden divide-y divide-gray-200 shadow sm:hidden">
                {customers.map((obj) => (
                  <li key={obj._id}>
                    <button
                      onClick={() => {
                        setDetailsCustomerData(obj._id);
                      }}
                      className="block w-full px-4 py-4 transition-all bg-white border-2 border-transparent group focus:outline-none focus:border-primary-500"
                    >
                      <span className="flex items-center space-x-3">
                        <span className="flex flex-1 space-x-2 truncate">
                          {/* Heroicon name: user-circle */}
                          <UserCircleIcon className="flex-shrink-0 w-6 h-6 text-gray-400" />
                          <span className="flex flex-col text-sm text-left text-gray-500 truncate">
                            <span
                              className="truncate"
                              dangerouslySetInnerHTML={{
                                __html: highlightText(
                                  `${obj.first_name} ${obj.last_name_p} ${obj.last_name_m}` ||
                                    '',
                                  [searchFilter]
                                ),
                              }}
                            ></span>
                            <span>
                              <span className="font-medium text-gray-900">
                                {obj.email}
                              </span>
                            </span>
                            <span>
                              S/
                              {obj.status === 'Pagado'
                                ? obj.initial_fee
                                : obj.initial_fee - (obj.paid || 0)}
                              <span
                                className={`${
                                  obj.status === 'Pagado'
                                    ? 'bg-green-100 text-green-800'
                                    : 'bg-yellow-100 text-yellow-800'
                                } inline-flex items-center text-center px-2.5 py-0.5 ml-2 rounded-full text-xs font-medium capitalize`}
                              >
                                {obj.status}
                              </span>
                            </span>
                          </span>
                        </span>
                        {/* Heroicon name: chevron-right */}
                        <ChevronRightIcon className="flex-shrink-0 w-5 h-5 text-gray-400 transition-colors group-focus:text-primary-500" />
                      </span>
                    </button>
                  </li>
                ))}
              </ul>
            </Transition>
            <Transition
              show={!loading}
              className="hidden min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg sm:block"
            >
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                      Cliente
                    </th>
                    <th className="px-6 py-3 text-xs font-medium tracking-wider text-right text-gray-500 uppercase bg-gray-50">
                      Contacto
                    </th>
                    <th className="px-6 py-3 text-xs font-medium tracking-wider text-right text-gray-500 uppercase bg-gray-50">
                      Departamento
                    </th>
                    <th className="px-6 py-3 text-xs font-medium tracking-wider text-right text-gray-500 uppercase bg-gray-50">
                      Deuda
                    </th>
                    <th className="px-6 py-3 text-xs font-medium tracking-wide text-right text-gray-500 uppercase bg-gray-50">
                      Estado
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {customers.map((obj) => (
                    <tr key={obj._id} className="bg-white">
                      <td className="w-full px-4 py-2 text-sm text-gray-900 max-w-0 whitespace-nowrap">
                        <div className="flex">
                          <button
                            onClick={() => {
                              setDetailsCustomerData(obj._id);
                            }}
                            className="w-full p-2 text-sm truncate transition-all rounded-md cursor-pointer group focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary-500"
                          >
                            <div className="flex items-center space-x-2">
                              {/* Heroicon name: user-circle */}
                              <UserCircleIcon className="flex-shrink-0 hidden w-6 h-6 text-gray-400 transition-colors group-hover:text-gray-500 group-focus:text-gray-500 md:block" />
                              <div className="text-left text-gray-500 transition-colors group-hover:text-gray-900 group-focus:text-gray-900">
                                <p
                                  className="underline truncate "
                                  dangerouslySetInnerHTML={{
                                    __html: highlightText(
                                      `${obj.first_name} ${obj.last_name_p} ${obj.last_name_m}` ||
                                        '',
                                      [searchFilter]
                                    ),
                                  }}
                                ></p>
                                <span>
                                  {
                                    constants.DOCUMENT_KIND_DISPLAY[
                                      obj.document_kind
                                    ]
                                  }
                                  :{' '}
                                </span>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: highlightText(
                                      obj.document_number || '',
                                      [searchFilter]
                                    ),
                                  }}
                                />
                              </div>
                            </div>
                          </button>
                        </div>
                      </td>
                      <td className="px-6 py-4 text-sm text-right text-gray-500 whitespace-nowrap">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: highlightText(obj.email || '', [
                              searchFilter,
                            ]),
                          }}
                        />
                        {obj.phone_number}
                      </td>
                      <td className="px-6 py-4 text-sm text-right text-gray-500 whitespace-nowrap">
                        #
                        <span
                          dangerouslySetInnerHTML={{
                            __html: highlightText(obj.department || '', [
                              searchFilter,
                            ]),
                          }}
                        />
                        <p>S/ {obj.total_cost}</p>
                      </td>
                      <td className="px-4 py-2 text-sm text-right text-gray-500 whitespace-nowrap">
                        <button
                          onClick={() => {
                            setDetailsCustomerData(obj._id);
                            setTimeout(() => {
                              setIsCustomerPaymentsOpen(true);
                              setRefreshPayments(true);
                            }, 500);
                          }}
                          className="w-full p-2 text-sm truncate transition-all rounded-md cursor-pointer group focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary-500"
                        >
                          <p>Cuota inicial: S/ {obj.initial_fee}</p>
                          <p>
                            Pendiente: S/ {obj.initial_fee - (obj.paid || 0)}
                          </p>
                        </button>
                      </td>
                      <td className="px-6 py-4 text-sm text-right text-gray-500 whitespace-nowrap">
                        <span
                          className={`${
                            obj.status === 'Pagado'
                              ? 'bg-green-100 text-green-800'
                              : 'bg-yellow-100 text-yellow-800'
                          } inline-flex items-center text-center px-2.5 py-0.5 ml-2 rounded-full text-xs font-medium capitalize`}
                        >
                          {obj.status}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Transition>
            <Transition
              show={!loading && customers.length === 0}
              className="px-4 py-8 mt-3 bg-white shadow sm:rounded-lg"
            >
              <EmptyState
                title="No se encontraron resultados"
                subtitle={
                  searchFilter === ''
                    ? 'Empieza creando un nuevo cliente'
                    : 'No encuentras lo que buscas? Verifica que no sea un error o crea un nuevo cliente'
                }
                buttonText="Crear cliente"
                buttonAction={() => {
                  setSelectedCustomerId('');
                  setIsCustomerCreateOpen(true);
                }}
                icon={
                  <UserGroupIcon className="w-12 h-12 mx-auto text-gray-400" />
                }
              />
            </Transition>
            {/* Pagination */}
            <Pagination
              page={page}
              amountPerPage={amountPerPage}
              total={total}
              maxPage={maxPage}
              onPreviousPage={() => {
                setPage(page - 1);
              }}
              onNextPage={() => {
                setPage(page + 1);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
