import { Route, Redirect } from 'react-router-dom';

import { useAuth } from '../../contexts/Auth';
import StorageService from '../../utils/storage';

import Sidebar from '../../components/Sidebar';

export default function PrivateRoute({
  needsProject = false,
  children,
  ...rest
}) {
  let auth = useAuth();
  const redirectToProjects =
    auth.isAuthenticated &&
    needsProject &&
    StorageService.get('chosenProject') === null;
  return (
    <Route
      {...rest}
      render={({ location }) =>
        redirectToProjects ? (
          <Redirect
            to={{
              pathname: '/projects',
              search: '?needed=true',
              state: { from: location },
            }}
          />
        ) : auth.isAuthenticated ? (
          <Sidebar>{children}</Sidebar>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
