import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { XIcon } from '@heroicons/react/outline';

import { constants } from '../../utils/constants';

import LoadingSpinner from '../LoadingSpinner';
import JoinedInput from '../Inputs/JoinedInput';
import BaseInput from '../Inputs/BaseInput';

const defaultRequiredRule = {
  required: constants.ERROR_MESSAGES.REQUIRED,
};

export default function UserCreateForm({
  toggleFocus,
  onSubmit,
  onCancelClick,
}) {
  const {
    register,
    handleSubmit,
    watch,
    errors,
    reset,
    setError,
    clearErrors,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useForm();
  const watchDocumentKind = watch('document_kind', 'dni');
  const initialFocusRef = useRef();

  const [previousDot, setPreviousDot] = useState(0);
  const [currentDot, setCurrentDot] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setPreviousDot(currentDot);
    }, 500);
  }, [currentDot]);

  const buildBaseInput = (label, type, name, rules, dotIndex, props) => {
    return (
      <BaseInput
        showDot
        label={label}
        type={type}
        name={name}
        register={register}
        errors={errors}
        rules={rules}
        setCurrentDot={setCurrentDot}
        previousDot={previousDot}
        currentDot={currentDot}
        dotIndex={dotIndex}
        {...props}
      />
    );
  };

  async function submit(data) {
    let response = await onSubmit(data);
    if (!response.success) {
      setError('server', response.message);
      setTimeout(() => {
        clearErrors('server');
      }, 200);
    }
  }

  useEffect(() => {
    if (isSubmitSuccessful) reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  useEffect(() => {
    if (toggleFocus) {
      setTimeout(() => {
        initialFocusRef.current.focus();
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleFocus]);

  return (
    <form
      onSubmit={handleSubmit(submit)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') return e.preventDefault();
      }}
      className="flex flex-col h-full bg-white divide-y divide-gray-200 shadow-xl"
    >
      <div className="flex-1 h-0 overflow-y-auto">
        <div className="py-6 pr-4 pl-7 bg-primary-700 sm:pr-6">
          <div className="flex items-center justify-between">
            <h2
              id="slide-over-heading"
              className="text-lg font-medium text-white"
            >
              Nuevo Empleado
            </h2>
            <div className="flex items-center ml-3 h-7">
              <button
                type="button"
                onClick={onCancelClick}
                className="rounded-md bg-primary-700 text-primary-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
              >
                <span className="sr-only">Close panel</span>
                {/* Heroicon name: x */}
                <XIcon className="w-6 h-6" />
              </button>
            </div>
          </div>
          <div className="mt-1">
            <p className="text-sm text-primary-300">
              Llene los datos del formulario para crear un nuevo empleado.
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-between flex-1">
          <div className="pr-4 divide-y divide-gray-200 pl-7 sm:pr-6">
            <div className="pt-6 pb-5 space-y-3">
              <JoinedInput
                isVertical
                label="Documento"
                register={register}
                errors={errors}
                errorName="document_number"
                setCurrentDot={setCurrentDot}
                previousDot={previousDot}
                currentDot={currentDot}
                inputs={[
                  {
                    label: 'Tipo de documento',
                    type: 'select',
                    name: 'document_kind',
                    rules: {},
                    showDot: true,
                    dotIndex: 0,
                    defaultValue: 'dni',
                    ref: (e) => {
                      if (e != null) {
                        register(e);
                        initialFocusRef.current = e;
                      }
                    },
                    options: (
                      <>
                        <option value="dni">DNI</option>
                        <option value="passport">Pasaporte</option>
                        <option value="other">Otro</option>
                      </>
                    ),
                  },
                  {
                    label: 'Número de documento',
                    type: 'text',
                    name: 'document_number',
                    rules: {
                      required: constants.ERROR_MESSAGES.REQUIRED,
                      validate: {
                        length: (value) =>
                          watchDocumentKind !== 'dni'
                            ? null
                            : value.length === 8
                            ? null
                            : constants.ERROR_MESSAGES.LENGTH(8),
                        pattern: (value) =>
                          watchDocumentKind !== 'dni'
                            ? null
                            : /^[0-9]*$/.exec(value)
                            ? null
                            : constants.ERROR_MESSAGES.DIGITS_ONLY,
                      },
                    },
                    showDot: true,
                    dotIndex: 1,
                  },
                ]}
              />
              {buildBaseInput(
                'Nombres',
                'text',
                'first_name',
                defaultRequiredRule,
                2
              )}
              <JoinedInput
                label="Apellidos"
                register={register}
                errors={errors}
                error={errors.last_name_p || errors.last_name_m}
                setCurrentDot={setCurrentDot}
                previousDot={previousDot}
                currentDot={currentDot}
                inputs={[
                  {
                    label: 'Paterno',
                    type: 'text',
                    name: 'last_name_p',
                    rules: defaultRequiredRule,
                    showDot: true,
                    dotIndex: 3,
                    dotProps: {
                      direction: 'horizontal',
                      nextDirection: 'horizontal',
                    },
                  },
                  {
                    label: 'Materno',
                    type: 'text',
                    name: 'last_name_m',
                    rules: defaultRequiredRule,
                    showDot: true,
                    dotIndex: 4,
                    dotProps: {
                      direction: 'horizontal',
                      previousDirection: 'horizontal',
                    },
                  },
                ]}
              />
              {buildBaseInput(
                'Correo',
                'email',
                'email',
                {
                  ...defaultRequiredRule,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: constants.ERROR_MESSAGES.WRONG_EMAIL,
                  },
                },
                5
              )}
              {buildBaseInput('Cargo', 'select', 'position', {}, 6, {
                options: (
                  <>
                    <option value="admin">Administrador</option>
                    <option value="operations">Operaciones</option>
                    <option value="inventory">Inventario</option>
                    <option value="crm">CRM</option>
                  </>
                ),
              })}
              {buildBaseInput(
                'Contraseña',
                'password',
                'password',
                {
                  ...defaultRequiredRule,
                  minLength: (value) =>
                    value.length >= 6
                      ? null
                      : constants.ERROR_MESSAGES.MIN_LENGTH(6),
                },
                7,
                { autoComplete: 'new-password' }
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end flex-shrink-0 px-4 py-4">
        <button
          type="button"
          onClick={onCancelClick}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          Cancelar
        </button>
        <button
          type="submit"
          className="inline-flex justify-center px-4 py-2 ml-4 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          {isSubmitting ? (
            <LoadingSpinner></LoadingSpinner>
          ) : (
            <span>Crear empleado</span>
          )}
        </button>
      </div>
    </form>
  );
}
