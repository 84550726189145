import { DocumentTextIcon, XIcon } from '@heroicons/react/outline';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Document, Page } from 'react-pdf';

import { checkFileType, validateFile } from '../../utils/fileUtils';
import { constants } from '../../utils/constants';

import LoadingSpinner from '../LoadingSpinner';
import BaseInput from '../Inputs/BaseInput';
import { showErrorToast } from '../Toast';

const defaultRequiredRule = {
  required: constants.ERROR_MESSAGES.REQUIRED,
};

export default function CreateDocumentForm({
  toggleFocus,
  onSubmit,
  onCancelClick,
}) {
  const {
    register,
    handleSubmit,
    errors,
    reset,
    setError,
    clearErrors,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useForm();

  const [documentFile, setDocumentFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const initialFocusRef = useRef();

  const [previousDot, setPreviousDot] = useState(0);
  const [currentDot, setCurrentDot] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setPreviousDot(currentDot);
    }, 500);
  }, [currentDot]);

  const buildBaseInput = (label, type, name, rules, dotIndex, props) => {
    return (
      <BaseInput
        showDot
        label={label}
        type={type}
        name={name}
        register={register}
        errors={errors}
        rules={rules}
        setCurrentDot={setCurrentDot}
        previousDot={previousDot}
        currentDot={currentDot}
        dotIndex={dotIndex}
        {...props}
      />
    );
  };

  async function submit(data) {
    let response = await onSubmit(data, documentFile);
    if (!response.success) {
      setError('server', response.message);
      setTimeout(() => {
        clearErrors('server');
      }, 200);
    }
  }

  useEffect(() => {
    if (isSubmitSuccessful) reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  useEffect(() => {
    if (toggleFocus) {
      setTimeout(() => {
        initialFocusRef.current.focus();
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleFocus]);

  const checkFile = async (event) => {
    let isValid = validateFile(event.target, 'document');
    if (!isValid) {
      setDocumentFile(null);
      showErrorToast(
        'Formato de documento no válido, debe ser un archivo .pdf, .png, .jpg, .jpeg, word o excel'
      );
      return;
    }
    setDocumentFile(event.target.files[0]);
    if (checkFileType(event.target.files[0].name) === 'image') {
      var reader = new FileReader();
      reader.onload = (e) => {
        setImagePreview(e.target.result);
      };
      await reader.readAsDataURL(event.target.files[0]);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(submit)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') return e.preventDefault();
      }}
      className="flex flex-col h-full bg-white divide-y divide-gray-200 shadow-xl"
    >
      <div className="flex-1 h-0 overflow-y-auto">
        <div className="py-6 pr-4 pl-7 bg-primary-700 sm:pl-7 sm:pr-6">
          <div className="flex items-center justify-between">
            <h2
              id="slide-over-heading"
              className="text-lg font-medium text-white"
            >
              Nuevo Documento
            </h2>
            <div className="flex items-center ml-3 h-7">
              <button
                type="button"
                onClick={onCancelClick}
                className="rounded-md bg-primary-700 text-primary-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
              >
                <span className="sr-only">Close panel</span>
                {/* Heroicon name: x */}
                <XIcon className="w-6 h-6" />
              </button>
            </div>
          </div>
          <div className="mt-1">
            <p className="text-sm text-primary-300">
              Llene los datos del formulario para crear un nuevo documento.
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-between flex-1">
          <div className="pr-4 divide-y divide-gray-200 pl-7 sm:pl-7 sm:pr-6">
            <div className="pt-6 pb-5 space-y-3">
              {buildBaseInput(
                'Nombre',
                'text',
                'name',
                defaultRequiredRule,
                0,
                {
                  inputRef: (e) => {
                    if (e != null) {
                      register(e, {
                        required: constants.ERROR_MESSAGES.REQUIRED,
                      });
                      initialFocusRef.current = e;
                    }
                  },
                }
              )}
              {buildBaseInput('Comentario', 'textarea', 'comment', {}, 1, {})}
              <div className="relative flex flex-col items-center max-w-lg px-2 pt-5 pb-6 space-y-4 border-2 border-gray-300 border-dashed rounded-md sm:space-y-0 sm:flex-row justify-evenly">
                <div className="space-y-1 text-center">
                  <svg
                    className="w-12 h-12 mx-auto text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div className="flex text-sm text-gray-600">
                    <label
                      htmlFor="inp_document_file"
                      className="relative mx-auto font-medium bg-white rounded-md cursor-pointer text-primary-600 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                    >
                      <span>
                        {documentFile ? 'Cambiar archivo' : 'Elegir archivo'}
                      </span>
                      <input
                        id="inp_document_file"
                        name="inp_document_file"
                        type="file"
                        className="sr-only"
                        accept=".pdf, .png, .jpg, .jpeg, .pdf, .word, .doc, .docx, .xml,n/mswor,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .xls, .xlsx, .xlsm, .xlsb"
                        onChange={checkFile}
                      />
                    </label>
                  </div>
                  <p className="max-w-[120px] text-xs text-gray-500">
                    PDF, WORD, EXCEL o imagen hasta 10MB
                  </p>
                </div>
                {!documentFile ? null : checkFileType(documentFile.name) ===
                  'pdf' ? (
                  <Document
                    file={documentFile}
                    className="sm:max-w-[50%] max-h-64 p-1 border-2 rounded-lg border-primary-200 overflow-hidden"
                    renderMode="svg"
                  >
                    <Page pageNumber={1} width={150} className="mx-auto" />
                  </Document>
                ) : checkFileType(documentFile.name) === 'image' ? (
                  <img
                    src={imagePreview}
                    className="max-h-64 sm:max-w-[50%] max-w-full border-2 rounded-lg border-primary-200"
                    alt="Imagen del documento"
                  ></img>
                ) : (
                  <p className="max-h-64 sm:max-w-[50%] break-all overflow-hidden border-2 rounded-lg p-4 border-primary-200">
                    <DocumentTextIcon className="w-8 h-8 mx-auto mb-2 text-gray-400" />
                    {documentFile.name}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end flex-shrink-0 px-4 py-4">
        <button
          type="button"
          onClick={onCancelClick}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          Cancelar
        </button>
        <button
          type="submit"
          className="inline-flex justify-center px-4 py-2 ml-4 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          {isSubmitting ? (
            <LoadingSpinner></LoadingSpinner>
          ) : (
            <span>Crear documento</span>
          )}
        </button>
      </div>
    </form>
  );
}
