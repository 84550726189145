import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { XIcon } from '@heroicons/react/outline';

import { constants } from '../../utils/constants';

import InputErrorMessage from '../InputErrorMessage';
import LoadingSpinner from '../LoadingSpinner';

export default function UserEditForm({
  user,
  toggleFocus,
  onSubmit,
  onCancelClick,
}) {
  const {
    register,
    handleSubmit,
    watch,
    errors,
    trigger,
    reset,
    setValue,
    setError,
    clearErrors,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useForm();
  const watchDocumentKind = watch('document_kind', 'dni');
  const initialFocusRef = useRef();

  async function submit(data) {
    let response = await onSubmit(data);
    if (!response.success) {
      setError('server', response.message);
      setTimeout(() => {
        clearErrors('server');
      }, 200);
    }
  }

  useEffect(() => {
    if (isSubmitSuccessful) reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  useEffect(() => {
    if (toggleFocus) {
      setTimeout(() => {
        initialFocusRef.current.focus();
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleFocus]);

  useEffect(() => {
    setValue('document_kind', user.document_kind);
    setValue('document_number', user.document_number);
    setValue('first_name', user.first_name);
    setValue('last_name_p', user.last_name_p);
    setValue('last_name_m', user.last_name_m);
    setValue('email', user.email);
    setValue('phone_number', user.phone_number);
    setValue('position', user.position);
    setValue('password', '');
    setTimeout(() => {
      trigger();
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <form
      onSubmit={handleSubmit(submit)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') return e.preventDefault();
      }}
      className="flex flex-col h-full bg-white divide-y divide-gray-200 shadow-xl"
    >
      <div className="flex-1 h-0 overflow-y-auto">
        <div className="px-4 py-6 bg-primary-700 sm:px-6">
          <div className="flex items-center justify-between">
            <h2
              id="slide-over-heading"
              className="text-lg font-medium text-white"
            >
              Editar Empleado
            </h2>
            <div className="flex items-center ml-3 h-7">
              <button
                type="button"
                onClick={onCancelClick}
                className="rounded-md bg-primary-700 text-primary-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
              >
                <span className="sr-only">Close panel</span>
                {/* Heroicon name: x */}
                <XIcon className="w-6 h-6" />
              </button>
            </div>
          </div>
          <div className="mt-1">
            <p className="text-sm text-primary-300">
              Modifique los datos del formulario para editar el empleado.
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-between flex-1">
          <div className="px-4 divide-y divide-gray-200 sm:px-6">
            <div className="pt-6 pb-5 space-y-3">
              <div>
                <label
                  htmlFor="inp_edit_document_kind"
                  className="block text-sm font-medium text-gray-900"
                >
                  Documento
                </label>
                <div className="mt-1 -space-y-px rounded-md shadow-sm">
                  <div>
                    <label htmlFor="inp_edit_document_kind" className="sr-only">
                      Tipo de documento
                    </label>
                    <select
                      id="inp_edit_document_kind"
                      name="document_kind"
                      defaultValue="dni"
                      ref={(e) => {
                        if (e != null) {
                          register(e);
                          initialFocusRef.current = e;
                        }
                      }}
                      autoFocus
                      onChange={() => {
                        setTimeout(() => {
                          trigger('document_number');
                        }, 200);
                      }}
                      className={`${
                        errors.document_number
                          ? 'inp-border-red'
                          : 'inp-border-primary'
                      } relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm`}
                    >
                      <option value="dni">DNI</option>
                      <option value="passport">Pasaporte</option>
                      <option value="other">Otro</option>
                    </select>
                  </div>
                  <div>
                    <label
                      htmlFor="inp_edit_document_number"
                      className="sr-only"
                    >
                      Número de documento
                    </label>
                    <input
                      type="text"
                      id="inp_edit_document_number"
                      name="document_number"
                      ref={register({
                        required: constants.ERROR_MESSAGES.REQUIRED,
                        validate: {
                          length: (value) =>
                            watchDocumentKind !== 'dni'
                              ? null
                              : value.length === 8
                              ? null
                              : constants.ERROR_MESSAGES.LENGTH(8),
                          pattern: (value) =>
                            watchDocumentKind !== 'dni'
                              ? null
                              : /^[0-9]*$/.exec(value)
                              ? null
                              : constants.ERROR_MESSAGES.DIGITS_ONLY,
                        },
                      })}
                      className={`${
                        errors.document_number
                          ? 'inp-border-red'
                          : 'inp-border-primary'
                      } relative block w-full rounded-none rounded-b-md bg-transparent focus:z-10 sm:text-sm`}
                      placeholder="Número de documento"
                    ></input>
                  </div>
                </div>
                <InputErrorMessage
                  error={errors.document_number}
                ></InputErrorMessage>
              </div>
              <div>
                <label
                  htmlFor="inp_edit_first_name"
                  className="block text-sm font-medium text-gray-900"
                >
                  Nombres
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    id="inp_edit_first_name"
                    name="first_name"
                    ref={register({
                      required: constants.ERROR_MESSAGES.REQUIRED,
                    })}
                    className={`${
                      errors.first_name
                        ? 'inp-border-red'
                        : 'inp-border-primary'
                    } block w-full shadow-sm sm:text-sm rounded-md`}
                    placeholder="Nombres"
                  ></input>
                </div>
                <InputErrorMessage
                  error={errors.first_name}
                ></InputErrorMessage>
              </div>
              <div>
                <label
                  htmlFor="inp_edit_last_name_p"
                  className="block text-sm font-medium text-gray-900"
                >
                  Apellidos
                </label>
                <div className="flex mt-1 -space-x-px">
                  <div className="flex-1 w-1/2 min-w-0">
                    <label htmlFor="inp_edit_last_name_p" className="sr-only">
                      Paterno
                    </label>
                    <input
                      type="text"
                      id="inp_edit_last_name_p"
                      name="last_name_p"
                      ref={register({
                        required: constants.ERROR_MESSAGES.REQUIRED,
                      })}
                      className={`${
                        errors.last_name_p || errors.last_name_m
                          ? 'inp-border-red'
                          : 'inp-border-primary'
                      } relative block w-full rounded-none rounded-l-md bg-transparent focus:z-10 sm:text-sm`}
                      placeholder="Paterno"
                    ></input>
                  </div>
                  <div className="flex-1 min-w-0">
                    <label htmlFor="inp_edit_last_name_m" className="sr-only">
                      Materno
                    </label>
                    <input
                      type="text"
                      id="inp_edit_last_name_m"
                      name="last_name_m"
                      ref={register({
                        required: constants.ERROR_MESSAGES.REQUIRED,
                      })}
                      className={`${
                        errors.last_name_p || errors.last_name_m
                          ? 'inp-border-red'
                          : 'inp-border-primary'
                      } relative block w-full rounded-none rounded-r-md bg-transparent focus:z-10 sm:text-sm`}
                      placeholder="Materno"
                    ></input>
                  </div>
                </div>
                <InputErrorMessage
                  error={errors.last_name_p || errors.last_name_m}
                ></InputErrorMessage>
              </div>
              <div>
                <label
                  htmlFor="inp_edit_email"
                  className="block text-sm font-medium text-gray-900"
                >
                  Correo
                </label>
                <div className="mt-1">
                  <input
                    type="email"
                    id="inp_edit_email"
                    name="email"
                    ref={register({
                      required: constants.ERROR_MESSAGES.REQUIRED,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: constants.ERROR_MESSAGES.WRONG_EMAIL,
                      },
                    })}
                    className={`${
                      errors.email ? 'inp-border-red' : 'inp-border-primary'
                    } block w-full shadow-sm sm:text-sm rounded-md`}
                    placeholder="Correo"
                  ></input>
                </div>
                <InputErrorMessage error={errors.email}></InputErrorMessage>
              </div>
              <div>
                <label
                  htmlFor="inp_edit_phone_number"
                  className="block text-sm font-medium text-gray-900"
                >
                  Teléfono
                </label>
                <div className="mt-1">
                  <input
                    type="number"
                    id="inp_edit_phone_number"
                    name="phone_number"
                    ref={register}
                    className="block w-full rounded-md shadow-sm inp-border-primary sm:text-sm"
                    placeholder="Teléfono"
                  ></input>
                </div>
              </div>
              <div>
                <label
                  htmlFor="inp_edit_position"
                  className="block text-sm font-medium text-gray-900"
                >
                  Cargo
                </label>
                <div className="mt-1">
                  <select
                    id="inp_edit_position"
                    name="position"
                    ref={register}
                    className="relative block w-full bg-transparent border-gray-300 rounded-md focus:ring-primary-500 focus:border-primary-500 focus:z-10 sm:text-sm"
                  >
                    <option
                      hidden={user.position !== 'super_admin'}
                      value="super_admin"
                    >
                      Administrador
                    </option>
                    <option
                      hidden={user.position === 'super_admin'}
                      value="admin"
                    >
                      Administrador
                    </option>
                    <option
                      hidden={user.position === 'super_admin'}
                      value="operations"
                    >
                      Operaciones
                    </option>
                    <option
                      hidden={user.position === 'super_admin'}
                      value="inventory"
                    >
                      Inventario
                    </option>
                    <option
                      hidden={user.position === 'super_admin'}
                      value="crm"
                    >
                      CRM
                    </option>
                  </select>
                </div>
              </div>
              <div>
                <label
                  htmlFor="inp_edit_password"
                  className="block text-sm font-medium text-gray-900"
                >
                  Contraseña
                </label>
                <div className="mt-1">
                  <input
                    type="password"
                    id="inp_edit_password"
                    name="password"
                    ref={register}
                    className={`${
                      errors.password ? 'inp-border-red' : 'inp-border-primary'
                    } block w-full shadow-sm sm:text-sm rounded-md`}
                    placeholder="Contraseña"
                    autoComplete="new-password"
                  ></input>
                </div>
                <InputErrorMessage error={errors.password}></InputErrorMessage>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end flex-shrink-0 px-4 py-4">
        <button
          type="button"
          onClick={onCancelClick}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          Cancelar
        </button>
        <button
          type="submit"
          className="inline-flex justify-center px-4 py-2 ml-4 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          {isSubmitting ? (
            <LoadingSpinner></LoadingSpinner>
          ) : (
            <span>Guardar cambios</span>
          )}
        </button>
      </div>
    </form>
  );
}
