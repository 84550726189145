import { ExclamationIcon } from '@heroicons/react/outline';
import LoadingSpinner from './LoadingSpinner';

export default function ConfirmModalContent({
  onClose,
  onConfirm,
  title,
  content,
  buttonLoading,
}) {
  return (
    <div>
      <div className="px-4 pt-5 pb-4 sm:flex sm:items-start sm:p-6 sm:pb-4">
        <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
          {/* Heroicon name: exclamation */}
          <ExclamationIcon className="w-6 h-6 text-red-600" />
        </div>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3
            className="text-lg font-medium leading-6 text-gray-900"
            id="modal-headline"
          >
            {title || 'Confirmar acción'}
          </h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              {content || '¿Está seguro que desea realizar esta acción?'}
            </p>
          </div>
        </div>
      </div>
      <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          onClick={onConfirm}
          className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          {buttonLoading ? (
            <LoadingSpinner></LoadingSpinner>
          ) : (
            <span>Confirmar</span>
          )}
        </button>
        <button
          type="button"
          onClick={onClose}
          className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:w-auto sm:text-sm"
        >
          Cancelar
        </button>
      </div>
    </div>
  );
}
