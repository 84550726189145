import { PlusIcon } from '@heroicons/react/outline';

export default function EmptyState({
  title,
  subtitle,
  buttonText,
  buttonAction,
  icon,
}) {
  return (
    <div className="text-center">
      {icon}
      <h3 className="mt-2 text-sm font-medium text-gray-900">{title}</h3>
      {subtitle && <p className="mt-1 text-sm text-gray-500">{subtitle}</p>}
      {buttonText && (
        <div className="mt-6">
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={buttonAction}
          >
            <PlusIcon className="w-5 h-5 mr-2 -ml-1" aria-hidden="true" />
            {buttonText}
          </button>
        </div>
      )}
    </div>
  );
}
