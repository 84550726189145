import { useEffect, useRef } from 'react';
import { XIcon } from '@heroicons/react/outline';

import { formatDate } from '../../utils/dateUtils';
import StorageService from '../../utils/storage';

export default function OperationDetails({
  operation,
  toggleFocus,
  onCloseClick,
  onEditClick,
  onDeleteClick,
}) {
  const position = StorageService.get('userData').position;

  const initialFocusRef = useRef();

  useEffect(() => {
    if (toggleFocus) {
      setTimeout(() => {
        initialFocusRef.current.focus();
      }, 200);
    }
  }, [toggleFocus]);

  return (
    <div className="flex flex-col h-full overflow-y-scroll bg-white shadow-xl">
      <div className="px-4 py-6 sm:px-6">
        <div className="flex items-start justify-between">
          <h2
            id="slide-over-heading"
            className="text-lg font-medium text-gray-900"
          >
            Datos de la Operación
          </h2>
          <div className="flex items-center ml-3 h-7">
            <button
              onClick={() => {
                onCloseClick();
              }}
              className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:ring-2 focus:ring-primary-500"
            >
              <span className="sr-only">Close panel</span>
              {/* Heroicon name: x */}
              <XIcon className="w-6 h-6" />
            </button>
          </div>
        </div>
      </div>
      {/* Main */}
      <div>
        <div className="pb-1 sm:pb-6">
          <div>
            <div className="relative h-20 lg:h-28 bg-primary-700"></div>
            <div className="px-4 mt-6 sm:mt-8 sm:flex sm:items-end sm:px-6">
              <div className="sm:flex-1">
                <div>
                  <div className="items-center sm:flex">
                    <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
                      {operation.motive}
                    </h3>
                    <span
                      className={`${
                        operation.is_active
                          ? 'text-green-800 bg-green-100'
                          : 'text-red-800 bg-red-100'
                      } mt-2.5 sm:mt-0 sm:ml-2.5 inline-block px-2 py-0.5 text-sm font-medium rounded-full`}
                    >
                      {operation.kind}
                    </span>
                  </div>
                </div>
                <div className="flex flex-wrap mt-5 space-y-3 sm:space-y-0 sm:space-x-3">
                  <button
                    onClick={() => {
                      onEditClick(operation);
                    }}
                    ref={initialFocusRef}
                    type="button"
                    className="inline-flex items-center justify-center flex-shrink-0 w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:flex-1"
                  >
                    Editar
                  </button>
                  <button
                    type="button"
                    onClick={onDeleteClick}
                    className="inline-flex items-center justify-center flex-1 w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                  >
                    Eliminar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 pt-5 pb-5 sm:px-0 sm:pt-0">
          <dl className="px-4 space-y-6 sm:px-6">
            <div>
              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                Comprobante
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                {operation.voucher_kind}
                {operation.voucher_kind === 'Sin comprobante' ? (
                  <></>
                ) : (
                  <span>: {operation.voucher_number}</span>
                )}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                Monto
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                S/ {operation.amount}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                Fecha
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                {formatDate(operation.date)}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                Observación
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                {operation.observation || '-'}
              </dd>
            </div>
            {position === 'super_admin' || position === 'admin' ? (
              <div>
                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                  Empleado
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                  {operation?.created_by?.first_name}{' '}
                  {operation?.created_by?.last_name_p}{' '}
                  {operation?.created_by?.last_name_m}
                  {' / '}
                  {operation?.created_by?.email}
                </dd>
              </div>
            ) : (
              <></>
            )}
          </dl>
        </div>
      </div>
    </div>
  );
}
